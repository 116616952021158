import { useState } from "react";
import {Flex, Button, FlexItem, ArrowLeftIcon, ArrowRightIcon, CloseIcon, DownloadIcon, Header } from "@fluentui/react-northstar"
import { Document, Page, pdfjs } from 'react-pdf'
import "./PdfViewer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfViewer(props: { pdfUrl: string, pdfFileName:string, togglePdf: any, }) {
    const [numPages, setNumPages] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(1);


    function onDocumentLoadSuccess({ numPages } : { numPages:number }) {
        setNumPages(numPages);
        setPageNumber(1);
      }

    function changePage(offset:any) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
    changePage(-1);
    }

    function nextPage() {
    changePage(1);
    }  

    const onDownloadClick = () => {
        fetch(props.pdfUrl).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = props.pdfFileName;
                alink.click();
            })
        })
    }

    return (
        <div id="pdfModal" className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <Flex gap="gap.small" space="between">
                        <Header as="h3" style={{ padding: "0 0 0 20px", margin: "auto 0"}} content={props.pdfFileName} />
                        <Flex gap="gap.small">
                            <Button icon={<DownloadIcon />} text iconOnly title="Download" onClick={onDownloadClick}/>
                            <Button icon={<CloseIcon />} text iconOnly title="Close" onClick={(e) => {
                                e.preventDefault();
                                props.togglePdf()
                            }}/>
                        </Flex>
                    </Flex>
                    <Flex column gap="gap.small">
                        <FlexItem align="center">
                            <p>
                            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                            </p>
                        </FlexItem>
                        <Flex hAlign="center" padding="padding.medium" gap="gap.small">
                            <Button size="small" disabled={pageNumber <= 1} onClick={previousPage} icon={<ArrowLeftIcon />} iconOnly title="Previous" />
                            <Button size="small" disabled={pageNumber >= numPages} onClick={nextPage} icon={<ArrowRightIcon />} iconOnly title="Next" />
                        </Flex>
                    </Flex>
                </div>
                <div className="modal-body">
                    <Document file={props.pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} />
                    </Document>
                </div>
                <div className="modal-footer">
                </div>
            </div>
        </div>
    )
}