import { useState, useEffect, useRef, useCallback, useMemo, useContext } from "react";
import {
    Text, Header, Status, Flex, Menu, Button, FormCheckbox, Loader,
    FormTextArea, FormRadioGroup, FormDropdown, Divider, Label, Alert
} from "@fluentui/react-northstar"
import { ITask, NonCompletionReason } from "../../../interfaces/task"
import FAQ from '../faq/FAQ'
import TaskSurvey from '../survey/TaskSurvey'
import "./Task.css"
import { Attachments } from '../attachment/Attachments';
import { TaskAPI } from '../../../api/TaskAPI';
import { SurveyAPI } from '../../../api/SurveyAPI'
import { FaqAPI } from "../../../api/FaqAPI";
import {
    SpokeForm, SpokeFormTab,
    SpokeFormQuestionValue,
    SpokeFormQuestion,
    SpokeFormQuestionColumnValue,
    SpokeFormQuestionRowValue
} from "../../../interfaces/spokeForm"
import { SpokeFormResponse } from "../../../interfaces/spokeFormResponse"
import * as microsoftTeams from "@microsoft/teams-js";
import { ErrorWithCode } from "@microsoft/teamsfx";
import { app } from "@microsoft/teams-js";
import { ITaskView } from '../../../interfaces/taskView'
import { TeamsFxContext } from "../../Context";
import { KnowHowFaq, KnowHowParent, KnowHow } from '../../../interfaces/faq'

export default function Task(props: { TaskView?: ITaskView, postSave?: any }) {

    const [task, setTask] = useState<ITask>();
    const [selectedTabItem, setSelectedTabItem] = useState({});
    const [nonCompletionDisabled, setNonCompletionDisabled] = useState(true)
    const [nonCompletionReasons, setNonCompletionReasons] = useState<any>({})
    const params = new URLSearchParams(window.location.hash.substring(6))
    //const communicationId = Number(params.get('communicationId'));
    //const repeatDate = params.get('repeatDate')
    const [communicationId, setCommunicationId] = useState<number>(Number(params.get('communicationId')))
    const [repeatDate, setRepeatDate] = useState<any>(params.get('repeatDate'))
    const childRef = useRef<any>(null);
    const [taskTabs, setTaskTabs] = useState<any>({})
    const [alert, setAlert] = useState<boolean>(false);
    const [token, setToken] = useState<string>();
    const [tenantId, setTenantId] = useState<string>();
    const [surveyForm, setSurveyForm] = useState<SpokeForm>();
    const [surveyResponse, setSurveyResponse] = useState<SpokeFormResponse>();
    const [surveyResponseId, setSurveyResponseId] = useState<number>();
    const [tabs, setTabs] = useState<any>({})
    const [selectedSurveyTabItem, setSelectedSurveyTabItem] = useState({});
    const [loading, setLoading] = useState<boolean>(true);
    const [faqDisplayAlert, setFaqDisplayAlert] = useState<boolean>(false)
    const [faqShowAsk, setFaqShowAsk] = useState<boolean>(false)
    const [validate, setValidate] = useState<boolean>(false);
    const [faqQuestion, setFaqQuestion] = useState<string>();
    const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
    const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];
    const [faqKey, setFaqKey] = useState<string>(Math.random().toString(36).slice(2, 7))
    const handleFaqQuestionChange = ((questionText: string) => {
        setFaqQuestion(questionText);
        setFaqShowAsk(questionText !== '')
    })
    const handleFaqSubmit = (knowHowParent: KnowHowParent, communicationId: number) => {
        console.log(`FAQ askQuestion token ${token}`)
        if (token && tenantId) {
            const knowHow: KnowHow = {
                Question: faqQuestion!,
                KnowHowParentId: knowHowParent.KnowHowParentId,
                CommunicationId: communicationId,
                DateAsked: new Date(),
                Read: false,
                Status: 0,
                KnowHowId: 0
            }

            FaqAPI.post(knowHow, token, tenantId)
                .then(async (response) => {
                    const newKey = Math.random().toString(36).slice(2, 7)
                    setFaqDisplayAlert(true)
                    setFaqKey(newKey)
                }).catch((error) => {
                    console.error(error.response.status);
                })
        }
    }

    const TabLabel = useCallback((props: { tab: SpokeFormTab }) => {
        console.log('TabLabel')
        const circle = {
            width: '1rem',
            height: '1rem',
            alignItems: 'center',
            backgroundColor: 'rgb(196, 49, 75)',
            justifyContent: 'center',
            borderRadius: '50%',
            fontSize: '6pt',
            verticalAlign: 'super'
        }
        return (<>
            <span>{props.tab.DisplayName}</span>
            {props.tab.ErrorCount && props.tab.ErrorCount > 0 ?
                <Label style={circle} color='red' content={props.tab.ErrorCount} /> : <></>}</>)
    }, [])

    const valid = (_surveyResponse?: SpokeFormResponse) => {
        console.log('valid _surveyResponse ', _surveyResponse)
        let errorCount = 0;
        const updatedTabs = surveyForm?.SpokeFormTabs.map(t => {
            t.ErrorCount = 0;
            const requiredItems = t.SpokeFormItems!.filter(i => i.SpokeFormQuestion.Required)
            requiredItems?.forEach(ri => {
                const responseAnswer = _surveyResponse?.SpokeFormQuestionResponses.find(r => r.SpokeFormQuestionId === ri.SpokeFormQuestionId)
                switch (ri.SpokeFormQuestion.ControlType) {
                    case 0: // Textbox
                    case 1: // Number 
                    case 2: // Textarea    
                    case 3: // Dropdown                    
                    case 5: // Checkbox
                    case 7: // DatePicker
                        if (responseAnswer === undefined || responseAnswer.Response === '') {
                            t.ErrorCount++;
                            errorCount++;
                        }
                        break;
                    case 4: // CheckboxList
                    case 6: // RadioButtonList
                    case 10: // YesNo
                    case 11: // YesNoNA
                        if (responseAnswer?.SpokeFormQuestionValueResponses === undefined || responseAnswer?.SpokeFormQuestionValueResponses?.length === 0) {
                            t.ErrorCount++;
                            errorCount++;
                        }
                        break;
                    case 8: // Matrix
                        const colCount = ri.SpokeFormQuestion.SpokeFormQuestionValues.filter(qv => qv.SpokeFormQuestionIdColumn).length
                        const rowCount = ri.SpokeFormQuestion.SpokeFormQuestionValues.filter(qv => qv.SpokeFormQuestionIdRow).length
                        const blanks = responseAnswer?.SpokeFormQuestionValueResponses?.find(r => r.Response === '')
                        if (ri.SpokeFormQuestion.DisplayRadio) {
                            if (blanks || rowCount !== responseAnswer?.SpokeFormQuestionValueResponses?.length) {
                                t.ErrorCount++;
                                errorCount++;
                            }
                        } else {
                            if (blanks || (rowCount * colCount) !== responseAnswer?.SpokeFormQuestionValueResponses?.length) {
                                t.ErrorCount++;
                                errorCount++;
                            }
                        }
                        break;
                }
            });
            return ({
                key: t.SpokeFormTabId!.toString(),
                content: <TabLabel tab={t} />,
                onClick: () => setSelectedSurveyTabItem(t.SpokeFormTabId!)
            });
        })
        setTabs(updatedTabs)
        TaskMenuItems(errorCount)
        return errorCount;
    }

    const isValid = () => {
        const errorCount = valid(surveyResponse)
        if (errorCount === 0) {
            setValidate(false)
        } else {
            setValidate(true)

        }
        console.log('errorCount=', errorCount)
        TaskMenuItems(errorCount)
        return errorCount
    }

    const handleChange: any = (event: any, data: any,
        validate: boolean,
        spokeFormQuestion: SpokeFormQuestion,
        spokeFormQuestionValue?: SpokeFormQuestionValue,
        spokeFormQuestionColumnValue?: SpokeFormQuestionColumnValue,
        spokeFormQuestionRowValue?: SpokeFormQuestionRowValue) => {
        console.log('handleChange')
        let spokeFormQuestionValueResponse
        const _surveyResponse = surveyResponse
        let response = _surveyResponse?.SpokeFormQuestionResponses.find(questionResponse => questionResponse.SpokeFormQuestionId === spokeFormQuestion?.SpokeFormQuestionId)
        if (!response) {
            response = { SpokeFormQuestionId: spokeFormQuestion.SpokeFormQuestionId, SpokeFormQuestionValueResponses: [] }
            _surveyResponse?.SpokeFormQuestionResponses.push(response);
        }
        switch (spokeFormQuestion?.ControlType) {
            case 0:
            case 1:
            case 2:
                response.Response = data.value
                break;
            case 7: // Datepicker
                response.Response = data.value.toISOString().split("T")[0];
                break;
            case 3: // Dropdown
                response.Response = data.value.key
                if (response.SpokeFormQuestionValueResponses!.length > 0) {
                    response.SpokeFormQuestionValueResponses![0].Response = data.value.key
                    response.SpokeFormQuestionValueResponses![0].SpokeFormQuestionValueId = data.value.key
                } else {
                    spokeFormQuestionValueResponse = {
                        Response: data.value.key,
                        SpokeFormQuestionValueId: data.value.key
                    }
                    response.SpokeFormQuestionValueResponses!.push(spokeFormQuestionValueResponse)
                }
                break;
            case 4:  // CheckboxList
                console.log('CheckboxList checked ', data.checked)
                spokeFormQuestionValueResponse = response.SpokeFormQuestionValueResponses?.find(questionValueResponse => questionValueResponse.SpokeFormQuestionValueResponseId === spokeFormQuestionValue!.SpokeFormQuestionValueId)
                if (!spokeFormQuestionValueResponse) {
                    spokeFormQuestionValueResponse = { SpokeFormQuestionValueId: spokeFormQuestionValue!.SpokeFormQuestionValueId }
                    response.SpokeFormQuestionValueResponses?.push(spokeFormQuestionValueResponse)
                }
                if (data.checked) {
                    spokeFormQuestionValueResponse.Response = data.label
                    console.log('spokeFormQuestionValueResponse ', spokeFormQuestionValueResponse)
                } else {
                    response.SpokeFormQuestionValueResponses = response.SpokeFormQuestionValueResponses?.filter(questionValueResponse => questionValueResponse.SpokeFormQuestionValueId !== spokeFormQuestionValue!.SpokeFormQuestionValueId)
                }
                break;
            case 5:
                if (data.checked) {
                    response.Response = data.label

                } else {
                    response.Response = undefined;
                }
                break;
            case 6: // RadioButtonList
            case 10: // YesNo
            case 11: //YesNoNA
                response.Response = data.value
                if (response.SpokeFormQuestionValueResponses!.length > 0) {
                    response.SpokeFormQuestionValueResponses![0].Response = data.value
                    response.SpokeFormQuestionValueResponses![0].SpokeFormQuestionValueId = data.value
                } else {
                    spokeFormQuestionValueResponse = {
                        Response: data.value,
                        SpokeFormQuestionValueId: data.value
                    }
                    response.SpokeFormQuestionValueResponses!.push(spokeFormQuestionValueResponse)
                }
                break;
            case 8:
                if (spokeFormQuestion.DisplayRadio) {
                    const cols = response.SpokeFormQuestionValueResponses?.filter(questionValueResponse => questionValueResponse.SpokeFormQuestionValueIdRow === spokeFormQuestionRowValue?.SpokeFormQuestionValueId)
                    cols?.forEach((col) => {
                        col.Response = undefined
                    })
                }
                spokeFormQuestionValueResponse = response.SpokeFormQuestionValueResponses?.find(
                    questionValueResponse => questionValueResponse.SpokeFormQuestionValueIdColumn === spokeFormQuestionColumnValue?.SpokeFormQuestionValueId
                        && questionValueResponse.SpokeFormQuestionValueIdRow === spokeFormQuestionRowValue?.SpokeFormQuestionValueId)
                if (!spokeFormQuestionValueResponse) {
                    spokeFormQuestionValueResponse = {
                        SpokeFormQuestionValueIdRow: spokeFormQuestionRowValue?.SpokeFormQuestionValueId,
                        SpokeFormQuestionValueIdColumn: spokeFormQuestionColumnValue?.SpokeFormQuestionValueId,
                        SpokeFormQuestionValueId: spokeFormQuestionRowValue?.SpokeFormQuestionIdRow,
                        Response: data.value
                    }
                    response.SpokeFormQuestionValueResponses?.push(spokeFormQuestionValueResponse)
                } else {
                    spokeFormQuestionValueResponse.Response = data.value
                }
                break;
        }
        if (validate) {
            valid(_surveyResponse)
        }
    }

    const fetchData = (tokenResponse: any, tenantId: string) => {
        TaskAPI.get(communicationId, repeatDate, tokenResponse.token, tenantId)
            .then(async (response) => {
                let data: ITask = response;
                setTask(data);
                if (data.NonCompletionReasons) {
                    setNonCompletionReasons(data.NonCompletionReasons.map((r: NonCompletionReason) => {
                        return {
                            header: r.Reason,
                            key: r.TaskNonCompletionId
                        }
                    }));
                }

                if (data.Completed) {
                    setNonCompletionDisabled(true)
                } else {
                    setNonCompletionDisabled(false)
                }
                setSelectedTabItem('Summary')
                if (data.HasFeedback) {
                    SurveyAPI.survey(communicationId, tokenResponse.token, tenantId).then(async (surveyFormResponseData) => {
                        setSurveyForm(surveyFormResponseData)
                        setTabs(surveyFormResponseData.SpokeFormTabs.map((tab: SpokeFormTab) => {
                            return {
                                key: tab.SpokeFormTabId!.toString(),
                                content: <TabLabel tab={tab} />,
                                onClick: () => setSelectedSurveyTabItem(tab.SpokeFormTabId!),
                            };
                        }));
                        setSelectedSurveyTabItem(surveyFormResponseData.SpokeFormTabs[0].SpokeFormTabId)
                        let surveyFormDataResponseData: SpokeFormResponse = await SurveyAPI.answer(communicationId, tokenResponse.token, tenantId, surveyResponseId);
                        if (surveyFormDataResponseData) {
                            setSurveyResponse(surveyFormDataResponseData)
                        } else {
                            setSurveyResponse({
                                SpokeFormId: surveyFormResponseData.SpokeFormId,
                                SpokeFormQuestionResponses: [],
                                SpokeFormResponseId: 0,
                            })
                        }
                        setLoading(false)
                    })
                } else {
                    setLoading(false)
                }
            }).catch((error) => {
                console.log('error ', error)
            })
    }

    useEffect(() => {
        setCommunicationId(props.TaskView?.communicationId!)
        setRepeatDate(props.TaskView?.repeatDate!)
    }, [props.TaskView])

    useEffect(() => {
        console.log('REACT_APP_IDENTIFIER_URI=', process.env.REACT_APP_IDENTIFIER_URI)
        app.getContext().then((context) => {
            teamsUserCredential?.getUserInfo().then((userInfo) => {
                setTenantId(userInfo.tenantId);
                teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                    setToken(tokenResponse!.token)
                    fetchData(tokenResponse, userInfo.tenantId)
                }).catch((err: ErrorWithCode) => {
                    console.log('News get token error: ', err.code, err.message)
                    if (err.code === 'UiRequiredError') {
                        teamsUserCredential?.getUserInfo().then((userInfo) => {
                            setTenantId(userInfo.tenantId);
                            console.log('prompt user to login', process.env.REACT_APP_IDENTIFIER_URI)
                            teamsUserCredential!.login(scope).then(() => {
                                teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                                    setToken(tokenResponse!.token)
                                    fetchData(tokenResponse, userInfo.tenantId)
                                })
                            })
                        })
                    }
                })
            })
        })
    }, [communicationId, repeatDate]);

    useEffect(() => {
        TaskMenuItems(0)
    }, [surveyForm, task])

    useEffect(() => {
        console.log('selectedSurveyTabItem changed ', selectedSurveyTabItem)
    }, [selectedSurveyTabItem])

    const defaultNonCompletion = () => {
        if (task?.NonCompletionReasons) {
            const selectedReason = task.NonCompletionReasons.find((r) => {
                return r.Selected
            })
            console.log('selectedReason ', selectedReason)
            if (selectedReason) {
                console.log(nonCompletionReasons)
                const selectedOption = nonCompletionReasons.find((r: any) => {
                    return r.key === selectedReason.TaskNonCompletionId
                })
                console.log(selectedOption)
                return selectedOption;
            }
        }
    }

    const nonCompletionChanged = (event: any, data: any) => {
        let selectedReason = task?.NonCompletionReasons?.find((r) => {
            return r.TaskNonCompletionId === data.value.key
        });
        if (selectedReason) {
            if (task?.NonCompletionReasons) {
                task!.NonCompletionReasons = task!.NonCompletionReasons.map(r => ({
                    ...r,
                    Selected: r.TaskNonCompletionId === selectedReason?.TaskNonCompletionId ? true : false
                }));
                setNonCompletionReasons(task?.NonCompletionReasons.map((r: NonCompletionReason) => {
                    return {
                        header: r.Reason,
                        key: r.TaskNonCompletionId
                    }
                }));
            }
        }
    }

    const SubTasks = () => {
        let subTasks = {};
        if (task?.SubTaskResponses) {
            subTasks = task?.SubTaskResponses.map((t) => {
                return (
                    <FormCheckbox data-taskResponseId={t.TaskResponseId} defaultChecked={t.Complete} label={t.Title} onChange={(event, data) => {
                        if (task && data) {
                            let _task = task;
                            const taskResponse = _task.SubTaskResponses?.find(t => t.TaskResponseId.toString() === event.currentTarget.getAttribute('data-taskResponseId'))
                            if (taskResponse) {
                                taskResponse.Complete = data.checked
                                setTask(_task);
                            }
                        }
                    }} />
                )
            })
        }
        return (<div style={{ margin: '1em 0em 0em 0em' }}>{subTasks}</div>)
    }

    const Summary = () => {
        return (
            <>
                <Flex column gap="gap.small">
                    <div dangerouslySetInnerHTML={{ __html: task?.Subject }} />
                    <Attachments content={task?.Content} />
                </Flex>
                <Flex>
                    <FormTextArea
                        onChange={(event, data) => {
                            if (task && data) {
                                let _task = task;
                                _task.ResponseNotes = data.value
                                setTask(_task);
                            }
                        }}
                        defaultValue={task?.ResponseNotes}
                        fluid
                        label="Notes"
                        resize='none'
                        rows={5}
                        cols={80}
                    />
                </Flex>
                <Flex column>
                    <FormRadioGroup label="Complete"
                        onCheckedValueChange={(event, data) => {
                            if (data?.value === 1) {
                                task!.Completed = true
                                setNonCompletionDisabled(true)
                            } else {
                                task!.Completed = false
                                setNonCompletionDisabled(false)
                            }
                            console.log(nonCompletionDisabled)
                        }}
                        defaultCheckedValue={task?.Completed ? 1 : 0}
                        items={
                            [
                                {
                                    name: 'Yes',
                                    key: 'Yes',
                                    label: 'Yes',
                                    value: 1
                                },
                                {
                                    name: 'No',
                                    key: 'No',
                                    label: 'No',
                                    value: 0
                                }
                            ]
                        }
                    />
                    {task?.NonCompletionReasons !== undefined && task?.NonCompletionReasons?.length > 0 ?
                        <FormDropdown
                            disabled={nonCompletionDisabled}
                            label={{
                                content: `Reason for non-completion`
                            }}
                            items={nonCompletionReasons}
                            defaultValue={defaultNonCompletion}
                            onChange={(event, data) => { nonCompletionChanged(event, data) }}
                        />
                        :
                        <></>
                    }
                </Flex>
            </>
        )
    }

    const TabContent = useMemo(() => {
        if (selectedTabItem === 'SubTasks') {
            return (<SubTasks />)
        } else if (selectedTabItem === 'FAQ') {
            return (<FAQ CommunicationId={task?.CommunicationId}
                HandleFaqQuestionChange={handleFaqQuestionChange}
                HandleFaqSubmit={handleFaqSubmit}
                KnowHowParent={task?.KnowHowParent}
                DefaultQuestion={faqQuestion}
                key={faqKey}
                ShowAsk={faqShowAsk}
                DisplayAlert={faqDisplayAlert} />)
        } else if (selectedTabItem === 'Survey') {
            return (<TaskSurvey surveyForm={surveyForm}
                surveyResponse={surveyResponse}
                tabs={tabs}
                validate={validate}
                valid={valid}
                selectedTabItem={selectedSurveyTabItem}
                communicationId={task?.CommunicationId}
                handleChange={handleChange}
                ref={childRef} />)
        } else {
            return (
                <Summary />
            )
        }
    }, [selectedTabItem, selectedSurveyTabItem, validate, valid, handleChange])

    const TaskMenuItems = (surveyErrors: number) => {
        const summaryTab = {
            DisplayName: 'Summary',
            ErrorCount: 0
        }
        var items = [{
            key: 'Summary',
            content: <TabLabel tab={summaryTab} />,
            onClick: () => setSelectedTabItem("Summary")
        }]
        if (task?.SubTaskResponses !== undefined && task?.SubTaskResponses?.length > 0) {
            const subTaskTab = {
                DisplayName: `Sub Tasks (${task?.SubTaskResponses?.length})`,
                ErrorCount: 0
            }
            items.push({
                key: 'SubTasks',
                content: <TabLabel tab={subTaskTab} />,
                onClick: () => setSelectedTabItem('SubTasks')
            })
        }

        const canAskQuestion = (task?.KnowHowParent?.KnowHowRoleExperts !== undefined && task?.KnowHowParent?.KnowHowRoleExperts?.length > 0)
            || (task?.KnowHowParent?.KnowHowUserExperts !== undefined && task?.KnowHowParent?.KnowHowUserExperts?.length)

        if (canAskQuestion || (task?.KnowHowParent !== undefined && task?.KnowHowParent?.KnowHowFaqs.length > 0)) {
            const qaTab = {
                DisplayName: `Q & A (${task?.KnowHowParent?.KnowHowFaqs.length})`,
                ErrorCount: 0
            }
            items.push({
                key: 'Q&A',
                content: <TabLabel tab={qaTab} />,
                onClick: () => setSelectedTabItem('FAQ')
            })
        }
        console.log('TaskMenuItems hasFeedback ', task)
        if (task?.HasFeedback) {
            const surveyTab = {
                DisplayName: 'Survey',
                ErrorCount: surveyErrors
            }
            items.push({
                key: 'Survey',
                content: <TabLabel tab={surveyTab} />,
                onClick: () => setSelectedTabItem('Survey')
            })
        }
        console.log('taskTabs ', items)
        setTaskTabs(items);
        //return items
    }

    const handleSave: any = async (event: any, data: any) => {
        if (task?.FeedbackCompleteBeforeTask && task.Completed) {
            const errorCount = isValid()
            if (errorCount > 0) {
                TaskMenuItems(errorCount)
                setAlert(true)
                return
            }
        }
        //childRef.current?.showAlert()
        if (task && token && tenantId) {
            await TaskAPI.save(task, token, tenantId).then(() => {
                if (surveyResponse) {
                    const _surveyResponse = surveyResponse
                    if (task.Completed) {
                        _surveyResponse.Status = 2
                    }
                    console.log(`Task: saving survey communicationId ${task.CommunicationId} `)
                    SurveyAPI.update(task.CommunicationId, _surveyResponse, token, tenantId).then(() => {
                        if (microsoftTeams.dialog.isSupported() === false) {
                            console.log('navigate home')
                            props.postSave();
                        } else {
                            microsoftTeams.dialog.url.submit();
                        }
                    })
                } else {
                    if (microsoftTeams.dialog.isSupported() === false) {
                        console.log('navigate home')
                        props.postSave();
                    } else {
                        microsoftTeams.dialog.url.submit();
                    }
                }
            })
        }
    }

    const getStatus = (task?: ITask) => {
        switch (task?.ResponseStatus) {
            case 0: {
                return (<><Status state="error" title="Not started" /><Text weight="semibold" size="medium">Not started</Text></>)
            }
            case 1: {
                return (<><Status state="warning" title="In progress" /><Text weight="semibold" size="medium"> In progress</Text></>);
            }
            case 2: {
                return (<><Status state="success" title="Complete" /><Text weight="semibold" size="medium"> Complete</Text></>);
            }
            case 3: {
                return (<Text weight="semibold" size="medium">Could not complete</Text>);
            }
        }
    }

    return (
        !loading ?
            <div className="task page" style={{ height: '100%', overflowY: 'auto' }}>
                <div className="narrow">
                    {
                        alert ?
                            <Alert
                                content="You must complete the Survey before completing this task"
                                dismissible
                                dismissAction={{
                                    'aria-label': 'close',
                                }}
                                variables={{
                                    urgent: true,
                                }}
                                onVisibleChange={() => {
                                    setAlert(false)
                                }}
                            />
                            : <></>
                    }
                    <Flex space='between'>
                        <Flex.Item>
                            <Header as="h3" styles={{ marginTop: '0px' }} content={task?.Title} description={{
                                as: 'span',
                                content: <>
                                    <span style={{ marginRight: '5px' }}><Label color='brand' circular content={<Text weight="light" size='small' content={'Due: ' + new Date(task?.TargetDate!).toLocaleDateString()} />}></Label></span>
                                    <span>{getStatus(task)}</span>
                                </>
                            }} />
                        </Flex.Item>
                        <Flex.Item>
                            <Button content="Save" styles={{ marginTop: '1.5em' }} primary onClick={(event, data) => { handleSave(event, data) }} />
                        </Flex.Item>
                    </Flex>
                    <Menu defaultActiveIndex={0} items={taskTabs} underlined secondary />
                    <div id='tabContent' style={{ height: 'fit-content' }}>
                        {TabContent}
                    </div>
                </div>
            </div>
            :
            (
                <Loader style={{ margin: 100 }} />
            )
    )
}