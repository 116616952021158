import { useState, useEffect, useContext } from "react";
import { Flex, FormDropdown, FormTextArea, Text, DropdownItemProps, FormInput, FormCheckbox, FormButton } from "@fluentui/react-northstar"
import { Hierarchy } from '../../interfaces/hierarchy'
import "./Message.css";
import { HierarchyTypeResponse } from '../../interfaces/hierarchyType'
import { TeamsFxContext } from "../Context";

export default function Message(props: any) {

  const [loading, setLoading] = useState<boolean>(true)
  const [token, setToken] = useState<string>();
  const [hierarchyTypes, setHierarchyTypes] = useState<DropdownItemProps[]>();
  const [selectedHierarchyType, setSelectedHierarchyType] = useState<any>();
  const [hierarchies, setHierarchies] = useState<DropdownItemProps[]>();
  const { themeString, teamsUserCredential } = useContext(TeamsFxContext);

  useEffect(() => {
    (async () => {
      var scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];
      let token: any = {}
      token = teamsUserCredential!.getToken(scope);
      setToken(token.token);
      const response = await fetch(`${process.env.REACT_APP_UNIFIED_COMMS_ENDPOINT}/hierarchytype`, {
        headers: !token.token ? {} : { 'Authorization': `Bearer ${token.token}` }
      });
      const data: HierarchyTypeResponse = await response.json();
      setHierarchyTypes(data.Entities.map(h => {
        return {
          header: h.Name,
          key: h.HierarchyTypeID,
          selected: h.IsDefaultForAudience
        }
      }))
      setLoading(false)
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedHierarchyType) {
        const response = await fetch(`${process.env.REACT_APP_UNIFIED_COMMS_ENDPOINT}/hierarchy/type/${selectedHierarchyType.key}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        const data: Hierarchy[] = await response.json();
        console.log(data)
        setHierarchies(data.map(h => {
          return {
            header: h.DisplayName,
            key: h.HierarchyID
          }
        }))
      }
    })();
  }, [selectedHierarchyType, token]);

  const page = {
    margin: '1em'
  };

  const urgent = {
    padding: '5px 3px 3px 0px'
  }

  return (
    !loading && hierarchyTypes ?
      <div style={page}>
        <FormDropdown
          label={{
            content: 'Level'
          }}
          items={hierarchyTypes}
          onChange={(event, data) => {
            if (data.value) {
              setSelectedHierarchyType((data.value))
            }
          }
          }
        />
        <FormDropdown
          label={{
            content: 'Location'
          }}
          search
          multiple
          items={hierarchies}
        />
        <FormCheckbox
          styles={urgent}
          toggle
          labelPosition='start'
          label={{
            content: 'Urgent'
          }}
        />
        <FormInput
          fluid
          label={{
            content: 'Title'
          }}
        />
        <FormTextArea rows={5} fluid label="Body" />
        <Flex gap="gap.smaller" hAlign='end' padding='padding.medium'>
        <FormButton          
            content='Send'
            primary
        />
        <FormButton          
            content='Cancel'
        />
        </Flex>
      </div>
      : <Text content='loading...' />
  )
}