import { useState, useEffect, useContext } from "react";
import { Segment, Flex, Breadcrumb, ListIcon, Loader, ChevronEndIcon, Text, Alert } from "@fluentui/react-northstar"
import { INews } from '../../../interfaces/news'
import NewsList from '../news/NewsList'
import Find from './Find'
import NewsContent from '../news/NewsContent'
import { ErrorWithCode } from "@microsoft/teamsfx";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive"
import { UserAPI } from '../../../api/UserAPI'
import Video from "../video/Video"
import { app } from "@microsoft/teams-js";
import { TeamsFxContext } from "../../Context";

export default function Search(props: { searchTerm?: string }) {

    const [query, setQuery] = useState<string>();
    const [selectedComm, setSelectedComm] = useState<INews>();
    const [resultCount, setResultCount] = useState<number | undefined>()
    const [loading, setLoading] = useState<boolean>(true);
    const [validUser, setValidUser] = useState<boolean>(true);
    const [token, setToken] = useState<string>();
    const navigate = useNavigate();
    const [updateNewsListSocial, setUpdateNewsListSocial] = useState<boolean>(false);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];
    const { themeString, teamsUserCredential } = useContext(TeamsFxContext);

    useEffect(() => {
        if (!app.isInitialized()) {
            app.initialize().then(() => {
                app.getContext().then((context) => {
                    setQuery(context.page.subPageId);
                }).catch((err) => {
                    console.log('search getContext error ', err)
                })
            })
        } else {
            app.getContext().then((context) => {
                setQuery(context.page.subPageId);
            }).catch((err) => {
                console.log('search getContext error ', err)
            })
        }
        loadTab();
    }, []);

    const loadTab = () => {
        teamsUserCredential?.getUserInfo().then((userInfo) => {
            teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                setToken(tokenResponse!.token)
                UserAPI.me(tokenResponse!.token, userInfo.tenantId)
                    .then((user) => {
                        setLoading(false);
                    })
                    .catch((error) => {
                        if (error.response.status.toString().startsWith('4')) {
                            setValidUser(false)
                        }
                        console.log('UserAPI error ', error)
                    })
            }).catch((err: unknown) => {
                if (err instanceof ErrorWithCode && err.code === 'UiRequiredError') {
                    teamsUserCredential!.login(scope).then(() => {
                        teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                            setToken(tokenResponse!.token)
                            UserAPI.me(tokenResponse!.token, userInfo.tenantId)
                                .then((user) => {
                                    setLoading(false);
                                })
                                .catch((error) => {
                                    if (error.response.status.toString().startsWith('4')) {
                                        setValidUser(false)
                                    }
                                    console.log('UserAPI error ', error)
                                })
                        })
                    })
                }
            })
        })
    }

    const onSelectedComm = (selectedComm: any) => {
        setSelectedComm(selectedComm)
    }

    const searchResults: any = (count: number) => {
        setResultCount(count);
    }

    function updateSearchQuery(newSearchTerm?: string) {
        console.log('search updateSearchQuery ', newSearchTerm)
        setQuery(newSearchTerm)
    }

    function refreshNewsListSocial() {
        setUpdateNewsListSocial(!updateNewsListSocial)
    }

    const searchBreadcrumbClick = (event: any) => {
        event.preventDefault();
        console.log('clicked breadcrumb')
        setSelectedComm(undefined);
        navigate("/search", { replace: true });
    }

    const MobileNews = () => {
        if (selectedComm) {
            return (
                <>
                    <Flex column gap="gap.small">
                        <Flex.Item grow>
                            <Segment
                                content={
                                    <Breadcrumb aria-label="breadcrumb" size="medium">
                                        <Breadcrumb.Item onClick={searchBreadcrumbClick}>
                                            <Breadcrumb.Link href="#">
                                                <ListIcon xSpacing="after" />
                                                Search
                                            </Breadcrumb.Link>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Divider>
                                            <ChevronEndIcon size="medium" />
                                        </Breadcrumb.Divider>
                                        <Breadcrumb.Item active aria-current="page">{selectedComm.Title}</Breadcrumb.Item>
                                    </Breadcrumb>}
                            />
                        </Flex.Item>
                    </Flex>
                    <Flex styles={{ overflowY: 'auto', height: '100%' }}>
                        <Flex.Item>
                            <Segment
                                content={!loading ?
                                    selectedComm?.CommunicationType === 'Video' ?
                                        <Video communicationId={selectedComm.CommunicationId} largeVideoSize={false} refreshVideoListSocial={true} />
                                        : <NewsContent selectedComm={selectedComm} refreshNewsListSocial={refreshNewsListSocial} />
                                    : <Flex fill hAlign="center" vAlign="center"><Loader size='larger' /></Flex>}
                            />
                        </Flex.Item>
                    </Flex>
                </>
            )
        } else {
            return !loading ?
                <>
                    <Flex>
                        <Flex.Item grow push>
                            <Segment content={<Find searchQuery={query} updateSearchQuery={updateSearchQuery} />} />
                        </Flex.Item>
                    </Flex>
                    {resultCount === 0 ?
                        <Segment styles={{ width: '100%', height: '100%' }} content={
                            <Alert
                                content="Sorry, you search didn't return any results."
                                dismissible
                                dismissAction={{
                                    'aria-label': 'close',
                                }}
                                variables={{
                                    urgent: true,
                                }}
                            />} />
                        :
                        <div style={{ height: '100%', overflowY: 'auto', paddingBottom: '5em' }}>
                            <NewsList loadDefault={false} onSelectedComm={onSelectedComm} searchResult={searchResults} searchQuery={query} updateNewsListSocial={updateNewsListSocial} />
                        </div>
                    }
                </>
                : <Flex fill hAlign="center" vAlign="center"><Loader size='larger' /></Flex>
        }
    }


    return (
        !validUser ?
            <Flex fill hAlign="center" vAlign="center" column>
                <Text weight='bold' content='You need to be a subscriber of the Metro service from Retail Manager Solutions Ltd to use this application.' />
                <p>Please <a target='_blank' href='https://www.metrofy.com/contact-us'>contact us</a> at Retail Manager Solutions Ltd for more information or support.</p>
            </Flex>
            : !loading ?
                isDesktopOrLaptop ?
                    <>
                        <Flex>
                            <Flex.Item grow push>
                                <Segment content={<Find searchQuery={query} updateSearchQuery={updateSearchQuery} />} />
                            </Flex.Item>
                        </Flex>
                        <Flex styles={{ height: '100%' }}>
                            {resultCount === 0 ?
                                <Segment styles={{ width: '100%', height: '100%' }} content={
                                    <Alert
                                        content="Sorry, you search didn't return any results."
                                        dismissible
                                        dismissAction={{
                                            'aria-label': 'close',
                                        }}
                                        variables={{
                                            urgent: true,
                                        }}
                                    />} />
                                : <>
                                    <Flex.Item size="size.quarter" styles={{ overflowY: 'auto' }}>
                                        <Segment
                                            content={!loading ? <NewsList loadDefault={true} onSelectedComm={onSelectedComm} searchResult={searchResults} searchQuery={query} updateNewsListSocial={updateNewsListSocial} /> : <></>}
                                        />
                                    </Flex.Item>

                                    <Flex.Item grow size="size.half">
                                        <Segment
                                            content={
                                                !loading ?
                                                    selectedComm?.CommunicationType === 'Video' ?
                                                        <Video communicationId={selectedComm.CommunicationId} largeVideoSize={true} refreshVideoListSocial={true} />
                                                        : <NewsContent selectedComm={selectedComm} refreshNewsListSocial={refreshNewsListSocial} />
                                                    : <></>}
                                        />
                                    </Flex.Item>
                                </>}
                        </Flex >

                    </>
                    : <MobileNews />
                : <Flex fill hAlign="center" vAlign="center"><Loader size='larger' /></Flex>
    )

}