import { Grid, Segment, Header } from "@fluentui/react-northstar"
import SurveyList from './SurveyList'
import SurveyResponses from './SurveyResponses'

export default function Surveys() {

    return (
        <>
        <Grid columns="20% 20% 60%" styles={{height:'100%'}}>
        <Segment content={
            <>
            <Header as="h3" content="Surveys" styles={{padding:0}} />
            <SurveyList ongoing={false} />
            <Header as="h3" content="Ongoing Surveys" styles={{padding:0}} />
            <SurveyList ongoing={true} />
            </>
        } />
        <Segment content={<>
            <SurveyResponses/>
            </>
        }/>
        <Segment content='' />
        </Grid>
        
        </>
    )
}