import { useState } from "react";
import { Toolbar, SearchIcon, Input, Button } from "@fluentui/react-northstar"
import * as microsoftTeams from "@microsoft/teams-js";
import { useMediaQuery } from "react-responsive"

export default function Find(props: { searchQuery?: string, updateSearchQuery?: any }) {

    const [searchQuery, setSearchQuery] = useState<string>();
    const handleChange = (e:any) => setSearchQuery(e.currentTarget.value);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    const handleSearch = (event:any) => {
        console.log('find app id: ', process.env.REACT_APP_TEAMS_APP_ID)
        console.log('find pages.currentApp.isSupported', microsoftTeams.pages.currentApp.isSupported())
        console.log(`find pages.config.isSupported ${microsoftTeams.pages.config.isSupported()}`)
        microsoftTeams.pages.getConfig().then((config) => {
            console.log('pages config: ', config)
        })
        console.log('find searchQuery ', searchQuery)
        console.log(`find tabs isSupported ${microsoftTeams.pages.tabs.isSupported()}`)
        microsoftTeams.app.getContext().then((context) =>{
            console.log(`find context.app.host ${context.app.host.name}`)             
            if(microsoftTeams.pages.currentApp.isSupported() && context.app.host.name !== 'Outlook' && context.app.host.name !== 'Office'){
                console.log('find pages.currentApp.navigateTo ', searchQuery)
                microsoftTeams.pages.currentApp.navigateTo({pageId: 'search', subPageId: searchQuery});
            } else {
                if(props.updateSearchQuery){
                    console.log('find Not in Teams and on Search tab already')
                    props.updateSearchQuery(searchQuery);
                } else {
                    console.log('find pages.navigateToApp')
                    // if(microsoftTeams.pages.currentApp.isSupported())
                    // {
                    //     console.log('find launching navigate to with query of ', searchQuery)
                    //     const navigateToParams = {pageId: 'search', subPageId: searchQuery}
                    //     microsoftTeams.pages.currentApp.navigateTo(navigateToParams)
                    // } else {  
                        console.log('find teams app id ', process.env.REACT_APP_TEAMS_APP_ID)     
                        console.log('find m365 office app id ', process.env.REACT_APP_M365_APP_ID)    
                        const appId = context.app.host.name !== 'Teams' ? process.env.REACT_APP_M365_APP_ID : process.env.REACT_APP_TEAMS_APP_ID         
                        console.log('find appId ', appId)
                        microsoftTeams.pages.navigateToApp({appId: appId!, pageId: 'search', subPageId: searchQuery});                
                    //}
                }
            }
        }).catch((error) => {
            console.log('find getContext error ', error)
        })
    } 

    return (
        <Toolbar
            styles={{width:'100%'}}
            items={[
                {
                    key: 'searchToolbar',
                    kind: "custom",
                    content: (
                        <>
                            <Input
                                defaultValue={props.searchQuery}
                                //value={searchQuery}
                                fluid
                                // icon={<SearchIcon onClick={handleSearch } />}                                                            
                                placeholder="Find..."
                                onChange={handleChange}
                                onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                        handleSearch(event);                                        
                                    }
                                }} />
                                <Button circular icon={<SearchIcon />} primary title="Search" onClick={handleSearch } />
                        </>
                    ),
                    styles: {
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: isDesktopOrLaptop ? '70%' : '100%'
                    }
                }]}>
        </Toolbar>
    )
}