import { useState, useEffect, useContext } from "react";
import { Text, Card, Flex, Button, Label, Datepicker, FlexItem, Alert, FilterIcon } from "@fluentui/react-northstar"
import { Dashboard } from "../../../interfaces/dashboard"
import VisibilitySensor from "react-visibility-sensor";
import { CircularProgressbarWithChildren } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css";
import { NewsAPI } from '../../../api/NewsAPI'
import * as microsoftTeams from "@microsoft/teams-js";
import { format } from "date-fns";
import { useMediaQuery } from "react-responsive";
import { TeamsFxContext } from "../../Context";
import { ErrorWithCode } from "@microsoft/teamsfx";

export default function NewsViewed() {

    const [data, setData] = useState<Dashboard[]>();
    const [dateTo, setDateTo] = useState<Date>();
    const [dateFrom, setDateFrom] = useState<Date>();
    const [showFilters, setFilters] = useState(false);
    const [token, setToken] = useState<string>();
    const [tenantId, setTenantId] = useState<string>();
    const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
    const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      })


    useEffect(() => {
        teamsUserCredential?.getUserInfo().then((userInfo) => {
            setTenantId(userInfo.tenantId);
        teamsUserCredential!.getToken(scope).then((tokenResponse) => {
            setToken(tokenResponse!.token)
            load(tokenResponse, userInfo.tenantId)
          }).catch((err: ErrorWithCode) => {
            if (err.code === 'UiRequiredError') {
              teamsUserCredential!.login(scope).then(() => {
                teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                    setToken(tokenResponse!.token)
                    load(tokenResponse, userInfo.tenantId)
                })
              })
            }
        })
    })  
    }, []);

    const load = (tokenResponse:any, tenantId: string) => {
        setDateTo(new Date())
        const date: Date = new Date();
        date.setDate(date.getDate() - 7);
        setDateFrom(date)

        NewsAPI.viewed(format(new Date(date), 'MM/dd/yyyy'), format(new Date(), 'MM/dd/yyyy'), tokenResponse.token, tenantId)
        .then((response) => {
            console.log('News response: ', response)
            setData(response)
        })
    }

    function toggleFilters(){
        setFilters(!showFilters)
    }

    function onFilterSubmit() {
        if (dateFrom && dateTo && token && tenantId){
            NewsAPI.viewed(format(new Date(dateFrom), 'MM/dd/yyyy'), format(new Date(dateTo), 'MM/dd/yyyy'), token, tenantId)
            .then((response) => {
                console.log('News response: ', response)
                setData(response)
            })
        }
    }

    const openNewsCompliance = (communicationId: number, title:string) => {
        if (dateFrom && dateTo){
            console.log(`${process.env.REACT_APP_URL}/index.html#/compliance?communicationId=${communicationId}&dateFrom=${format(new Date(dateFrom), 'MM/dd/yyyy')}&dateTo=${format(new Date(dateTo), 'MM/dd/yyyy')}`)
            let dialogSubmitHandler: microsoftTeams.dialog.DialogSubmitHandler = (result: any) => {
                console.log('closed compliance dialog')
            }
            let urlDialogInfo: microsoftTeams.UrlDialogInfo = {
                url: `${process.env.REACT_APP_URL}/index.html#/compliance?communicationId=${communicationId}&datefrom=${format(new Date(dateFrom), 'MM/dd/yyyy')}&dateto=${format(new Date(dateTo), 'MM/dd/yyyy')}`,
                title: title,
                size: {
                    height: 600,
                    width: 1224
                }
            };
            microsoftTeams.dialog.url.open(urlDialogInfo, dialogSubmitHandler)
        }
    }

    const newsCards = data?.map(d => {
        return (
            <Card
                onClick={() => {openNewsCompliance(d.CommunicationId, d.Title)}}
                fluid
                compact
                styles={{ margin: '0em 0em 1em 0em', padding: '0.5em', height: 'auto', maxWidth: '400px' }}
                horizontal
                aria-roledescription="card with a preview image, text and action buttons"
            >
                <Card.Column>
                    <Card.Header>
                        <Text content={d.Title} weight="bold" />
                    </Card.Header>
                    <Card.Body>
                        <Flex gap="gap.small">
                            <VisibilitySensor partialVisibility={true}>
                                {({ isVisible }) => {
                                    const percentage = isVisible ? d.UnreadPercent : 0;
                                    return (
                                        <CircularProgressbarWithChildren
                                            background={false}
                                            value={percentage}
                                            text={`${percentage}%`}
                                            styles={{
                                                // Customize the root svg element
                                                root: {},
                                                // Customize the path, i.e. the "completed progress"
                                                path: {
                                                    // Path color
                                                    stroke: `rgba(62, 152, 199, ${percentage / 100})`,
                                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                    strokeLinecap: 'round',
                                                    // Customize transition animation
                                                    transition: 'stroke-dashoffset 2.0s ease 0s',
                                                    transformOrigin: 'center center',
                                                },
                                                // Customize the circle behind the path, i.e. the "total progress"
                                                trail: {
                                                    // Trail color
                                                    stroke: '#d6d6d',
                                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                    strokeLinecap: 'round',
                                                    transformOrigin: 'center center',
                                                },
                                                // Customize the text
                                                text: {
                                                    // Text color
                                                    fill: '#000',
                                                    // Text size
                                                    fontSize: '16px',
                                                    fontWeight: 'bolder',
                                                },
                                                // Customize background - only used when the `background` prop is true
                                                background: {
                                                    fill: '#3e98c7',
                                                },
                                            }}
                                        >
                                            <span style={{
                                                height: '85px',
                                                width: '85px',
                                                borderRadius: '50%',
                                                background: 'url(' + d.ThumbnailBlobUrl + ') center no-repeat',
                                                backgroundSize: 'contain',
                                                opacity: '.4',
                                                display: 'inline-block'
                                            }}></span>
                                        </CircularProgressbarWithChildren>
                                    );
                                }}
                            </VisibilitySensor>
                            {/* <Image
                                style={{
                                    height: '115px',
                                    width: '115px',
                                }}
                                src={d.ThumbnailBlobUrl}
                            /> */}
                            <Flex column>
                                <Text content={d.Byline} />
                            </Flex>
                        </Flex>
                    </Card.Body>
                </Card.Column>
            </Card>)
    })

    const handleDateFromChange: any = (event: any, data: any) => {
        setDateFrom(data.value)
    }

    const handleDateToChange: any = (event: any, data: any) => {
        setDateTo(data.value)
    }

    const filtersMobileStyle = {
          display: "block",
          marginBottom:"10px"
      };

    return (
        <div>
            <h4>News <FilterIcon onClick={(e) => { e.preventDefault();toggleFilters()}} styles={{cursor: "pointer"}} data-toggle="tooltip" data-placement="right" title="Date filter"/></h4>
            { dateFrom && dateTo && showFilters ?
                <>
                    <Flex styles={isDesktopOrLaptop ? {marginBottom:"10px"} : filtersMobileStyle} gap="gap.medium">
                        <FlexItem size="size.half">
                            <>
                                <Label content="Date from" styles={{
                                    color: 'rgb(37, 36, 35)',
                                    paddingLeft: '0px',
                                    backgroundColor: 'transparent'
                                }} />
                                <Datepicker 
                                    onDateChange={(event, data) => {
                                        handleDateFromChange(event, data)
                                    }}
                                    defaultSelectedDate={dateFrom}
                                />
                            </>
                        </FlexItem>
                        <FlexItem size="size.half">
                            <>
                                <Label content="Date to" styles={{
                                    color: 'rgb(37, 36, 35)',
                                    paddingLeft: '0px',
                                    backgroundColor: 'transparent'
                                }} />
                                <Datepicker                 
                                    onDateChange={(event, data) => {
                                        handleDateToChange(event, data)
                                    }}
                                    defaultSelectedDate={dateTo}
                                />
                            </>
                        </FlexItem>    
                    </Flex>
                    <Flex styles={{marginBottom:"10px"}}>
                        <Button content="Filter" tinted onClick={() => {onFilterSubmit();}} />  
                    </Flex>
                </>
                :
                <></>
            }
            { data && data?.length > 0 ? 
                <Flex column hAlign="center">
                    {newsCards}
                </Flex> 
                : 
                <Alert info content={ dateFrom && dateTo ? `No results from ${format(new Date(dateFrom), 'dd/MM/yyyy')} to ${format(new Date(dateTo), 'dd/MM/yyyy')}` : "No results"} />}
        </div>
    )
}
