import { useState, useEffect, useContext } from "react";
import { Usage } from '../../../interfaces/news'
import { NewsAPI } from '../../../api/NewsAPI'
import { INews } from '../../../interfaces/news'
import { formatDistance } from 'date-fns'
import { List, Header, ListItemProps } from "@fluentui/react-northstar"
import { useMediaQuery } from "react-responsive"
import { ErrorWithCode } from "@microsoft/teamsfx";
import { TeamsFxContext } from "../../Context";

export default function NewsRead(props: {
    onSelectedMostRead: any
}) {

    const [selectedComm, setSelectedComm] = useState<INews>();
    const [usage, setUsage] = useState<{}[]>();
    const [token, setToken] = useState<string>();
    const [tenantId, setTenantId] = useState<string>();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
    const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

    useEffect(() => {
        teamsUserCredential?.getUserInfo().then((userInfo) => {
            setTenantId(userInfo.tenantId);
            teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                setToken(tokenResponse!.token)
                load(tokenResponse, userInfo.tenantId)
            }).catch((err: ErrorWithCode) => {
                console.log('NewsRead get token error: ', err.code, err.message)
                if (err.code === 'UiRequiredError') {
                    console.log('prompt user to login', process.env.REACT_APP_IDENTIFIER_URI)
                    teamsUserCredential!.login(scope).then(() => {
                        teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                            setToken(tokenResponse!.token)
                            load(tokenResponse, userInfo.tenantId);
                        })
                    })
                }
            })
        })
    }, [teamsUserCredential]);

    const load = (tokenResponse: any, tenantId: string) => {
        NewsAPI.read(tokenResponse!.token, tenantId)
            .then((data) => {
                console.log('News Read ', data)
                setUsage(data.map((i: Usage) => {
                    const distance = formatDistance(new Date(i.PublishedDate), new Date(), {
                        addSuffix: true
                    })
                    return {
                        key: i.CommunicationId,
                        header: i.CommunicationTitle,
                        content: `${distance} by ${i.PublishedBy}`,
                        onClick: () => {
                            NewsAPI.get(i.CommunicationId, tokenResponse!.token, tenantId)
                                .then((data) => {
                                    console.log('Most read loaded news ', data)
                                    setSelectedComm(data)
                                })
                        }
                    };
                }))
            })
    }

    useEffect(() => {
        (async () => {
            props.onSelectedMostRead(selectedComm)
        })();
    }, [selectedComm, props.onSelectedMostRead]);

    return (
        <>
            {(isDesktopOrLaptop ? <Header as="h3" content="Most Read" /> : <></>)}
            <div style={{ overflowY: 'auto', height: '-webkit-fill-available', marginBottom: '30px' }}>
                <List selectable onSelectedIndexChange={(e, data) => {
                    const item = data?.items![data!.selectedIndex!] as ListItemProps
                    console.log('NewsRead setSelectedComm', item)
                    if (token && tenantId) {
                        NewsAPI.get(Object.values(item)[0].key, token, tenantId)
                            .then((data) => {
                                console.log('Most read list selected index changed ', data)
                                setSelectedComm(data)
                            })
                    }
                }} items={usage} />
            </div>
        </>
    )
}