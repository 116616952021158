import { ReactionToPost, CommentToPost, ReportComment } from "../interfaces/news"
import { api } from "./config"

const development = process.env.REACT_APP_DEVELOPER_ENDPOINT && process.env.REACT_APP_DEVELOPER_ENDPOINT !== '' 
          ? process.env.REACT_APP_DEVELOPER_ENDPOINT
          : ''

export const SocialAPI = {
    addReaction: async function (reaction: ReactionToPost, token:string, tenantId:string) {
        const response = await api.request({
            url: `/reaction`,
            method: "POST",
            data: reaction,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response
    },
    updateReaction: async function (reaction: ReactionToPost, token:string, tenantId:string) {
        const response = await api.request({
            url: `/reaction`,
            method: "PUT",
            data: reaction,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response
    },
    removeReaction: async function (reactionId: number, token:string, tenantId:string) {
        const response = await api.request({
            url: `/reaction/${reactionId}`,
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response
    },
    addComment: async function (comment: CommentToPost, token:string, tenantId:string) {
        const response = await api.request({
            url: `/comment`,
            method: "POST",
            data: comment,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response
    },
    reportComment: async function (reportComment: ReportComment, token:string, tenantId:string) {
        const response = await api.request({
            url: `/ReportComment`,
            method: "POST",
            data: reportComment,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response
    },    
    deleteComment: async function (commentId: number, token:string, tenantId:string) {
        const response = await api.request({
            url: `/Comment/${commentId}`,
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response
    }    
}