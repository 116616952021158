import { SpokeForm, SpokeFormTab } from "../../../interfaces/spokeForm"
import { SpokeFormResponse } from "../../../interfaces/spokeFormResponse"
import {
    SurveyTextbox,
    SurveyNumber,
    SurveyTextArea,
    SurveyDropdown,
    SurveyCheckboxList,
    SurveyCheckbox,
    SurveyRadioGroup,
    SurveyMatrix,
    SurveyImage,
    SurveyDatePicker
} from "./SurveyControls"

export function SurveyTab(props: {
    selectedTabId?: any,
    surveyForm?: SpokeForm,
    surveyResponse?: SpokeFormResponse,
    handleChange: any,
    validate: boolean,
    parent?: string
}) {

    const tab = props.surveyForm?.SpokeFormTabs.filter((tab: SpokeFormTab) => { return tab.SpokeFormTabId === props.selectedTabId })[0]

    const Questions = () => {
        return (<div style={{height: '100%'}}>{
            tab?.SpokeFormItems!.map((item) => {
                let questionResponse = props.surveyResponse?.SpokeFormQuestionResponses.find(questionResponse => questionResponse.SpokeFormQuestionId === item.SpokeFormQuestionId) ?? { SpokeFormQuestionId: item.SpokeFormQuestionId }
                switch (item.SpokeFormQuestion.ControlType) {
                    case 0:
                    default:    
                    {
                        return <SurveyTextbox
                            key={item.SpokeFormQuestion.SpokeFormQuestionId}
                            validate={props.validate}
                            spokeFormQuestion={item.SpokeFormQuestion}
                            spokeFormQuestionResponse={questionResponse}
                            handleChange={props.handleChange} />
                    }
                    case 1: {
                        return <SurveyNumber
                            key={item.SpokeFormQuestion.SpokeFormQuestionId}
                            spokeFormQuestion={item.SpokeFormQuestion}
                            validate={props.validate}
                            spokeFormQuestionResponse={questionResponse}
                            handleChange={props.handleChange} />
                    }
                    case 2: {
                        return <SurveyTextArea
                            key={item.SpokeFormQuestion.SpokeFormQuestionId}
                            validate={props.validate}
                            spokeFormQuestion={item.SpokeFormQuestion}
                            spokeFormQuestionResponse={questionResponse}
                            handleChange={props.handleChange} />
                    }
                    case 3: {
                        return <SurveyDropdown
                            validate={props.validate}
                            spokeFormQuestion={item.SpokeFormQuestion}
                            spokeFormQuestionResponse={questionResponse}
                            handleChange={props.handleChange}
                            key={item.SpokeFormQuestion.SpokeFormQuestionId} />
                    }
                    case 4: {
                        return <SurveyCheckboxList key={item.SpokeFormQuestion.SpokeFormQuestionId}
                            validate={props.validate}
                            spokeFormQuestion={item.SpokeFormQuestion}
                            spokeFormQuestionResponse={questionResponse}
                            handleChange={props.handleChange} />
                    }
                    case 5: {
                        return <SurveyCheckbox key={item.SpokeFormQuestion.SpokeFormQuestionId}
                            spokeFormQuestion={item.SpokeFormQuestion}
                            spokeFormQuestionResponse={questionResponse}
                            handleChange={props.handleChange} />
                    }
                    case 6: {
                        return <SurveyRadioGroup key={item.SpokeFormQuestion.SpokeFormQuestionId}
                            validate={props.validate}
                            spokeFormQuestion={item.SpokeFormQuestion}
                            spokeFormQuestionResponse={questionResponse}
                            handleChange={props.handleChange} />
                    }
                    case 7: {
                        return <SurveyDatePicker key={item.SpokeFormQuestion.SpokeFormQuestionId}
                            validate={props.validate}
                            spokeFormQuestion={item.SpokeFormQuestion}
                            spokeFormQuestionResponse={questionResponse}
                            handleChange={props.handleChange} />
                    }
                    case 8: {
                        return <SurveyMatrix key={item.SpokeFormQuestion.SpokeFormQuestionId}
                            validate={props.validate}
                            spokeFormQuestion={item.SpokeFormQuestion}
                            spokeFormQuestionResponse={questionResponse}
                            handleChange={props.handleChange} />
                    }
                    case 9: {
                        return <SurveyImage key={item.SpokeFormQuestion.SpokeFormQuestionId}
                            spokeFormQuestion={item.SpokeFormQuestion} />
                    }
                    case 10: {
                        return <SurveyRadioGroup key={item.SpokeFormQuestion.SpokeFormQuestionId}
                            validate={props.validate}
                            spokeFormQuestion={item.SpokeFormQuestion}
                            spokeFormQuestionResponse={questionResponse}
                            handleChange={props.handleChange} />
                    }
                    case 11: {
                        return <SurveyRadioGroup key={item.SpokeFormQuestion.SpokeFormQuestionId}
                            validate={props.validate}
                            spokeFormQuestion={item.SpokeFormQuestion}
                            spokeFormQuestionResponse={questionResponse}
                            handleChange={props.handleChange} />
                    }
                }
            })
        }</div>)
    }
    return (
        <Questions />
    )
}