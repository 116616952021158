// https://fluentsite.z22.web.core.windows.net/quick-start
import { Provider, teamsTheme, Loader } from "@fluentui/react-northstar";
import { useEffect } from "react";
import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { app } from "@microsoft/teams-js";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import Tab from "./Tab";
import "./App.css";
import TabConfig from "./TabConfig";
import News from "./unifiedcomms/news/News"
import Videos from "./unifiedcomms/video/Videos"
import Tasks from "./unifiedcomms/task/Tasks"
import Task from "./unifiedcomms/task/Task"
import Survey from "./unifiedcomms/survey/Survey"
import Answers from "./unifiedcomms/faq/Answers"
import Search from "./unifiedcomms/search/Search"
import Dashboard from "./unifiedcomms/Dashboard"
import Message from "./unifiedcomms/Message"
import Surveys from "./unifiedcomms/survey/Surveys"
import Home from './unifiedcomms/Home'
import Compliance from "./unifiedcomms/Compliance";
import { TeamsFxContext } from "./Context";
import config from "./sample/lib/config";
import { useTeamsFx } from "./sample/lib/useTeamsFx";

export default function App() {
  const { theme:themeFx } = useTeamsFx();
  let { theme, loading, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!,
  });

  useEffect(() => {
    loading &&
      app.initialize().then(() => {
        // Hide the loading indicator.
        app.notifySuccess();
      });
  }, [loading]);
  
  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
      <Provider theme={themeFx || teamsTheme} styles={{ backgroundColor: "#eeeeee", maxHeight: '100%', overflowY: 'hidden' }}>
        {loading ? (
          <Loader style={{ margin: 100 }} />
        ) : (
          <div style={{ height: '100%' }}>
            <Router>
              <Routes>
                <Route path="/" element={<Navigate to="/tab" replace />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/termsofuse" element={<TermsOfUse />} />
                <Route path="/tab" element={<Tab />} />
                <Route path="/config" element={<TabConfig />} />
                <Route path="/news" element={<News />} />
                <Route path="/videos" element={<Videos />} />
                <Route path="/home" element={<Home />} />
                <Route path="/tasks" element={<Tasks />} />
                <Route path="/task" element={<Task />} />
                <Route path="/answers" element={<Answers />} />
                <Route path="/survey" element={<Survey />} />
                <Route path="/surveys" element={<Surveys />} />
                <Route path="/search" element={<Search />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/message" element={<Message />} />
                <Route path="/compliance" element={<Compliance />} />
              </Routes>
            </Router>
          </div>
        )}
      </Provider>
    </TeamsFxContext.Provider >
  );
}
