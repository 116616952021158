import { useState, useEffect, useContext } from "react";
import { CommentToPost } from '../../../interfaces/news'
import { Flex, Button, TextArea } from "@fluentui/react-northstar"
import { SocialAPI } from "../../../api/SocialAPI";
import { TeamsFxContext } from "../../Context";
import { ErrorWithCode } from "@microsoft/teamsfx";

export function CommentPost(props: { communicationId: number, refreshSocial: any }) {
    const [commentText, setCommentText] = useState('');
    const handleCommentTextChange = (e: any) => { setCommentText(e.target.value); };
    const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
    const [token, setToken] = useState<string>();
    const [tenantId, setTenantId] = useState<string>();
    const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

    useEffect(() => {
        setCommentText('')
        teamsUserCredential?.getUserInfo().then((userInfo) => {
            setTenantId(userInfo.tenantId);
            teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                setToken(tokenResponse!.token)
            }).catch((err: ErrorWithCode) => {
                if (err.code === 'UiRequiredError') {
                    teamsUserCredential!.login(scope).then(() => {
                        teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                            setToken(tokenResponse!.token)
                        })
                    })
                }
            })
        })
    }, [])

    return (
        <Flex gap="gap.smaller">
            <TextArea id="commentText"
                name="commentText"
                value={commentText}
                onChange={handleCommentTextChange}
                fluid
                placeholder="Join the discussion..."
                style={{ marginBottom: '15px' }} />
            <Button content="Post" tinted style={{ margin: "auto" }} onClick={async (event) => {
                if (commentText.length === 0) {
                    return
                }
                if (token && tenantId) {
                    const comment: CommentToPost = {
                        CommunicationId: props.communicationId,
                        CommentText: commentText,
                        InReplyTo: undefined
                    }

                    await SocialAPI.addComment(comment, token, tenantId)
                        .then(async (response) => {
                            console.log(response);
                            props.refreshSocial();
                        })
                        .catch((error) => {
                            console.error(error.response.status);
                        })
                }
            }} />
        </Flex>)
}
