import { api } from "./config"

const development = process.env.REACT_APP_DEVELOPER_ENDPOINT && process.env.REACT_APP_DEVELOPER_ENDPOINT !== '' 
          ? process.env.REACT_APP_DEVELOPER_ENDPOINT
          : ''

export const UserAPI = {
    me: async function (token:string, tenantId:string) {
        const response = await api.request({
            url: `/user`,
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response.data
    }
}