import { api } from "./config"

const development = process.env.REACT_APP_DEVELOPER_ENDPOINT && process.env.REACT_APP_DEVELOPER_ENDPOINT !== ''
    ? process.env.REACT_APP_DEVELOPER_ENDPOINT
    : ''

export const NewsAPI = {
    all: async function (token: string, tenantId: string) {
        if (token) {
            const response = await api.request({
                url: `/news`,
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Audience': development !== '' ? development : tenantId
                }
            })
            return response.data
        } else {
            console.log('NewsAPI.all: error missing token')
        }
    },
    search: async function (query: string, token: string, tenantId: string) {
        const response = await api.request({
            url: `/search?query=${query}`,
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response.data
    },
    get: async function (id: number, token: string, tenantId: string) {
        const response = await api.request({
            url: `/news/${id}`,
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response.data
    },
    read: async function (token: string, tenantId: string) {
        const response = await api.request({
            url: `/mostread`,
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response.data
    },
    viewed: async function (dateFrom: string, dateTo: string, token: string, tenantId: string) {
        const response = await api.request({
            url: `/viewed?from=${dateFrom}&to=${dateTo}`,
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response.data
    },
    compliance: async function (communicationId: number, dateFrom: string, dateTo: string, token: string, tenantId: string) {
        const response = await api.request({
            url: `/newscompliance?id=${communicationId}&from=${dateFrom}&to=${dateTo}`,
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response.data
    }
}