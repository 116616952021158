import { KnowHow } from "../interfaces/faq"
import { api } from "./config"

const development = process.env.REACT_APP_DEVELOPER_ENDPOINT && process.env.REACT_APP_DEVELOPER_ENDPOINT !== '' 
          ? process.env.REACT_APP_DEVELOPER_ENDPOINT
          : ''

export const FaqAPI = {
    all: async function (token:string, tenantId:string) {
        const response = await api.request({
            url: `/faq`,
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response.data
    },
    get: async function (knowHowId:string, token:string, tenantId:string) {
        const response = await api.request({
            url: `/faq/${knowHowId}`,
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response.data
    },
    post: async function (knowHow: KnowHow, token:string, tenantId:string) {
        const response = await api.request({
            url: `/faq`,
            method: "POST",
            data: knowHow,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response
    }  
}