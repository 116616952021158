import { useState, useEffect } from "react";
import { AddIcon, SearchIcon } from "@fluentui/react-icons-northstar"
import { Grid, Input, Toolbar, Provider, Text } from "@fluentui/react-northstar"
import Questions from '../faq/Questions'
import TaskList from './TaskList'
import TaskCompliance from './TaskCompliance'

export default function Tasks() {

  const [taskComplianceKey, setTaskComplianceKey] = useState<string>(Math.random().toString(36).slice(2, 7))

  const handleChange: any = () => {
    console.log('Home screen handle task change ', taskComplianceKey)
    const newKey = Math.random().toString(36).slice(2, 7)
    console.log('new Task key ', newKey)
    setTaskComplianceKey(newKey)
  }

  const handleTaskView: any = () => {

  }

  return (
    <Provider>
      <Toolbar
        styles={{
          padding: '0.5em'
        }}
        items={[
          {
            kind: "custom",
            key: "createNewTask",
            content: (<>
              <AddIcon
                {...{
                  size: 'small',
                  xSpacing: 'after'
                }}
              />
              <Text content="Ask a question" /></>
            ),
            //onClick: () => console.log('Creating new task')
          },
          {
            kind: "custom",
            content: (
              <Input
                icon={<SearchIcon />}
                placeholder="Find..." />
            ),
            styles: {
              marginLeft: 'auto'
            }
          }]}>
      </Toolbar>
      <Grid columns="60% 40%">
        <TaskList handleChange={handleChange} handleTaskView={handleTaskView}/>
        <Grid rows="40% 60%">
          <TaskCompliance key={taskComplianceKey} />
          <Questions />
        </Grid>
      </Grid>
    </Provider>
  );
}