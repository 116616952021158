import { Social} from '../../../interfaces/news'
import { Chat, Avatar } from "@fluentui/react-northstar"
import { Reactions } from './Reactions'
import { CommentMenuButton } from './CommentMenuButton'
import { formatDistance } from 'date-fns'

export function SocialChat(props: { social: Social, 
                                setCommentToReply:any,
                                setCommentToReport:any,
                                getCurrentUserReaction:any, 
                                toggleCommentReply: any, 
                                toggleCommentReport: any, 
                                refreshSocial: any }) {
 
        enum contentPosition {
            start = 'start',
            end = 'end'
        }                                        

        let items: any = [];
        props.social?.Comments.forEach((c) => {
            items.push(
                {
                    gutter: (<Avatar name={c.HierarchyFriendlyName} image={c.UserImageUrl} />),
                    message: (
                        <Chat.Message
                            actionMenu = {<span>
                                            <CommentMenuButton key={c.CommentId} comment={c} 
                                                    setCommentToReply={props.setCommentToReply} 
                                                    setCommentToReport={props.setCommentToReport}
                                                    getCurrentUserReaction={props.getCurrentUserReaction}
                                                    toggleCommentReply={props.toggleCommentReply}
                                                    toggleCommentReport={props.toggleCommentReport}
                                                    refreshSocial={props.refreshSocial}/>
                                            </span>}
                            content={c.CommentText}
                            author={c.HierarchyFriendlyName}
                            timestamp={formatDistance(new Date(c.AuditDate), new Date())}
                            reactionGroup={[
                                { icon: <p><Reactions reactions={c.Reactions} /></p>, key: 'up' }
                                ]} />
                    ),
                    key: c.CommentId
                });
            if (c.CommentReplies.length > 0) {
                items.push({
                    gutter: (<Avatar name={c.CommentReplies[0].HierarchyFriendlyName} image={c.CommentReplies[0].UserImageUrl} />),
                    message: (
                        <Chat.Message
                            actionMenu = {<span>
                                            <CommentMenuButton key={c.CommentReplies[0].CommentId} comment={c.CommentReplies[0]} 
                                                    setCommentToReply={props.setCommentToReply} 
                                                    setCommentToReport={props.setCommentToReport}
                                                    getCurrentUserReaction={props.getCurrentUserReaction}
                                                    toggleCommentReply={props.toggleCommentReply}
                                                    toggleCommentReport={props.toggleCommentReport}
                                                    refreshSocial={props.refreshSocial}/>
                                        </span>}
                            styles={{ marginRight: 0, maxWidth: '100%' }}
                            content={c.CommentReplies[0].CommentText}
                            author={c.CommentReplies[0].HierarchyFriendlyName}
                            timestamp={formatDistance(new Date(c.CommentReplies[0].AuditDate), new Date())}
                            reactionGroup={[
                                { icon: <p><Reactions reactions={c.CommentReplies[0].Reactions} /></p>, key: 'up' }
                                ]} />
                    ),
                    contentPosition: contentPosition.end,
                    key: c.CommentReplies[0].CommentId
                })
            }
        });
        
        return <Chat items={items} />
}
