import { useState, useEffect, useCallback, useContext } from "react";
import { InfoIcon, ToDoListIcon } from "@fluentui/react-icons-northstar"
import {
  Flex,
  Pill,
  Status,
  Table,
  TableRowProps,
  Button,
  Card,
  CardHeader,
  CardBody,
  Text
} from "@fluentui/react-northstar"
import { MyTasks, ITask } from "../../../interfaces/task"
import * as microsoftTeams from "@microsoft/teams-js";
import { TaskAPI } from "../../../api/TaskAPI";
import { useMediaQuery } from "react-responsive"
import { ITaskView } from "../../../interfaces/taskView";
import { TeamsFxContext } from "../../Context";
import { ErrorWithCode } from "@microsoft/teamsfx";

export default function TaskList(props: { handleChange: any, handleTaskView: any }) {

  const [dialogSupported, setDialogSupported] = useState<boolean>();
  const [myTasks, setMyTasks] = useState<MyTasks>();
  const [selectedButton, setSelectedButton] = useState<string>()
  const [rowData, setrowData] = useState<Array<TableRowProps>>();
  const [token, setToken] = useState<string>();
  const [tenantId, setTenantId] = useState<string>();
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
  const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

  const showTasks = (tasks: ITask[], selectedButton: string) => {
    console.log('showTasks selectedButton ', selectedButton)
    if (isDesktopOrLaptop) {
      return tasks?.map(t => ({
        key: t.CommunicationId,
        items: [
          {
            content: t.Title,
            truncateContent: true,
            styles: { maxWidth: '300px' }
          },
          {
            content: getTaskType({ taskType: t.TaskTypeTitle }),
            styles: { maxWidth: '150px' }
          },
          {
            content: getPriority({ priority: t.Priority }),
            styles: { maxWidth: '150px' }
          },
          {
            content: getStatus({ status: t.ResponseStatus }),
            compact: true,
            styles: { maxWidth: '100px' }
          }
        ],
        onClick: () => { openTask(t.CommunicationId, t.RepeatDate, selectedButton) }
      }))
    } else {
      return tasks?.map(t => ({
        key: t.CommunicationId,
        items: [
          {
            content: <Flex gap="gap.small" vAlign="center" column>
              <Flex.Item>
                <Text weight="semibold" styles={{ width: '200px', marginLeft: '0.25rem', marginBottom: '0 !important' }} truncated content={t.Title} />
              </Flex.Item>
              <Flex.Item>
                <Flex gap="gap.smaller">
                  <Flex.Item>
                    {getTaskType({ taskType: t.TaskTypeTitle })}
                  </Flex.Item>
                  <Flex.Item>
                    {getPriority({ priority: t.Priority })}
                  </Flex.Item>
                </Flex>
              </Flex.Item>
            </Flex>
          },
          {
            content: getStatus({ status: t.ResponseStatus }),
            compact: true,
            styles: { maxWidth: '100px' }
          }
        ],
        onClick: () => { openTask(t.CommunicationId, t.RepeatDate, selectedButton) }
      }))
    }
  }

  useEffect(() => {
    teamsUserCredential?.getUserInfo().then((userInfo) => {
      setTenantId(userInfo.tenantId);
      teamsUserCredential!.getToken(scope).then((tokenResponse) => {
        console.log('Video got tokenResponse ', tokenResponse)
        setToken(tokenResponse!.token)
        load(tokenResponse, userInfo.tenantId)
      }).catch((err: ErrorWithCode) => {
        console.log('Video get token error: ', err.code, err.message)
        if (err.code === 'UiRequiredError') {
          console.log('prompt user to login', process.env.REACT_APP_IDENTIFIER_URI)
          teamsUserCredential!.login(scope).then(() => {
            teamsUserCredential!.getToken(scope).then((tokenResponse) => {
              setToken(tokenResponse!.token)
              load(tokenResponse, userInfo.tenantId)
            })
          })
        }
      })
    })
  }, []);

  const load = (tokenResponse: any, tenantId: string) => {
    TaskAPI.getMyTasks(tokenResponse.token, tenantId)
      .then(async (response) => {
        let data: MyTasks = response;
        setDialogSupported(microsoftTeams.dialog.isSupported())
        setMyTasks(data);
        setSelectedButton('today')
      })
  }

  useEffect(() => {
    switch (selectedButton) {
      case 'today':
        setrowData(showTasks(myTasks!.TodayTasks, selectedButton))
        break;
      case 'oustanding':
        setrowData(showTasks(myTasks!.OutstandingTasks, selectedButton))
        break;
      case 'prepare':
        setrowData(showTasks(myTasks!.PrepareTasks, selectedButton))
        break;
    }
  }, [myTasks, selectedButton])

  const openTask = (communicationId: number, repeatDate: any, selectedButton?: string) => {
    if (!dialogSupported) {
      props.handleTaskView({
        communicationId: communicationId,
        repeatDate: repeatDate,
        selectedButton: selectedButton
      })
    } else {
      if (token && tenantId) {
        let dialogSubmitHandler: microsoftTeams.dialog.DialogSubmitHandler = (result: any) => {
          TaskAPI.getMyTasks(token, tenantId)
            .then(async (response) => {
              let data: MyTasks = response;
              setMyTasks(data);
              setSelectedButton(selectedButton)
              props.handleChange();
            })
        }
        let urlDialogInfo: microsoftTeams.UrlDialogInfo = {
          url: `${process.env.REACT_APP_URL}index.html#/task?communicationId=${communicationId}&repeatDate=${repeatDate}&selectedButton=${selectedButton}`,
          title: "Task",
          size: {
            height: 650,
            width: 1224
          }
        };
        microsoftTeams.dialog.url.open(urlDialogInfo, dialogSubmitHandler)
      }
    }
  }

  const getTaskType = ({ taskType }: { taskType: string }) => {
    switch (taskType) {
      case 'Action': {
        return (<Pill size='small' icon={<ToDoListIcon outline />}>
          Action
        </Pill>)
      }
      default: {
        return (<Pill size='small' icon={<InfoIcon />}>
          Information
        </Pill>)
      }
    }
  }

  const getStatus = ({ status }: { status: number }) => {
    switch (status) {
      case 0: {
        return (<><Status state="error" title="Not started" /><Text> Not started</Text></>)
      }
      case 1: {
        return (<><Status state="warning" title="In progress" /><Text> In progress</Text></>);
      }
      case 2: {
        return (<><Status state="success" title="Complete" /><Text> Complete</Text></>);
      }
      case 3: {
        return (<Text>Could not complete</Text>);
      }
    }
  }

  const getPriority = ({ priority }: { priority: number }) => {
    switch (priority) {
      case 1: {
        return (<Pill content="High" size="small" styles={{ borderColor: 'red', color: 'red', display: "flex", justifyContent: "center" }} appearance="outline" />)
      }
      case 2: {
        return (<Pill content="Medium" size="small" styles={{ borderColor: 'orange', color: 'orange', display: "flex", justifyContent: "center" }} appearance="outline" />)
      }
      case 3: {
        return (<Pill content="Low" size="small" styles={{ borderColor: 'blue', color: 'blue', display: "flex", justifyContent: "center" }} appearance="outline" />)
      }
    }
  };

  const header = (
    isDesktopOrLaptop ?
      {
        items: [
          {
            content: 'Title',
            styles: { maxWidth: '300px' }
          },
          {
            content: 'Type',
            styles: { maxWidth: '150px' }
          },
          {
            content: 'Priority',
            styles: { maxWidth: '150px' }
          },
          {
            content: 'Status',
            styles: { maxWidth: '100px' }
          }]
      }
      :
      {
        items: [
          {
            content: 'Title',
            styles: { maxWidth: '600px' }
          },
          {
            content: 'Status',
            styles: { maxWidth: '100px' }
          }]
      })

  return (
    dialogSupported !== undefined ?
      <Card fluid styles={{ margin: '0em 0em 0em 0.5em' }}>
        <CardHeader>
          <Text content="Tasks" weight="bold" />
          {/* Needed for Outlook client */}
          <Text content={`dialog supported ${dialogSupported}`} styles={{ display: 'none' }} />
        </CardHeader>
        <CardBody>
          <Flex>
            <Button
              primary={selectedButton === 'today'}
              size='small'
              text={true}
              content={{
                content: `Today (${myTasks?.TodayTasks !== undefined ? myTasks?.TodayTasks.length : 0})`
              }}
              onClick={(e) => {
                setSelectedButton('today');
                setrowData(showTasks(myTasks!.TodayTasks, 'today;'))
              }
              } />
            <Button
              primary={selectedButton === 'outstanding'}
              size="small"
              text content={{
                content: `Outstanding (${myTasks?.OutstandingTasks !== undefined ? myTasks?.OutstandingTasks.length : 0})`
              }}
              onClick={(e) => {
                setSelectedButton('outstanding');
                setrowData(showTasks(myTasks!.OutstandingTasks, 'outstanding'))
              }
              } />
            <Button
              size="small"
              primary={selectedButton === 'prepare'}
              text content={{
                content: `Prepare (${myTasks?.PrepareTasks !== undefined ? myTasks?.PrepareTasks.length : 0})`
              }}
              onClick={(e) => {
                setSelectedButton('prepare');
                setrowData(showTasks(myTasks!.PrepareTasks, 'prepare'))
              }
              } />
          </Flex>
          <Table compact={false} header={header} rows={rowData} aria-label="Static table" />
        </CardBody>
      </Card> : <></>
  )
}