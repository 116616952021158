import { Flex, FlexItem, Attachment, WordColorIcon, FilesPdfColoredIcon, PowerPointColorIcon, FilesTextColoredIcon, FilesZipIcon, FilesEmptyIcon, ExcelColorIcon, FilesImageIcon, FilesHtmlColoredIcon } from "@fluentui/react-northstar"
import { useState } from "react";
import { Content } from "../../../interfaces/content";
import PdfViewer from "./PdfViewer";

export function Attachments(props: { content?: Content[] }) {
    
    const [showPdf, setShowPdf] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [pdfFileName, setPdfFileName] = useState('');
    const files = props.content?.filter(c => c.FileName !== '')

    function onDownloadClick(fileUrl:string, fileName:string) {
        fetch(fileUrl).then(response => {
            response.blob().then(blob => {
                // Creating new object file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = fileName;
                alink.click();
            })
        })
    }

    function togglePdf(){
        setShowPdf(!showPdf)
    }

    const attachments = files?.map((f) => {
        if(f.mimeType === 'application/pdf')
        {
            f.FileName = f.FileName.substring(0, f.FileName.lastIndexOf(".") + 1) + "pdf"
        }
        return (
            <FlexItem key={f.ContentId} grow>
                {(f.mimeType === 'application/pdf') ? 
                    <Attachment icon={ GetAttachmentIcon(f.FileName)} header={f.FileName} onClick={() => {
                        setPdfUrl(f.contentUrl); 
                        setPdfFileName(f.FileName); 
                        setShowPdf(true); 
                    }} />  
                    :
                    <Attachment icon={ GetAttachmentIcon(f.FileName)} header={f.FileName} onClick={() => {onDownloadClick(f.contentUrl, f.FileName);}} />}
            </FlexItem>
        )
    })

    return (
        <Flex fill column gap="gap.small">
            {attachments}
            {showPdf ?
                <PdfViewer pdfUrl={pdfUrl} pdfFileName={pdfFileName} togglePdf={togglePdf}/>
                :
                <></>
            }
        </Flex>
    )


    function GetAttachmentIcon(fileName: string){
        switch (fileName.substring(fileName.lastIndexOf(".") + 1)) {
            case "docx":
            case "docm":
            case "doc":
            case "dot":
            case "dotx":
                return <WordColorIcon />
            case "xls":
            case "xlsx":
            case "csv":
                return <ExcelColorIcon />                
            case "pdf":
                return <FilesPdfColoredIcon />
            case "pptx":
                return <PowerPointColorIcon />
            case "txt":
                return <FilesTextColoredIcon />
            case "html":
                return <FilesHtmlColoredIcon />                
            case "zip":
                return <FilesZipIcon />
            case "jpg":
            case "png":           
                return <FilesImageIcon />
            default:
                return <FilesEmptyIcon />
        }
    }
}
