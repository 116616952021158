import { useState, useEffect, useContext } from "react";
import TaskCompliance from './task/TaskCompliance'
import NewsViewed from './news/NewsViewed'
import { Flex, Segment } from "@fluentui/react-northstar";
import { TeamsFxContext } from "../Context";

export default function Dashboard() {
    
    const [token, setToken] = useState<string>(); 
    const [taskComplianceKey, setTaskComplianceKey] = useState<string>(Math.random().toString(36).slice(2, 7))
    const { teamsUserCredential } = useContext(TeamsFxContext);

    useEffect(() => {
        (async () => {
          const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];
          const token = await teamsUserCredential!.getToken(scope);
          setToken(token?.token)
        })();
      }, []);

      return(
<Flex gap="gap.small" padding="padding.medium">
      <Flex.Item size="size.quarter">
        <Segment content={<TaskCompliance key={taskComplianceKey}/>} />
      </Flex.Item>

      <Flex.Item size="size.quarter">
        <Segment content="1/4" />
      </Flex.Item>

      <Flex.Item size="size.half">
        {token ? <Segment content={<NewsViewed />} /> : <></>}
      </Flex.Item>
    </Flex>
      )
}