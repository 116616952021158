import { useState, useEffect, useCallback, useContext } from "react";
import { Segment, Accordion, Loader, Box, Image, List, Layout, Label, VideoCameraEmphasisIcon, Flex, Text } from "@fluentui/react-northstar"
import Video from "./Video"
import Banner from '../Banner'
import { ReactionsSummary } from './../social/Reactions'
import { useMediaQuery } from "react-responsive"
import { INews } from '../../../interfaces/news'
import { VideoLabel } from '../../../interfaces/videoLabel'
import { ErrorWithCode } from "@microsoft/teamsfx";
import { formatDistance } from 'date-fns'
import { VideosAPI } from '../../../api/VideosAPI'
import { TeamsFxContext } from "../../Context";

export default function Videos() {

    const [updateNewsListSocial, setUpdateNewsListSocial] = useState<boolean>(false);
    const [labelVideosLoading, setLabelVideosLoading] = useState<boolean>(true);
    const [videoLabels, setVideoLabels] = useState<VideoLabel[]>();
    const [selectedLabelIndex, setSelectedLabelIndex] = useState<number>(0);
    const [selectedVideoId, setSelectedVideoId] = useState<number>();
    const [videosForLabel, setVideosForLabel] = useState<INews[]>();
    const [token, setToken] = useState<string>();
    const [tenantId, setTenantId] = useState<string>();
    const [validUser, setValidUser] = useState<boolean>(true);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
    const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

    useEffect(() => {
        teamsUserCredential?.getUserInfo().then((userInfo) => {
            setTenantId(userInfo.tenantId);
            teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                console.log('Videos tokenResponse ', tokenResponse)
                setToken(tokenResponse!.token)
            }).catch((err: unknown) => {
                if (err instanceof ErrorWithCode && err.code === 'UiRequiredError') {
                    teamsUserCredential!.login(scope).then(() => {
                        teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                            console.log('Videos tokenResponse ', tokenResponse)
                            setToken(tokenResponse?.token)
                        })
                    })
                }
            })
        })
    }, [])

    const fetchVideos = useCallback(() => {
        console.log('Videos token ', token)
        if (token && tenantId) {
            VideosAPI.getLabels(token, tenantId)
                .then(async (response) => {
                    const labels: VideoLabel[] = response;
                    setVideoLabels(labels)
                    if (labels.length > 0) {
                        console.log(labels[0].Label)
                        VideosAPI.getForLabel(labels[selectedLabelIndex].Label, token, tenantId)
                            .then(async (response) => {
                                const videos: INews[] = response;
                                console.log(videos)
                                setVideosForLabel(videos?.slice(0, 10)) //get first 10
                                setLabelVideosLoading(false)
                                if (videos[0]) {
                                    setSelectedVideoId(videos[0].CommunicationId)
                                }
                            })
                    }
                }).catch((error) => {
                    if (error.response.status.toString().startsWith('4')) {
                        setValidUser(false)
                    }
                    console.log('Video getLabels error ', error)
                })
        }
    }, [selectedLabelIndex, token])

    useEffect(() => {
        fetchVideos()
    }, [selectedLabelIndex, updateNewsListSocial, token]);

    async function getVideosForLabel(activeIndex: number | number[]) {
        if (selectedLabelIndex === (typeof (activeIndex) === "number" ? activeIndex : activeIndex[0]) || activeIndex === -1) return
        setLabelVideosLoading(true)
        setVideosForLabel([])
        setSelectedLabelIndex(typeof (activeIndex) === "number" ? activeIndex : activeIndex[0])
    }

    async function getVideo(selectedIndex: number) {
        console.log(videosForLabelItems[selectedIndex].key)
        if (selectedVideoId === videosForLabelItems[selectedIndex].key) return
        setSelectedVideoId(videosForLabelItems[selectedIndex].key)
    }

    const videosForLabelItems: any = []
    videosForLabel?.forEach(video => {
        videosForLabelItems.push({
            key: video.CommunicationId,
            styles: { height: '80px' },
            media: (
                <Image
                    style={{
                        height: '60px',
                        width: '60px',
                    }}
                    src={video.ThumbnailUrl?.length > 0 ? video.ThumbnailUrl : "/square-image.png"}
                />
            ),
            header: <>
                <Flex>
                    <Text weight="semibold" content={video.Title} />
                </Flex>
                <Flex>
                    <Text weight="light" size="small" content={formatDistance(new Date(video.DateStart), new Date())} />
                </Flex>
            </>,
            content: video?.SocialSummary && video?.SocialSummary.SocialEnabled ?
                <Flex>
                    <ReactionsSummary reactions={video.SocialSummary.ReactionsCount} commentsCount={video.SocialSummary?.CommentsCount} />
                </Flex> : null,
        })
    });

    const labelPanels: any = []
    videoLabels?.forEach(label => {
        labelPanels.push({
            key: label.Label,
            title: (
                <Layout
                    key={label.Label}
                    start={<Label icon={<VideoCameraEmphasisIcon />} iconPosition="start" color="brand" content={label.FriendlyName} />}
                //start={<Button icon={<VideoCameraEmphasisIcon />} content={label.FriendlyName} iconPosition="before" tinted fluid />}
                />
            ),
            content: videosForLabelItems.length > 0 ? <Box styles={{ maxWidth: "400px" }}>
                <List selectable onSelectedIndexChange={(e, props) => getVideo(props?.selectedIndex ? props?.selectedIndex : 0)}
                    truncateHeader={true}
                    truncateContent={true}
                    defaultSelectedIndex={0}
                    items={videosForLabelItems} />
            </Box> :
                (labelVideosLoading ? <Loader size="smallest" label="Loading..." /> : <Label circular content="No video" />),
        })
    });


    function refreshVideoListSocial() {
        setUpdateNewsListSocial(!updateNewsListSocial)
    }

    return (
        !validUser ?
            <Flex fill hAlign="center" vAlign="center" column>
                <Text weight='bold' content='You need to be a subscriber of the Metro service from Retail Manager Solutions Ltd to use this application.' />
                <p>Please <a target='_blank' href='https://www.metrofy.com/contact-us'>contact us</a> at Retail Manager Solutions Ltd for more information or support.</p>
            </Flex>
            : isDesktopOrLaptop ?
                <>
                    <Banner />
                    <Flex
                        gap="gap.small"
                        padding="padding.medium"
                        style={{
                            height: "90%",
                        }}
                    >
                        <Flex.Item size="size.quarter">
                            <Segment content={<Accordion onActiveIndexChange={async (event, data) => { getVideosForLabel(data?.activeIndex ? data?.activeIndex : 0) }} panels={labelPanels} exclusive />} />
                        </Flex.Item>

                        <Flex.Item size="size.half" grow={true} >
                            <Segment styles={{ overflowY: 'auto' }} content={<><Video communicationId={selectedVideoId} largeVideoSize={true} refreshVideoListSocial={refreshVideoListSocial} /></>} />
                        </Flex.Item>
                    </Flex>
                </>
                : <><Banner />
                    <Flex column styles={{ height: '100%' }}>
                        <Flex.Item>
                            <Segment styles={{ height: '30%', overflowY: 'auto' }} content={<Accordion onActiveIndexChange={async (event, data) => { getVideosForLabel(data?.activeIndex ? data?.activeIndex : 0) }} panels={labelPanels} exclusive />} />
                        </Flex.Item>
                        <Flex.Item>
                            <Segment styles={{ marginBottom: '5em', overflowY: 'auto' }} content={<><Video communicationId={selectedVideoId} largeVideoSize={true} refreshVideoListSocial={refreshVideoListSocial} /></>} />
                        </Flex.Item>
                    </Flex></>
    )
}