import { useState, useEffect, useContext } from "react";
import { Dashboard } from "../../../interfaces/dashboard"
import { List, Header, Text } from "@fluentui/react-northstar"
import * as microsoftTeams from "@microsoft/teams-js";
import { SurveyAPI } from '../../../api/SurveyAPI'
import { TeamsFxContext } from "../../Context";
import { ErrorWithCode } from "@microsoft/teamsfx";

export default function SurveyResponses() {

    const [data, setData] = useState<Dashboard[]>();
    const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
    const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

    useEffect(() => {
        teamsUserCredential?.getUserInfo().then((userInfo) => {
            teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                SurveyAPI.allAnswers(tokenResponse!.token, userInfo.tenantId).then((data: any) => {
                    setData(data);
                })
            }).catch((err: ErrorWithCode) => {
                if (err.code === 'UiRequiredError') {
                    teamsUserCredential!.login(scope).then(() => {
                        teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                            SurveyAPI.allAnswers(tokenResponse!.token, userInfo.tenantId).then((data: any) => {
                                setData(data);
                            })
                        })
                    })
                }
            })
        })
    }, []);

    const openSurvey = (communicationId: number, title: string, responseId: number) => {
        let dialogSubmitHandler: microsoftTeams.dialog.DialogSubmitHandler = (result: any) => {
            console.log('closed task dialog')
        }
        let urlDialogInfo: microsoftTeams.UrlDialogInfo = {
            url: `${process.env.REACT_APP_URL}/index.html#/survey?communicationId=${communicationId}&responseId=${responseId}`,
            title: title,
            size: {
                height: 600,
                width: 1224
            }
        };
        microsoftTeams.dialog.url.open(urlDialogInfo, dialogSubmitHandler)
    }

    const items = data?.map(d => {
        return (
            {
                header: d.Title,
                content: `${new Date(d.DateCompleted).toLocaleDateString()} ${new Date(d.DateCompleted).toLocaleTimeString()}`,
                onClick: () => { openSurvey(d.CommunicationId, d.Title, d.ResponseId) }
            }
        )
    })

    return (
        <>
            <Header color="brand" as="h3" content="Survey Responses" styles={{ padding: 0 }} />
            {items && items.length > 0 ? <List selectable items={items} />
                : <Text content="You haven't completed any surveys." />}
        </>
    )
}