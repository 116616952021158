import { useState, useEffect, useContext } from "react";
import { Header, List, Status, Text } from "@fluentui/react-northstar"
import { KnowHow } from "../../../interfaces/faq"
import { FaqAPI } from "../../../api/FaqAPI";
import { ErrorWithCode } from "@microsoft/teamsfx";
import { TeamsFxContext } from "../../Context";

export default function FAQ(props: any) {

    const [knowHow, setKnowHow] = useState<KnowHow>();
    const params = new URLSearchParams(window.location.hash.substring(10))
    const knowHowId = params.get('knowHowId');
    const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
    const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

    const listItems = (responses?: any[]) => {
        return responses?.map(response => {
            return {
                key: response.KnowHowResponseId,
                header: response.CreatedBy,
                headerMedia: response.CreatedDate,
                content: response.TextBody
            }
        })
    }

    const FaqStatus = ({ status }: { status: number }) => {
        switch (status) {
            case 1: {
                return (<><Status state="success" title="Answered" /><Text weight="regular" size="medium" content=" Answered" /></>)
            }
            default:
                return (<><Status state="error" title="Awaiting Expert Response" /><Text weight="regular" size="medium" content=" Awaiting Expert Response" /></>)
        }
    }

    useEffect(() => {
        if (knowHowId) {
            teamsUserCredential?.getUserInfo().then((userInfo) => {
            teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                FaqAPI.get(knowHowId, tokenResponse!.token, userInfo.tenantId).then((data) => {
                    setKnowHow(data);
                });
            }).catch((err: ErrorWithCode) => {
                if (err.code === 'UiRequiredError') {
                    teamsUserCredential!.login(scope).then(() => {
                        teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                            FaqAPI.get(knowHowId, tokenResponse!.token, userInfo.tenantId).then((data) => {
                                setKnowHow(data);
                            });
                        })
                    })
                }
            })
        })
        }
    }, [knowHowId]);

    return (
        <div className="task page">
            <div className="narrow">
                <Header as="h3" content={knowHow?.Question} description={{
                    as: 'span',
                    content:
                        <FaqStatus status={knowHow?.Status!} />
                }} />
                <List items={listItems(knowHow?.KnowHowResponses)} />
            </div>
        </div>
    )
}