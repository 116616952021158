import { useState, useContext, useEffect } from "react";
import "./Reactions.css";
import { Reaction, ReactionToPost, ReactionCount } from '../../../interfaces/news'
import { Text, LikeIcon, ChatIcon } from "@fluentui/react-northstar"
import _ from 'lodash'
import { SocialAPI } from "../../../api/SocialAPI";
import { TeamsFxContext } from "../../Context";
import { ErrorWithCode } from "@microsoft/teamsfx";

const like = String.fromCodePoint(128077)
const dislike = String.fromCodePoint(128078)
const laugh = String.fromCodePoint(128514)
const insightful = String.fromCodePoint(129299)
const sympathy = String.fromCodePoint(128549)
const curious = String.fromCodePoint(129300)
const celebrate = String.fromCodePoint(129395)
const love = String.fromCodePoint(10084)

export function React(props: { reaction: ReactionToPost, refreshSocial: any }) {
    const [showReactions, setShow] = useState(false)
    const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
    const [token, setToken] = useState<string>();
    const [tenantId, setTenantId] = useState<string>();
    const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

    useEffect(() => {
        teamsUserCredential?.getUserInfo().then((userInfo) => {
            setTenantId(userInfo.tenantId);
            teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                setToken(tokenResponse!.token)
            }).catch((err: ErrorWithCode) => {
                if (err.code === 'UiRequiredError') {
                    teamsUserCredential!.login(scope).then(() => {
                        teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                            setToken(tokenResponse!.token)
                        })
                    })
                }
            })
        })
    }, [])


    async function addReaction(reactionType: string) {
        if (props.reaction.CommunicationId === undefined) { return }

        const reactionDTO: ReactionToPost = {
            ReactionId: props.reaction.ReactionId,
            CommunicationId: props.reaction.CommunicationId,
            CommentId: props.reaction.CommentId,
            ReactionType: reactionType
        }

        if (token && tenantId) {
            if (props.reaction.ReactionId > 0) {
                await SocialAPI.updateReaction(reactionDTO, token, tenantId)
                    .then(async (response) => {
                        console.log(response);
                        props.refreshSocial();
                    })
                    .catch((error) => {
                        console.error(error.response.status);
                    })
            }
            else {
                await SocialAPI.addReaction(reactionDTO, token, tenantId)
                    .then(async (response) => {
                        console.log(response);
                        props.refreshSocial();
                    })
                    .catch((error) => {
                        console.error(error.response.status);
                    })
            }
        }
    }

    async function removeReaction(reactionId: number) {
        if (reactionId === undefined) { return }
        if (token && tenantId) {
            await SocialAPI.removeReaction(reactionId, token, tenantId)
                .then(async (response) => {
                    console.log(response);
                    props.refreshSocial();
                })
                .catch((error) => {
                    console.error(error.response.status);
                })
        }
    }

    const react = <div className="reaction-btn">
        <span className="like-btn" onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
            {props.reaction.ReactionId > 0 ?
                <>
                    <span className="like-btn-emo fa-thumbs-o-up glyphicon glyphicon-thumbs-up" onClick={() => setShow(true)}>{GetReactionCode(props.reaction.ReactionType)}</span>
                    <span className="like-btn-text" onClick={() => removeReaction(props.reaction.ReactionId)}>{GetReactionText(props.reaction.ReactionType)}</span>
                </>
                :
                <>
                    <span className="like-btn-emo fa-thumbs-o-up glyphicon glyphicon-thumbs-up" onClick={() => setShow(true)}><LikeIcon bordered circular size="small" /></span>
                    <span className="like-btn-text">Like</span>
                </>
            }
            {showReactions ?
                <ul className={props.reaction.CommentId ? "reactions-box comment-box" : "reactions-box"}>
                    <li className="reaction reaction-like" onClick={() => addReaction("Like")}>👍</li>
                    <li className="reaction reaction-dislike" onClick={() => addReaction("Dislike")}>👎</li>
                    <li className="reaction reaction-love" onClick={() => addReaction("Love")}>❤</li>
                    <li className="reaction reaction-laugh" onClick={() => addReaction("Laugh")}>😂</li>
                    <li className="reaction reaction-insightful" onClick={() => addReaction("Insightful")}>🤓</li>
                    <li className="reaction reaction-sympathy" onClick={() => addReaction("Sympathy")}>😥</li>
                    <li className="reaction reaction-curious" onClick={() => addReaction("Curious")}>🤔</li>
                    <li className="reaction reaction-celebrate" onClick={() => addReaction("Celebrate")}>🥳</li>
                </ul>
                : null
            }
        </span>
    </div>;

    return (<><span style={{ margin: '0em 0.5em 0em 0em' }}>{react}</span></>
    )
}

export function Reactions(props: { reactions: Reaction[], commentsCount?: number }) {

    const reactionsByType = _.groupBy(props.reactions, r => r.ReactionType);
    let reactionList = []
    for (let key in reactionsByType) {
        let reactionGroup = reactionsByType[key];
        let reactionFriendlyNames = reactionGroup.sort((a, b) => +new Date(b.AuditDate) - +new Date(a.AuditDate)).map(a => a.UserDisplayName);
        let reactionFriendlyNamesText = reactionGroup.length + ' ' + reactionGroup[0].ReactionType + '\n' + reactionFriendlyNames.join('\n')
        reactionList.push({ reactionType: reactionGroup[0].ReactionType, count: reactionGroup.length, reactionFriendlyNamesText: reactionFriendlyNamesText })
    }

    const reactionsStats = reactionList.map(r => {
        if (r.reactionType.length > 0) {
            return (<span data-toggle="tooltip" data-placement="right" key={r.reactionType} title={r.reactionFriendlyNamesText}><Text content={GetReactionCode(r.reactionType)} /><Text size="small" styles={{ color: "#878787" }} content={r.count} /></span>)
        } else {
            return (<></>)
        }
    });

    const commentsStats = (props.commentsCount && props.commentsCount > 0) ?
        <span data-toggle="tooltip" data-placement="right" title={props.commentsCount + " comment(s)"}><ChatIcon outline /> <Text size="small" styles={{ color: "#878787" }} content={props.commentsCount} /> </span>
        : <></>


    return (<><span style={{ margin: '0em 0.5em 0em 0em' }}>{reactionsStats} {commentsStats}</span></>)
}

export function ReactionsSummary(props: { reactions: ReactionCount[], commentsCount?: number }) {

    let reactionList: { reactionType: string; count: number; reactionFriendlyNamesText: string; }[] = []

    props.reactions.forEach(reaction => {
        let reactionFriendlyNames = reaction.Hierarchies.map(h => h.FriendlyName);
        let reactionFriendlyNamesText = reactionFriendlyNames.length + ' ' + GetReactionText(reaction.ReactionType) + '\n' + reactionFriendlyNames.join('\n')
        reactionList.push({ reactionType: reaction.ReactionType, count: reaction.Count, reactionFriendlyNamesText: reactionFriendlyNamesText })
    });

    const reactionsStats = reactionList.map(r => {
        return (<span data-toggle="tooltip" data-placement="right" key={r.reactionType} title={r.reactionFriendlyNamesText}><Text content={GetReactionCode(r.reactionType)} /><Text size="small" styles={{ color: "#878787" }} content={r.count} /></span>)
    });

    const commentsStats = (props.commentsCount && props.commentsCount > 0) ?
        <span data-toggle="tooltip" data-placement="right" title={props.commentsCount + " comment(s)"}><ChatIcon outline /> <Text size="small" styles={{ color: "#878787" }} content={props.commentsCount} /> </span>
        : <></>

    return (<><span style={{ margin: '0em 0.5em 0em 0em' }}>{reactionsStats} {commentsStats}</span></>)
}

function GetReactionCode(reactionType: any) {
    switch (reactionType) {
        case 0: case "Like":
            return like
        case 1: case "Dislike":
            return dislike
        case 2: case "Laugh":
            return laugh
        case 3: case "Sympathy":
            return sympathy
        case 4: case "Celebrate":
            return celebrate
        case 5: case "Insightful":
            return insightful
        case 6: case "Love":
            return love
        case 7: case "Curious":
            return curious
        default:
            return ''
    }
}

function GetReactionText(reactionType: any) {
    switch (reactionType) {
        case 0: case "Like":
            return "Like"
        case 1: case "Dislike":
            return "Dislike"
        case 2: case "Laugh":
            return "Laugh"
        case 3: case "Sympathy":
            return "Sympathy"
        case 4: case "Celebrate":
            return "Celebrate"
        case 5: case "Insightful":
            return "Insightful"
        case 6: case "Love":
            return "Love"
        case 7: case "Curious":
            return "Curious"
        default:
            return ''
    }
}
