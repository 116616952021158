import { useState, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from "react";
import "./Survey.css";
import { Menu, Loader, Header, Text, Button, Flex, Label } from "@fluentui/react-northstar"
import { SurveyTab } from "./SurveyTab"
import { SpokeFormResponse } from "../../../interfaces/spokeFormResponse"
import {
    SpokeForm, SpokeFormTab,
} from "../../../interfaces/spokeForm"
import * as microsoftTeams from "@microsoft/teams-js";
import { SurveyAPI } from '../../../api/SurveyAPI'

const Survey = (props: {
    communicationId?: number,
    surveyForm?: SpokeForm,
    tabs: SpokeFormTab[],
    selectedTabItem: any,
    surveyResponse?: SpokeFormResponse,
    validate: boolean,
    valid: any,
    handleChange: any
}, ref: any) => {

    useImperativeHandle(
        ref,
        () => ({
            showAlert() {
                alert("Child Function Called")
                console.log('hello child world')
            }
        }),
    )

    const Description = () => {
        return (<p><Text weight="regular" size="medium" content={props.surveyForm?.Description} /></p>)
    }

    const Survey = useMemo(() => {

        return (
            <SurveyTab validate={props.validate}
                selectedTabId={props.selectedTabItem}
                surveyForm={props.surveyForm}
                surveyResponse={props.surveyResponse}
                handleChange={props.handleChange} 
                parent='task'/>
        )

    }, [props.validate, props.selectedTabItem, props.surveyResponse, props.surveyForm])

    return (
        <div className="survey page" style={{
            marginBottom: '3em'
        }}>
            <div className="narrow">
                <Header
                    as="h3"
                    content={props.surveyForm?.Name}
                    //description={<Description/>}
                    description={{
                        as: Description
                    }}
                />
                <Menu defaultActiveIndex={0} items={props.tabs} underlined secondary />
                {Survey}
            </div>
        </div>
    )
}

export default forwardRef(Survey)