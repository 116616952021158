import { api } from "./config"
import { SpokeFormResponse } from '../interfaces/spokeFormResponse'

const development = process.env.REACT_APP_DEVELOPER_ENDPOINT && process.env.REACT_APP_DEVELOPER_ENDPOINT !== '' 
          ? process.env.REACT_APP_DEVELOPER_ENDPOINT
          : ''

export const SurveyAPI = {
    survey: async function (communicationId:number, token:string, tenantId:string) {
        const response = await api.request({
            url: `survey/${communicationId}`,
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response.data
    },
    surveys: async function (type:string, token:string, tenantId:string) {
        const response = await api.request({
            url: `surveys?type=${type}`,
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response.data
    },
    answer: async function (communicationId:number, token:string, tenantId:string, surveyResponseId?:number) {
        let url = `/answer/${communicationId}`
        if (surveyResponseId) {
            url += `?surveyResponseId=${surveyResponseId}`
        }
        const response = await api.request({
            url: url,
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response.data
    },
    allAnswers: async function (token:string, tenantId:string) {
        const response = await api.request({
            url: `/survey/responses`,
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response.data
    },
    update: async function (communicationId:number, surveyResponse:SpokeFormResponse, token:string, tenantId:string) {
        const response = await api.request({
            url: `/answer/${communicationId}`,          
            method: 'POST', 
            data: JSON.stringify(surveyResponse),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response.data
    },
}