import {
    Flex,
    Text,
    Table,
    Image,
    RadioGroup,
    FormInput,
    FormField,
    FormDropdown,
    TableCellProps,
    FormLabel,
    FormCheckbox,
    Datepicker,
    Label
} from "@fluentui/react-northstar"
import { RadioGroupItemProps } from '../../../interfaces/radioGroupItemProps'
import { parse } from 'date-fns'
import { ExclamationCircleIcon } from '@fluentui/react-icons-northstar'
import { useState, useEffect, useCallback } from "react";
import "./Survey.css";
import { SpokeFormQuestion, SpokeFormQuestionValue, SpokeFormQuestionColumnValue, SpokeFormQuestionRowValue } from "../../../interfaces/spokeForm"
import { SpokeFormQuestionResponse, SpokeFormQuestionValueResponse } from "../../../interfaces/spokeFormResponse"

export function SurveyTextbox(props: {
    validate: boolean
    spokeFormQuestion: SpokeFormQuestion
    spokeFormQuestionResponse: SpokeFormQuestionResponse
    handleChange(event: any, data: any, validate: boolean, spokeFormQuestion?: SpokeFormQuestion): Function
}) {

    const [error, setError] = useState<string>();

    useEffect(() => {
        if (props.validate && props.spokeFormQuestion.Required
            && (!props.spokeFormQuestionResponse.Response || props.spokeFormQuestionResponse.Response === '')) {
            setError(' ')
        }
    }, [props.spokeFormQuestion.Required, props.spokeFormQuestionResponse.Response, props.validate])

    const handleLocalChange: any = (event: any, data: any) => {
        if (props.validate && props.spokeFormQuestion.Required && data.value === '') {
            setError(' ')
        } else {
            setError('')
        }
    }

    return (
        <FormInput
            defaultValue={props.spokeFormQuestionResponse.Response}
            onChange={(event, data) => {
                handleLocalChange(event, data)
                props.handleChange(event, data, props.validate, props.spokeFormQuestion)
            }
            }
            className="question"
            errorMessage={error}
            label={props.spokeFormQuestion.QuestionText}
            required={props.spokeFormQuestion.Required}
        />
    )
}

export function SurveyNumber(props: {
    validate: boolean
    spokeFormQuestion: SpokeFormQuestion
    spokeFormQuestionResponse: SpokeFormQuestionResponse
    handleChange(event: any, data: any, validate: boolean, spokeFormQuestion?: SpokeFormQuestion): Function
}) {

    const [error, setError] = useState<string>();

    useEffect(() => {
        if (props.validate && props.spokeFormQuestion.Required
            && (!props.spokeFormQuestionResponse.Response || props.spokeFormQuestionResponse.Response === '')) {
            setError(' ')
        }
    }, [props.spokeFormQuestion.Required, props.spokeFormQuestionResponse.Response, props.validate])

    const handleLocalChange: any = (event: any, data: any) => {
        if (props.validate && props.spokeFormQuestion.Required && data.value === '') {
            setError(' ')
        } else {
            setError('')
        }
    }

    return (
        <FormInput
            defaultValue={props.spokeFormQuestionResponse.Response}
            onChange={(event, data) => {
                handleLocalChange(event, data)
                props.handleChange(event, data, props.validate, props.spokeFormQuestion)
            }
            }
            type='number'
            className="question"
            errorMessage={error}
            label={props.spokeFormQuestion.QuestionText}
            required={props.spokeFormQuestion.Required} />
    )
}

export function SurveyTextArea(props: {
    validate: boolean
    spokeFormQuestion: SpokeFormQuestion
    spokeFormQuestionResponse: SpokeFormQuestionResponse
    handleChange(event: any, data: any, validate: boolean, spokeFormQuestion?: SpokeFormQuestion): Function
}) {

    const [error, setError] = useState<string>();

    useEffect(() => {
        if (props.validate && props.spokeFormQuestion.Required
            && (!props.spokeFormQuestionResponse.Response || props.spokeFormQuestionResponse.Response === '')) {
            setError(' ')
        }
    }, [props.spokeFormQuestion.Required, props.spokeFormQuestionResponse.Response, props.validate])

    const handleLocalChange: any = (event: any, data: any) => {
        if (props.validate && props.spokeFormQuestion.Required && data.value === '') {
            setError(' ')
        } else {
            setError('')
        }
    }

    return (
        <FormInput
            defaultValue={props.spokeFormQuestionResponse.Response}
            onChange={(event, data) => {
                handleLocalChange(event, data)
                props.handleChange(event, data, props.validate, props.spokeFormQuestion)
            }
            }
            className="question"
            fluid
            errorMessage={error}
            label={props.spokeFormQuestion.QuestionText}
            input={{
                resize: 'none',
                as: 'textarea',
                rows: props.spokeFormQuestion.Height,
            }}
        />
    )
}

export function SurveyCheckboxList(props: {
    validate: boolean
    spokeFormQuestion: SpokeFormQuestion
    spokeFormQuestionResponse: SpokeFormQuestionResponse
    handleChange(event: any, data: any,
        validate: boolean,
        spokeFormQuestion?: SpokeFormQuestion,
        spokeFormQuestionValue?: SpokeFormQuestionValue): Function
}) {

    const [selectedQuestionValueIds, setSelectedQuestionValueIds] = useState<number[]>([]);

    useEffect(() => {
        const existingAnswers = props.spokeFormQuestionResponse.SpokeFormQuestionValueResponses?.map((spokeFormQuestionValueResponse) => {
            return spokeFormQuestionValueResponse.SpokeFormQuestionValueId
        })
        if (existingAnswers) {
            setSelectedQuestionValueIds(existingAnswers);
        }
    }, [props.spokeFormQuestionResponse.SpokeFormQuestionValueResponses])

    const handleLocalChange: any = (event: any, data: any, spokeFormQuestionValue: SpokeFormQuestionValue) => {
        let _selectedQuestionValueIds = selectedQuestionValueIds;
        if (data.checked) {
            setSelectedQuestionValueIds(oldArray => [...oldArray, spokeFormQuestionValue.SpokeFormQuestionValueId]);
        } else {
            setSelectedQuestionValueIds(_selectedQuestionValueIds.filter(id => id !== spokeFormQuestionValue.SpokeFormQuestionValueId));
        }
    }

    const choices = props.spokeFormQuestion.SpokeFormQuestionValues.map((question: SpokeFormQuestionValue) => {
        const questionValueResponse = props.spokeFormQuestionResponse.SpokeFormQuestionValueResponses?.find(questionValueResponse => questionValueResponse.SpokeFormQuestionValueId === question.SpokeFormQuestionValueId) ?? { SpokeFormQuestionValueId: question.SpokeFormQuestionValueId }
        return {
            key: question,
            header: question.Text,
            checked: questionValueResponse.Response || selectedQuestionValueIds.includes(question.SpokeFormQuestionValueId)
        }
    })

    const CheckboxList = () => {
        return (
            <Flex className={
                props.validate && props.spokeFormQuestion.Required &&
                    selectedQuestionValueIds.length === 0 ? 'error' : ''} space="between" >
                <Flex column>
                    {
                        choices.map((choice: any) => {
                            return (<FormCheckbox key={choice.key.SpokeFormQuestionValueId}
                                label={choice.header}
                                defaultChecked={choice.checked}
                                onChange={(event, data) => {
                                    handleLocalChange(event, data, choice.key)
                                    props.handleChange(event, data, props.validate, props.spokeFormQuestion, choice.key)
                                }
                                } />)
                        })
                    }
                </Flex>
                {props.validate && props.spokeFormQuestion.Required
                    && selectedQuestionValueIds.length === 0 ? <Flex.Item align='center'>
                    <ExclamationCircleIcon styles={{ color: 'rgb(196, 49, 75)', padding: '8px' }} />
                </Flex.Item>
                    : <></>}
            </Flex>
        )
    }

    return (
        <div className="question" style={{ display: 'block' }}>
            <FormLabel content={props.spokeFormQuestion.QuestionText} />
            <CheckboxList />
        </div>
    )
}

export function SurveyCheckbox(props: {
    spokeFormQuestion: SpokeFormQuestion
    spokeFormQuestionResponse: SpokeFormQuestionResponse
    handleChange(event: any, data: any,
        spokeFormQuestion?: SpokeFormQuestion,
        spokeFormQuestionValue?: SpokeFormQuestionValue): Function
}) {
    return (
        <FormCheckbox className="question"
            label={props.spokeFormQuestion.QuestionText}
            onChange={(event, data) => {
                props.handleChange(event, data, props.spokeFormQuestion)
            }
            }
            defaultChecked={props.spokeFormQuestionResponse.Response} />
    )
}

export function SurveyDatePicker(props: {
    validate: boolean
    spokeFormQuestion: SpokeFormQuestion
    spokeFormQuestionResponse: SpokeFormQuestionResponse
    handleChange(event: any, data: any,
        validate: boolean,
        spokeFormQuesiton?: SpokeFormQuestion): Function
}) {
    const [selectedDate, setSelectedDate] = useState(
        props.spokeFormQuestionResponse.Response
            ? parse(props.spokeFormQuestionResponse.Response, 'yyyy-MM-dd', new Date())
            : undefined
    );

    const handleLocalChange: any = (event: any, data: any) => {
        setSelectedDate(data.value)
    }

    return (
        <>
            <Label content={props.spokeFormQuestion.QuestionText} styles={{
                marginTop: '1em',
                color: 'rgb(37, 36, 35)',
                paddingLeft: '0px',
                backgroundColor: 'transparent'
            }} />
            <Datepicker
                className={
                    props.validate && props.spokeFormQuestion.Required &&
                        (selectedDate === undefined || isNaN(selectedDate as any)) ? 'error' : ''}
                onDateChange={(event, data) => {
                    handleLocalChange(event, data)
                    props.handleChange(event, data, props.validate, props.spokeFormQuestion)
                }}
                defaultSelectedDate={selectedDate} />
        </>
    )
}

export function SurveyDropdown(props: {
    validate: boolean
    spokeFormQuestion: SpokeFormQuestion
    spokeFormQuestionResponse: SpokeFormQuestionResponse
    handleChange(event: any, data: any, validate: boolean, spokeFormQuestion?: SpokeFormQuestion): Function
}) {

    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {
        if (props.validate && props.spokeFormQuestion.Required &&
            (props.spokeFormQuestionResponse.Response === '' || props.spokeFormQuestionResponse.Response === undefined)) {
            setErrorMessage(' ')
        }
    }, [props.spokeFormQuestion.Required, props.spokeFormQuestionResponse.Response, props.validate])

    const handleLocalChange: any = (event: any, data: any) => {
        if (props.validate && props.spokeFormQuestion.Required && data.value === '') {
            console.log('Dropdown handleLocalChange error')
            setErrorMessage(' ')
        } else {
            console.log('Dropdown handleLocalChange NO error')
            setErrorMessage('')
        }
    }

    const defaultValue = () => {
        if (props.spokeFormQuestionResponse.SpokeFormQuestionValueResponses
            && props.spokeFormQuestionResponse.SpokeFormQuestionValueResponses.length > 0
            && props.spokeFormQuestionResponse.SpokeFormQuestionValueResponses[0].Response) {
            const response = props.spokeFormQuestionResponse.SpokeFormQuestionValueResponses[0].Response
            const defaultResponse = props.spokeFormQuestion.SpokeFormQuestionValues.find(qv => qv.SpokeFormQuestionValueId.toString() === response)
            if (defaultResponse) {
                return {
                    key: defaultResponse.SpokeFormQuestionValueId,
                    header: defaultResponse.Text
                }
            }
        }
    }

    const choices = props.spokeFormQuestion.SpokeFormQuestionValues.map((question: SpokeFormQuestionValue) => {
        return {
            key: question.SpokeFormQuestionValueId,
            header: question.Text
        }
    })
    return (
        <FormDropdown className="question"
            label={{
                content: props.spokeFormQuestion.QuestionText
            }}
            items={choices}
            defaultValue={defaultValue}
            errorMessage={errorMessage}
            onChange={(event, data) => {
                handleLocalChange(event, data)
                props.handleChange(event, data, props.validate, props.spokeFormQuestion)
            }
            }
        />
    )
}

export function SurveyRadioGroup(props: {
    validate: boolean
    spokeFormQuestion: SpokeFormQuestion
    spokeFormQuestionResponse: SpokeFormQuestionResponse
    handleChange(event: any, data: any,
        validate: boolean,
        spokeFormQuestion?: SpokeFormQuestion,
        spokeFormQuestionValue?: SpokeFormQuestionValue): Function
}) {

    const [selectedQuestionValueId, setSelectedQuestionValueId] = useState<number>();

    const choices = props.spokeFormQuestion.SpokeFormQuestionValues.map((question: SpokeFormQuestionValue) => {
        return {
            key: question.SpokeFormQuestionValueId,
            label: question.Text,
            value: question.SpokeFormQuestionValueId.toString()
        }
    })

    const checkedValue = props.spokeFormQuestionResponse.SpokeFormQuestionValueResponses === undefined || props.spokeFormQuestionResponse?.SpokeFormQuestionValueResponses?.length === 0
        ? undefined
        : props.spokeFormQuestionResponse.SpokeFormQuestionValueResponses![0].Response

    const handleLocalChange: any = (event: any, data: any, spokeFormQuestionValue: SpokeFormQuestionValue) => {
        console.log('RadioGroup handleLocalChange ', data.value)
        setSelectedQuestionValueId(data.value);
    }

    return (
        <>
            <Label content={props.spokeFormQuestion.QuestionText} styles={{
                marginTop: '1em',
                color: 'rgb(37, 36, 35)',
                paddingLeft: '0px',
                backgroundColor: 'transparent'
            }} />
            <Flex className={
                props.validate && props.spokeFormQuestion.Required
                && selectedQuestionValueId === undefined ? 'error' : ''} space="between" >
                <Flex column>
                    <RadioGroup
                        vertical items={choices}
                        defaultCheckedValue={checkedValue}
                        onCheckedValueChange={(event, data) => {
                            handleLocalChange(event, data)
                            props.handleChange(event, data, props.validate, props.spokeFormQuestion)
                        }
                        } />
                </Flex>
                {props.validate && props.spokeFormQuestion.Required
                    && selectedQuestionValueId === undefined ? <Flex.Item align='center'>
                    <ExclamationCircleIcon styles={{ color: 'rgb(196, 49, 75)', padding: '8px' }} />
                </Flex.Item>
                    : <></>}
            </Flex>
        </>
    )
}

export function SurveyMatrix(props: {
    validate: boolean
    spokeFormQuestion: SpokeFormQuestion
    spokeFormQuestionResponse: SpokeFormQuestionResponse
    handleChange(event: any, data: any,
        validate: boolean,
        spokeFormQuestion?: SpokeFormQuestion,
        spokeFormQuestionValue?: SpokeFormQuestionValue,
        spokeFormQuestionColumnValue?: SpokeFormQuestionColumnValue,
        spokeFormQuestionRowValue?: SpokeFormQuestionRowValue): Function
}) {

    const handleChange = props.handleChange
    const [radioGroupItems, setRadioGroupItems] = useState<RadioGroupItemProps[]>([]);
    const [rows, setRows] = useState<any>();

    let headers = props.spokeFormQuestion.SpokeFormQuestionColumnValues.map((column: SpokeFormQuestionColumnValue) => {
        return column.Text
    })
    headers = ['', ...headers]

    const radioItem = (column: SpokeFormQuestionColumnValue, row: SpokeFormQuestionRowValue, checkedValue?: string) => {
        return {
            value: column.SpokeFormQuestionValueId + ',' + row.SpokeFormQuestionValueId,
            label: '',
            key: {
                spokeFormQuestionColumnValue: column,
                spokeFormQuestionRowValue: row
            },
            checkedValue: checkedValue // ''
        }
    }

    const radioGroupContent = useCallback((item: RadioGroupItemProps, radioGroupItems: any) => {
        console.log('radioGroupItemProps ', item)
        const checked = radioGroupItems.find((rgi: any) => rgi.checkedValue === item.value)?.value
        return (<Flex vAlign="center" hAlign="center">
            <RadioGroup items={Array(1).fill(item)}
                checkedValue={checked ? checked : ''}
                onCheckedValueChange={(event, data) => {
                    console.log('onCheckedValueChange fired!')
                    const colRow = item.value!.toString().split(',')
                    const _radioGroupItems: any = radioGroupItems.map((x: any) => {
                        if (x.key.spokeFormQuestionColumnValue.SpokeFormQuestionValueId.toString() !== colRow[0] && x.key.spokeFormQuestionRowValue.SpokeFormQuestionValueId.toString() === colRow[1]) {
                            x.checkedValue = ''
                            console.log('uncheck')
                        } else if (colRow[0] && x.key.spokeFormQuestionRowValue.SpokeFormQuestionValueId.toString() === colRow[1]) {
                            x.checkedValue = x.value!.toString()
                            console.log('check ', x.value)
                        }
                        return x;
                    })
                    console.log('local _radioGroupItems ', _radioGroupItems)
                    handleChange(event, data,
                        props.validate,
                        props.spokeFormQuestion,
                        undefined,
                        item.key.spokeFormQuestionColumnValue,
                        item.key.spokeFormQuestionRowValue)
                    setRadioGroupItems(_radioGroupItems)
                    loadRows(_radioGroupItems)
                }
                } />
        </Flex>)
    }, [props.spokeFormQuestion, props.validate])

    const loadRows = useCallback((radioButtons) => {
        console.log('loadRows ', radioButtons)
        return props.spokeFormQuestion.SpokeFormQuestionRowValues.map((row: SpokeFormQuestionRowValue) => {
            let valid: boolean = false;
            let cells: TableCellProps[] = props.spokeFormQuestion.SpokeFormQuestionColumnValues.map((column: SpokeFormQuestionColumnValue) => {
                let item = radioButtons.find((d: any) => d.value === column.SpokeFormQuestionValueId + ',' + row.SpokeFormQuestionValueId);
                const questionValueResponse = props.spokeFormQuestionResponse.SpokeFormQuestionValueResponses?.find(questionValueResponse => questionValueResponse.SpokeFormQuestionValueIdColumn === column.SpokeFormQuestionValueId && questionValueResponse.SpokeFormQuestionValueIdRow === row.SpokeFormQuestionValueId)
                if (!valid) {
                    valid = questionValueResponse || item?.checkedValue !== undefined ? true : false
                }
                if (props.spokeFormQuestion.DisplayRadio && item) {
                    return ({
                        key: column.SpokeFormQuestionValueId,
                        content: radioGroupContent(item, radioButtons)
                    })
                } else {
                    return ({
                        key: column.SpokeFormQuestionValueId,
                        content: <SurveyMatrixTextbox spokeFormQuestion={props.spokeFormQuestion}
                            spokeFormQuestionColumnValue={column}
                            spokeFormQuestionRowValue={row}
                            validate={props.validate}
                            spokeFormQuestionValueResponse={questionValueResponse}
                            handleChange={handleChange} />
                    })
                }
            })
            const _cell: TableCellProps = { content: (<Flex vAlign="center" hAlign="center"><Text content={row.Text} /></Flex>) }
            cells = [_cell, ...cells]
            console.log('rowValid ', valid)
            const rowValid: boolean = props.spokeFormQuestion.DisplayRadio && props.validate && props.spokeFormQuestion.Required && !valid
            return { items: cells, className: `${rowValid ? 'errorrow' : ''}` }
        })
    }, [radioGroupContent, props.spokeFormQuestion, props.spokeFormQuestionResponse.SpokeFormQuestionValueResponses, props.validate]);


    useEffect(() => {
        console.log('radioGroupItems have changed loadRows')
        setRows(loadRows(radioGroupItems))
    }, [radioGroupItems])

    useEffect(() => {
        console.log('setup radio buttons!')
        let radioButtons: any = [];
        props.spokeFormQuestion.SpokeFormQuestionRowValues.forEach((row: any) => {
            props.spokeFormQuestion.SpokeFormQuestionColumnValues.forEach((column: any) => {
                const questionValueResponse = props.spokeFormQuestionResponse.SpokeFormQuestionValueResponses?.find(questionValueResponse => questionValueResponse.SpokeFormQuestionValueIdColumn === column.SpokeFormQuestionValueId && questionValueResponse.SpokeFormQuestionValueIdRow === row.SpokeFormQuestionValueId)
                radioButtons.push(radioItem(column, row, questionValueResponse?.Response))
            })
        });
        //setRadioGroupItems(radioButtons)    
        setRows(loadRows(radioButtons))
    }, [props.spokeFormQuestion.SpokeFormQuestionColumnValues, props.spokeFormQuestion.SpokeFormQuestionRowValues, props.spokeFormQuestionResponse.SpokeFormQuestionValueResponses])

    return (
        <>
            <FormField className='question' style={{ width: '100%' }}>
                <FormLabel>{props.spokeFormQuestion.QuestionText}</FormLabel>
                <Table header={headers} rows={rows} />
            </FormField>
        </>
    )
}

export function SurveyMatrixTextbox(props: {
    spokeFormQuestion: SpokeFormQuestion
    spokeFormQuestionColumnValue: SpokeFormQuestionColumnValue,
    spokeFormQuestionRowValue: SpokeFormQuestionRowValue,
    validate: boolean,
    spokeFormQuestionValueResponse?: SpokeFormQuestionValueResponse
    handleChange(event: any, data: any,
        validate: boolean,
        spokeFormQuestion?: SpokeFormQuestion,
        spokeFormQuestionValue?: SpokeFormQuestionValue,
        spokeFormQuestionColumnValue?: SpokeFormQuestionColumnValue,
        spokeFormQuestionRowValue?: SpokeFormQuestionRowValue): Function
}) {

    const [error, setError] = useState<string>();

    useEffect(() => {
        if (props.validate && props.spokeFormQuestion.Required
            && (!props?.spokeFormQuestionValueResponse?.Response || props.spokeFormQuestionValueResponse.Response === '')) {
            setError(' ')
        }
    }, [props.spokeFormQuestion.Required, props.spokeFormQuestionValueResponse?.Response, props.validate])

    const handleLocalChange: any = (event: any, data: any) => {
        if (props.validate && props.spokeFormQuestion.Required && data.value === '') {
            setError(' ')
        } else {
            setError('')
        }
    }

    return (
        <FormInput
            fluid
            defaultValue={props.spokeFormQuestionValueResponse?.Response}
            onChange={(event, data) => {
                handleLocalChange(event, data)
                props.handleChange(event, data,
                    props.validate,
                    props.spokeFormQuestion,
                    undefined,
                    props.spokeFormQuestionColumnValue,
                    props.spokeFormQuestionRowValue)
            }
            }
            errorMessage={error}
            required={props.spokeFormQuestion.Required}
        />
    )
}

export function SurveyImage(props: {
    spokeFormQuestion: SpokeFormQuestion
}) {
    return (
        <>
            <FormField className='question' style={{ width: '100%', maxWidth: '650px' }}>
                <FormLabel>{props.spokeFormQuestion.QuestionText}</FormLabel>
                <Image fluid src={props.spokeFormQuestion.FileUrl} />
            </FormField>
        </>
    )
}