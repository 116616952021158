import { useState } from "react";
import { Image, Menu } from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import "./Welcome.css";
import { EditCode } from "./EditCode";
import { AzureFunctions } from "./AzureFunctions";
import { Graph } from "./Graph";
import { CurrentUser } from "./CurrentUser";
import { useTeamsFx } from "./lib/useTeamsFx";
import { TeamsFx } from "@microsoft/teamsfx";
import { useData } from "./lib/useData";
import { Deploy } from "./Deploy";
import { Publish } from "./Publish";
import EnvironmentVariables from "../unifiedcomms/EnvironmentVariables"

export function Welcome(props: { showFunction?: boolean; environment?: string }) {
  const { showFunction, environment } = {
    showFunction: true,
    environment: window.location.hostname === "localhost" ? "local" : "azure",
    ...props,
  };
  const friendlyEnvironmentName =
    {
      local: "local environment",
      azure: "Azure environment",
    }[environment] || "local environment";

  const steps = ["local", "azure", "publish"];
  const friendlyStepsName: { [key: string]: string } = {
    local: "1. Build your app locally",
    azure: "2. Provision and Deploy to the Cloud",
    publish: "3. Publish to Teams",
  };
  const [selectedMenuItem, setSelectedMenuItem] = useState("local");
  const items = steps.map((step) => {
    return {
      key: step,
      content: friendlyStepsName[step] || "",
      onClick: () => setSelectedMenuItem(step),
    };
  });

  const { inTeams } = useTeamsFx();
  const userProfile = useData(async () => {
    const teamsfx = new TeamsFx();
    return inTeams ? await teamsfx.getUserInfo() : undefined;
  })?.data;
  const userName = userProfile ? userProfile.displayName : "";
  const hubName = useData(async () => {
    await microsoftTeams.app.initialize();
    const context = await microsoftTeams.app.getContext();
    return context.app.host.name;
  })?.data;
  const token = useData(async () => {
    const teamsfx = new TeamsFx();
    const credential = teamsfx.getCredential();
    const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];
    const token = await credential.getToken(scope);
    return token?.token;
  })
  return (
    <div className="welcome page">
      <div className="narrow page-padding">
        <Image src="hello.png" />
        <h1 className="center">Congratulations{userName ? ", " + userName : ""}!</h1>
        {hubName && (
          <p className="center">Your app is running in {hubName}</p>
        )}
        <p className="center">Your app is running in your {friendlyEnvironmentName}</p>
        <Menu defaultActiveIndex={0} items={items} underlined secondary />
        <div className="sections">
          {selectedMenuItem === "local" && (
            <div>
              <EditCode showFunction={showFunction} />
              {inTeams && <CurrentUser userName={userName} />}
              <Graph />
              {showFunction && <AzureFunctions />}
            </div>
          )}
          {selectedMenuItem === "azure" && (
            <div>
              <Deploy />
            </div>
          )}
          {selectedMenuItem === "publish" && (
            <div>
              <Publish />
            </div>
          )}
        </div>
        <EnvironmentVariables teamsToken={token.data}/>
      </div>
    </div>
  );
}
