import { useState, useEffect, useContext, useCallback } from "react";
import { Divider, Loader, Text } from "@fluentui/react-northstar"
import { INews } from '../../../interfaces/news'
import { SocialSection } from './../social/SocialSection'
import { Reactions } from './../social/Reactions'
import { NewsAPI } from '../../../api/NewsAPI'
import { VideosAPI } from '../../../api/VideosAPI'
import { useMediaQuery } from "react-responsive"
import ReactPlayer from 'react-player'
import { TeamsFxContext } from "../../Context";
import { ErrorWithCode } from "@microsoft/teamsfx";
import { osName } from 'react-device-detect';

export default function Video(props: { communicationId?: number, largeVideoSize?: boolean, refreshVideoListSocial?: any, setShowVideoLoader?: any }) {
  const [video, setVideo] = useState<INews>();
  const [showVideoLoader, setShowVideoLoader] = useState<boolean>(false);
  const [updateSocial, setUpdateSocial] = useState<boolean>(false);
  const [thumbnail, setThumbnail] = useState<string>();
  const [source, setSource] = useState<string>();
  const [height, setHeight] = useState<string>('200px');
  const [token, setToken] = useState<string>();
  const [tenantId, setTenantId] = useState<string>();
  const [width, setWidth] = useState<string>('320px');
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
  const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

  const setVideoProps = (video: INews) => {

    let content = video?.Content?.find(x => x.ContentType === 'VideoOriginal')
    setThumbnail(encodeURIComponent(video.ThumbnailUrl))

    if (content?.FileName) {
      setSource(encodeURIComponent(content.FileName))
    }

    if (props.largeVideoSize) {
      setHeight('477px')
      setWidth('100%')
    }
  }

  const getVideo = (communicationId: number, token: string, tenantId: string) => {
    if (communicationId && token) {
      NewsAPI.get(communicationId, token!, tenantId)
        .then((response) => {
          let video: INews = response;
          setVideo(video)
          setVideoProps(video)
          setShowVideoLoader(false)
        })
    }
  }

  // useEffect(() => {
  //   if (token && tenantId) {
  //     load(token, tenantId)
  //   }
  // }, [video])

  useEffect(() => {
    teamsUserCredential?.getUserInfo().then((userInfo) => {
      setTenantId(userInfo.tenantId);
      teamsUserCredential!.getToken(scope).then((tokenResponse) => {
        setToken(tokenResponse!.token)
        if(tokenResponse){
          load(tokenResponse.token, userInfo.tenantId)
        }
      }).catch((err: ErrorWithCode) => {
        console.log('Video get token error: ', err.code, err.message)
        if (err.code === 'UiRequiredError') {
          console.log('prompt user to login', process.env.REACT_APP_IDENTIFIER_URI)
          teamsUserCredential!.login(scope).then(() => {
            teamsUserCredential!.getToken(scope).then((tokenResponse) => {
              setToken(tokenResponse!.token)
              if(tokenResponse){
                load(tokenResponse.token, userInfo.tenantId)
              }
            })
          })
        }
      })
    })
  }, []);

  const load = (token: string, tenantId: string) => {
    console.log("Video load")
    setShowVideoLoader(true)
    VideosAPI.all(token, tenantId)
      .then(async (response) => {
        const videos: INews[] = response;
        getVideo(videos[0].CommunicationId, token, tenantId)
      })
  }

  useEffect(() => {
    setShowVideoLoader(true)
    if(token && tenantId){
      getVideo(props.communicationId!, token, tenantId)
    } else {
      console.log('Invalid token or tentandId')
    }
  }, [props.communicationId, token]);

  function refreshSocial() {
    if(token && tenantId){
      getVideo(props.communicationId!, token, tenantId)
    } else {
      console.log('Invalid token or tentandId')
    }
    // setUpdateSocial(!updateSocial)
    // if (props.refreshVideoListSocial) {
    //    props.refreshVideoListSocial(props.communicationId!)
    // }
  }

  const wrapper = {
    //margin: 'auto',
    height: '100%',
    width: '100%',
    maxWidth: '877.5px',
    maxHeight: height,
    //marginBottom: '20px'
  };

  return (
    showVideoLoader ?
      <Loader size="largest" styles={wrapper} />
      :
      <>
        <div style={wrapper}>
          {osName !== 'iOS' ?
            <ReactPlayer onError={(error) => console.log('ReactPlayer error ', error)} controls config={{ file: { forceHLS: true } }} url={`${decodeURIComponent(source!)}`} width="100%" height="100%" />
            : <ReactPlayer onError={(error) => console.log('ReactPlayer error ', error)} controls config={{ file: { forceHLS: false } }} url={`${decodeURIComponent(source!)}`} width="100%" height="100%" />}
        </div>
        {video?.Social?.Reactions && video?.SocialEnabled ? <p><Reactions reactions={video?.Social.Reactions} commentsCount={video?.SocialSummary?.CommentsCount} /></p> : null}
        {
          props.largeVideoSize ?
            (video?.Social && video?.SocialEnabled ?
              <>
                <Divider color="brand" content="Discussion" />
                <div style={{ height: '100%', marginBottom: '7.5em' }}>
                  <SocialSection social={video?.Social} refreshSocial={refreshSocial} />
                </div>
              </>
              : null)
            : null
        }
      </>

  )
}