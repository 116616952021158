import React from "react";
import "./TermsOfUse.css";
/**
 * This component is used to display the required
 * terms of use statement which can be found in a
 * link in the about tab.
 */
export default function TermsOfUse() {

  return (
    <div className="termsofuse page" style={{
      marginBottom: '3em',
      height: '100%',
      overflowY: 'auto'
    }}>
      <div className="narrow">
        <div>
          <h1>Terms of Use</h1>
          <p>The application (“the App”) is intended for personal use in relation to software authorised for you to use by your employer. The App is licensed for use by Retail Manager Solutions Limited (a company registered in England under registration number 6819750 whose registered office is Castle Malwood, Minstead, Lyndhurst, Hants. SO43 7PE) and to you by your employer (together or individually "we" or "us"). This Agreement describes the terms and conditions applicable to the services available to Users through the App.</p>
          <p>Before using any of the services through the App, please read all of this agreement carefully. By accessing any areas of the App users ("Users" or "you") agree to be legally bound and to abide by these terms and conditions, which will constitute our agreement ("Agreement").</p>
          <p>This Agreement describes your responsibilities and, among other things, limits our liability. If at any time you do not agree with any part of this Agreement, you must discontinue use of this App. We reserve the right, in our sole discretion, to amend, modify, or alter this Agreement at any time by posting the amended terms on the App. We recommend that you review these terms and conditions periodically. The amended terms shall be effective from the date that they are posted on the App. </p>
          <h2 style={{ textAlign: 'center' }}>GENERAL TERMS AND CONDITIONS</h2>
          <ol>
            <li style={{ fontWeight: 'bolder' }}>Definitions</li>
            <p>Unless it is obvious from the context that a different meaning is meant, the following definitions apply to capitalised terms in this Agreement:</p>

            <p>“Content” means all content included on this App, including, without limitation, the information, data, software, images, illustrations, maps, designs, icons, written and other material;</p>

            <p>“Covered Parties” means Retail Manager Solutions Limited and your employer, including their respective officers, directors, employees, representatives, subsidiaries, affiliates, distributors, licensors and agents;</p>

            <li style={{ fontWeight: 'bolder' }}>License</li>
            <p>We grant to you a limited, personal, non-transferable, non-sublicensable, revocable license to access, download and use this App only as expressly permitted in this Agreement. Except for this limited license, we do not grant you any other rights or license with respect to this App; any rights or license not expressly granted in this Agreement are reserved. The Content and information on this App as well as the infrastructure used to provide such Content and information, is proprietary to your employer. Accordingly, as a condition of using this App, you agree not to use this App or its contents or information for any for any purpose that is unlawful or prohibited by this Agreement. You agree not to modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or re-sell any information, software, products, or services obtained from this App. </p>
            <li style={{ fontWeight: 'bolder' }}>Acceptable Use</li>
            <p>Your use of this App is restricted to personal and legitimate purposes in relation to use authorised by your employer on this App. Without limiting the generality of this statement, in addition, whether or not you have a commercial purpose, you agree not to:</p>
            <ol type="i">
              <li>access, monitor or copy any content or information of this App using any device or automated means of any description or any manual process for any purpose other than as is expressly permitted under this Agreement; </li>
              <li>breach any of the restrictions applying to use of this App or bypass or circumvent other measures employed to prevent or limit access to this App;</li>
              <li>take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on your employer's infrastructure;</li>
              <li>deliver any unlawful (according to national or international law or regulation) postings to or through the this App, or any postings which advocate illegal activity; </li>
              <li>deliver, or provide links to, any postings containing material that could be considered harmful, obscene, pornographic, indecent, lewd, violent, abusive, profane, insulting, threatening, tortuous, harassing, hateful or otherwise objectionable;</li>
              <li>deliver or provide links to, any postings containing material that harasses, victimises, degrades, or intimidates an individual or group of individuals on the basis of religion, race, ethnicity, sexual orientation, gender, age, or disability;</li>
              <li>deliver or provide links to, any postings containing defamatory, false or libellous material;</li>
              <li>deliver any posting that infringes or is in breach of any intellectual property or other right of any entity or person, including, without limitation, copyrights, patents, trademarks, laws governing trade secrets, rights to privacy, or publicity; </li>
              <li>deliver any posting to that you do not have a right to make available under law or contractual or fiduciary relationships; </li>
              <li>manipulate identifiers, including, without limitation, by forging headers, in order to disguise the origin of any posting that you deliver;</li>
              <li>use this App in any manner which could damage, disable, overburden, or impair or otherwise interfere with the use of this App or other Users' computers or/and phone equipment, or cause damage, disruption or limit the functioning of any software, hardware, or telecommunications equipment;</li>
              <li>attempt to gain unauthorised access to this App, computer system, or networks connected to this App, through hacking, password mining, or any other means; or</li>
              <li>obtain or attempt to obtain any materials or information through any means not intentionally made available through this App, including harvesting or otherwise collecting information about others including, without limitation, email addresses. </li>
            </ol>
            <p>Without limitation of any other provisions of this Agreement, you may not use this App for any purpose that is unlawful or prohibited by this Agreement and/or any applicable additional terms. Your access to this App may be terminated immediately in our sole discretion, with or without notice, if you fail to comply with any provision of this Agreement and/or additional terms, or for any other reason.</p>

            <li style={{ fontWeight: 'bolder' }}>Use of this App</li>
            <p>You may only use this App in connection with your employment and shall not use this App to make any false or fraudulent entries. You represent that you are of sufficient legal age (that is to say, at least 18 years old) i) to create binding legal obligations for any liability you may incur as a result of your use of this App and ii) use any service through this App. You agree to provide correct and true information in connection with your use of this App and you agree to promptly update your account information (if applicable) in order to keep it current, complete and accurate. It is forbidden and unlawful to place a entry in a false name. Please be aware that even if you do not give us your real name, your phone transmits a unique device identification number to us that can be used by law enforcement officials to identify you. Fraudulent users will be prosecuted to the fullest extent of the law.</p>
            <li style={{ fontWeight: 'bolder' }}>Intellectual Property Rights; Copyright Notice</li>

            <p>You, the User, acknowledge that the Content and the arrangement and compilation of the Content are our intellectual property and copyrighted works. Reproduction or storage of information or works retrieved from this App, in all forms, media and technologies now existing or hereafter developed, is not permitted under this Agreement.</p>

            <li style={{ fontWeight: 'bolder' }}>Trademark Notice</li>

            <p>"Metro" is the trademark of Retail Manager Solutions Limited (a company registered in England under registration number 6819750). Other product and company names identified on this App may be the name, trademark, trade name, service mark, logo, symbol or other proprietary designation of your employer or a third party.</p>

            <li style={{ fontWeight: 'bolder' }}>Accounts, Security, Passwords</li>

            <p>In order to use this App you must apply for an Account by registering and completing the App registration and activation process and providing us with current, complete, and accurate information as requested by the online or in App registration form. By filling out and submitting the form you are creating a User account that can be accessible by you in the future. It is your responsibility to maintain the currency, completeness and accuracy of your registration Account data, and any loss caused by your failure to do so is your responsibility. As part of the registration process, you will be asked to choose a security password. It is your responsibility to maintain the confidentiality of your security password and your Account details. You agree to notify us immediately of any unauthorised use of your Account. We are not liable for any loss that you may incur as a result of someone else using your Account, either with or without your knowledge.</p>

            <li style={{ fontWeight: 'bolder' }}>Privacy Policy</li>

            <p>You confirm that you have read your employer's Privacy Policy and agree that the terms of such policy are reasonable and you accept them. You consent to the use of your personal information by us in accordance with the terms of and for the purposes set forth in the Privacy Policy. To the extent permitted by law, we make no representation or warranty with regard to the sufficiency of the security measures used for data handling and storage. We will not be responsible for any actual or consequential damages that result from a lapse in compliance with the Privacy Policy because of a security breach or technical malfunction.</p>

            <li style={{ fontWeight: 'bolder' }}>Disclaimers and Warranties</li>

            <p>All Content, including, without limitation, software, products, services, information, text and related graphics contained within or available through this App are provided to you on an "as is" and "as available" basis.</p>
            <p>We make no representations or warranties of any kind, either express or implied, as to the operation of this App or the information, Content or materials included on this App. To the fullest extent permissible, we disclaim all representations and warranties of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability or fitness for purpose, informational content, title, or non-infringement of the rights of third parties.</p>
            <p>We do not warrant or make any representations that this App will operate error-free or uninterrupted, that defects will be corrected, or that this App and/or its servers will be free of viruses and/or other harmful components. We do not warrant or make any representations regarding suitability, availability, accuracy, reliability, completeness, or timeliness of any material of any kind contained within this App for any purpose, including software, products, services, information, text and related graphics content.</p>
            <p>We are not responsible for any failures caused by server errors, misdirected or redirected transmissions, failed internet or phone connections, interruptions in the transmission or receipt of orders or, any computer virus or other technical defect, whether human or technical in nature.</p>
            <li style={{ fontWeight: 'bolder' }}>Limitation of Liability</li>

            <p>To the extent permitted by law, in no event shall the Covered Parties be liable to any person or entity whatsoever for any direct, indirect, incidental, special, exemplary, compensatory, consequential, or punitive damages or any damages whatsoever, including, without limitation:</p>
            <ol type='i'>
              <li>loss of goodwill, profits or anticipated profits, savings or anticipated savings, business interruption, data or other intangible losses; </li>
              <li>your inability to use, or unauthorised use of, performance or non-performance of the App;</li>
              <li>unauthorised access to or tampering with your personal information or transmissions; </li>
              <li>the provision or failure to provide any service; </li>
              <li>errors or inaccuracies contained on the App or any information, software, products, services, and related graphics obtained through the App; </li>
              <li>any transactions entered into through this App; </li>
              <li>any property damage including damage to your computer or computer system or phone caused by viruses or other harmful components, during or on account of access to or use of this App or any software to which it provides hyperlinks; or </li>
              <li>damages otherwise arising out of the use of the App, any delay or inability to use the App, or any information, products, or services obtained through the App. </li>
            </ol>
            <p>The limitations of liability shall apply regardless of the form of action, whether based on contract, tort, negligence, strict liability or otherwise, even if a Covered Party has been advised of the possibility of damages.</p>
            <p>To the extent permitted by law, no liability or responsibility is accepted for any damage and/or delay due to matters out of our control, including, without limitation, downtime or lack of connection to the App or to any of its services, employment disputes, hardware or software failure, quarantine, government restraints, weather or terrorism. No liability or responsibility is accepted for any additional expense, omissions, delays, failure to deliver services or product, or acts of any governmental authority.</p>
            <p>If, notwithstanding the above, a Covered Party is found liable for any loss or damage relating to the use of this App, you agree that the liability of any such Covered Party, other than liability for personal injury or death (for which there shall be no limitation) shall in no event exceed £100.</p>

            <li style={{ fontWeight: 'bolder' }}>Indemnity</li>

            <p>You agree to defend and fully indemnify us and the Covered Parties from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including, but not limited to, reasonable legal and accounting fees, brought:</p>
            <p><ol type='i'>
              <li>by you or on behalf of you in excess of the liability described in Section 10 above; or</li>
              <li> by third parties as a result of:</li>
              <ol type='a'>
                <li>your breach of this Agreement; </li>
                <li>your violation of any law or the rights of a third party, including, without limitation, third party intellectual property rights; or </li>
                <li>your use of this App in breach of this Agreement. </li>
              </ol>
            </ol></p>

            <li style={{ fontWeight: 'bolder' }}>Creation of Hyperlinks </li>

            <p>Unless expressly agreed in advance in writing by us, no User, other individual or entity may, whether through any web or phone apps or other media:</p>
            <p><ol type='i'>
              <li>effect a hyperlink to any page beyond the homepage of this App;</li>
              <li>frame this App or any web page or material contained in this App; or </li>
              <li>hyperlink any aspect of this App in an email for commercial purposes.</li>
            </ol></p>

            <li style={{ fontWeight: 'bolder' }}>Modification of the App</li>

            <p>We reserve the right, in our sole discretion, at any time for any reason, with or without notice to you to:</p>
            <p><ol type='i'>
              <li>modify, suspend, or terminate this App and/or any portion of it;</li>
              <li>modify, suspend, or terminate any service or product available through the App; and/or </li>
              <li>modify, suspend, or terminate your profile, Account password, or use of the App, or any portion of it. </li>
            </ol></p>

            <li style={{ fontWeight: 'bolder' }}>Termination of Use</li>

            <p>You may terminate your use of the App at any time if appropriate for you to do so in the context of your employment or if requested to do so by your employer.We may terminate accessibility to, or termination of your use of, this App at any time by giving written notice, unless the circumstances require or prevent written notification, including, without limitation, where your use of the App is in breach of this Agreement.In the event of termination, you will still be bound by your obligations under this Agreement and any additional terms, including the warranties made by you, and by the disclaimers and limitations of liability made by us.We shall not be liable to you or any third party for any termination of your access to this App.</p>

            < li style={{ fontWeight: 'bolder' }}> International Use </li >

            <p>We make no representation that materials on this App are appropriate or available for use in locations outside of the United Kingdom.Accessing materials on this App by certain persons in certain countries may not be lawful, and if you are resident in a country other than in the United Kingdom, you must not transact business with this App.</p>

            < li style={{ fontWeight: 'bolder' }}> Entire Agreement</li >

            <p>This Agreement, and the related parts of this Agreement relating to each service represent the entire agreement between you and each Covered Party regarding your use of this App and supersede any prior statements or representations(in either case, whether oral or in writing), or prior versions of these Terms and Conditions relating to the use of the App that were displayed on this App before.</p>

            < li style={{ fontWeight: 'bolder' }}> Changes to these terms and conditions</li >

            <p>We reserve all rights to modify, revise or update this Agreement from time to time by updating this posting.Your continued use of this App will be subject to the terms of this Agreement in effect at the time of your use.</p>

            < li style={{ fontWeight: 'bolder' }}> Severability  </li >

            <p>If for any reason a court of competent jurisdiction finds any provision of this Agreement invalid or unenforceable, that provision of the Agreement will be enforced to the maximum extent permissible and the other provisions of this Agreement will remain in full force and effect.</p>

            < li style={{fontWeight:'bolder'}}>Jurisdiction</li >

            <p>This Agreement will be governed by and construed in accordance with the laws of England and Wales, excluding that body of laws known as conflicts of law.We both agree to submit to the exclusive jurisdiction of the courts of England.</p>
          </ol>
        </div >
      </div>
    </div>
  );
}
