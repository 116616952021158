import { useState, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle, useContext } from "react";
import "./Survey.css";
import { Menu, Loader, Header, Text, Button, Flex, Label } from "@fluentui/react-northstar"
import { SurveyTab } from "./SurveyTab"
import { SpokeFormResponse } from "../../../interfaces/spokeFormResponse"
import {
    SpokeForm, SpokeFormTab,
    SpokeFormQuestionValue,
    SpokeFormQuestion,
    SpokeFormQuestionColumnValue,
    SpokeFormQuestionRowValue
} from "../../../interfaces/spokeForm"
import * as microsoftTeams from "@microsoft/teams-js";
import { SurveyAPI } from '../../../api/SurveyAPI'
import { ErrorWithCode } from "@microsoft/teamsfx";
import { app } from "@microsoft/teams-js";
import { useMediaQuery } from "react-responsive"
import { TeamsFxContext } from "../../Context";
import { userInfo } from "os";

const Survey = (props: { communicationId?: number, parent?: string }, ref: any) => {

    useImperativeHandle(
        ref,
        () => ({
            showAlert() {
                alert("Child Function Called")
                console.log('hello child world')
            }
        }),
    )

    const [communicationId, setCommunicationId] = useState<number>();
    const [selectedTabItem, setSelectedTabItem] = useState({});
    const [surveyForm, setSurveyForm] = useState<SpokeForm>();
    const [surveyResponse, setSurveyResponse] = useState<SpokeFormResponse>();
    const [surveyResponseId, setSurveyResponseId] = useState<number>();
    const [loading, setLoading] = useState<boolean>(true);
    const [validate, setValidate] = useState<boolean>(false);
    const [tabs, setTabs] = useState<any>({})
    const [token, setToken] = useState<string>();
    const [tenantId, setTenantId] = useState<string>();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
    const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

    useEffect(() => {
        const params = new URLSearchParams(window.location.hash.substring(9))
        if (params.get('responseId')) {
            setSurveyResponseId(Number(params.get('responseId')))
        }
        if (params.get('communicationId')) {
            setCommunicationId(Number(params.get('communicationId')))
        }
        else if (props.communicationId) {
            setCommunicationId(props.communicationId)
        }
    }, [props.communicationId])

    const TabLabel = useCallback((props: { tab: SpokeFormTab }) => {
        const circle = {
            width: '1rem',
            height: '1rem',
            alignItems: 'center',
            backgroundColor: 'rgb(196, 49, 75)',
            justifyContent: 'center',
            borderRadius: '50%',
            fontSize: '6pt',
            verticalAlign: 'super'
        }
        return (<>
            <span>{props.tab.DisplayName}</span>
            {props.tab.ErrorCount && props.tab.ErrorCount > 0 ?
                <Label style={circle} color='red' content={props.tab.ErrorCount} /> : <></>}</>)
    }, [])

    const fecthData = (communicationId: number, token:string, tenantId: string) => {
        SurveyAPI.survey(communicationId, token, tenantId).then((surveyFormResponseData) => {
            setSurveyForm(surveyFormResponseData)
            setTabs(surveyFormResponseData.SpokeFormTabs.map((tab: SpokeFormTab) => {
                return {
                    key: tab.SpokeFormTabId!.toString(),
                    content: <TabLabel tab={tab} />,
                    onClick: () => setSelectedTabItem(tab.SpokeFormTabId!),
                };
            }));
            setSelectedTabItem(surveyFormResponseData.SpokeFormTabs[0].SpokeFormTabId)
            SurveyAPI.answer(communicationId, token, tenantId, surveyResponseId).then((surveyFormDataResponseData) => {
                if (surveyFormDataResponseData) {
                    setSurveyResponse(surveyFormDataResponseData)
                } else {
                    setSurveyResponse({
                        SpokeFormId: surveyFormResponseData.SpokeFormId,
                        SpokeFormQuestionResponses: [],
                        SpokeFormResponseId: 0,
                    })
                }
                setLoading(false)
            })
        })
    }

    useEffect(() => {
        if (communicationId) {
            teamsUserCredential?.getUserInfo().then((userInfo) => {
                setTenantId(userInfo.tenantId);
            teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                setToken(tokenResponse!.token)
                fecthData(communicationId, tokenResponse!.token, userInfo.tenantId)
            }).catch((err: ErrorWithCode) => {
                console.log('News get token error: ', err.code, err.message)
                if (err.code === 'UiRequiredError') {
                    console.log('prompt user to login', process.env.REACT_APP_IDENTIFIER_URI)
                    teamsUserCredential!.login(scope).then(() => {
                        teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                            setToken(tokenResponse!.token)
                            fecthData(communicationId, tokenResponse!.token, userInfo.tenantId)
                        })
                    })
                }
            })
        })
        }
    }, [communicationId, surveyResponseId, TabLabel])

    const valid = useCallback((_surveyResponse?: SpokeFormResponse) => {
        let errorCount = 0;
        const updatedTabs = surveyForm?.SpokeFormTabs.map(t => {
            t.ErrorCount = 0;
            const requiredItems = t.SpokeFormItems!.filter(i => i.SpokeFormQuestion.Required)
            requiredItems?.forEach(ri => {
                const responseAnswer = _surveyResponse?.SpokeFormQuestionResponses.find(r => r.SpokeFormQuestionId === ri.SpokeFormQuestionId)
                switch (ri.SpokeFormQuestion.ControlType) {
                    case 0: // Textbox
                    case 1: // Number 
                    case 2: // Textarea    
                    case 3: // Dropdown                    
                    case 5: // Checkbox
                    case 7: // DatePicker
                        if (!responseAnswer || responseAnswer.Response === '') {
                            t.ErrorCount++;
                            errorCount++;
                        }
                        break;
                    case 4: // CheckboxList
                    case 6: // RadioButtonList
                    case 10: // YesNo
                    case 11: // YesNoNA
                        if (responseAnswer?.SpokeFormQuestionValueResponses?.length === 0) {
                            t.ErrorCount++;
                            errorCount++;
                        }
                        break;
                    case 8: // Matrix
                        const colCount = ri.SpokeFormQuestion.SpokeFormQuestionValues.filter(qv => qv.SpokeFormQuestionIdColumn).length
                        const rowCount = ri.SpokeFormQuestion.SpokeFormQuestionValues.filter(qv => qv.SpokeFormQuestionIdRow).length
                        const blanks = responseAnswer?.SpokeFormQuestionValueResponses?.find(r => r.Response === '')
                        if (ri.SpokeFormQuestion.DisplayRadio) {
                            if (blanks || rowCount !== responseAnswer?.SpokeFormQuestionValueResponses?.length) {
                                t.ErrorCount++;
                                errorCount++;
                            }
                        } else {
                            if (blanks || (rowCount * colCount) !== responseAnswer?.SpokeFormQuestionValueResponses?.length) {
                                t.ErrorCount++;
                                errorCount++;
                            }
                        }
                        break;
                }
            });
            return ({
                key: t.SpokeFormTabId!.toString(),
                content: <TabLabel tab={t} />,
                onClick: () => setSelectedTabItem(t.SpokeFormTabId!)
            });
        })
        setTabs(updatedTabs)
        return errorCount;
    }, [TabLabel, surveyForm?.SpokeFormTabs])

    const isValid = () => {
        const errorCount = valid(surveyResponse)
        if (errorCount === 0) {
            setValidate(false)
            return true
        } else {
            setValidate(true)
            return false
        }
    }

    const Description = () => {
        return (<p><Text weight="regular" size="medium" content={surveyForm?.Description} /></p>)
    }

    const Survey = useMemo(() => {

        const handleChange: any = (event: any, data: any,
            validate: boolean,
            spokeFormQuestion: SpokeFormQuestion,
            spokeFormQuestionValue?: SpokeFormQuestionValue,
            spokeFormQuestionColumnValue?: SpokeFormQuestionColumnValue,
            spokeFormQuestionRowValue?: SpokeFormQuestionRowValue) => {

            let spokeFormQuestionValueResponse
            const _surveyResponse = surveyResponse
            let response = _surveyResponse?.SpokeFormQuestionResponses.find(questionResponse => questionResponse.SpokeFormQuestionId === spokeFormQuestion?.SpokeFormQuestionId)
            if (!response) {
                response = { SpokeFormQuestionId: spokeFormQuestion.SpokeFormQuestionId, SpokeFormQuestionValueResponses: [] }
                _surveyResponse?.SpokeFormQuestionResponses.push(response);
            }
            switch (spokeFormQuestion?.ControlType) {
                case 0:
                case 1:
                case 2:
                    response.Response = data.value
                    break;
                case 7: // Datepicker
                    response.Response = data.value.toISOString().split("T")[0];
                    break;
                case 3: // Dropdown
                    response.Response = data.value.key
                    if (response.SpokeFormQuestionValueResponses!.length > 0) {
                        response.SpokeFormQuestionValueResponses![0].Response = data.value.key
                        response.SpokeFormQuestionValueResponses![0].SpokeFormQuestionValueId = data.value.key
                    } else {
                        spokeFormQuestionValueResponse = {
                            Response: data.value.key,
                            SpokeFormQuestionValueId: data.value.key
                        }
                        response.SpokeFormQuestionValueResponses!.push(spokeFormQuestionValueResponse)
                    }
                    break;
                case 4:  // CheckboxList
                    spokeFormQuestionValueResponse = response.SpokeFormQuestionValueResponses?.find(questionValueResponse => questionValueResponse.SpokeFormQuestionValueResponseId === spokeFormQuestionValue!.SpokeFormQuestionValueId)
                    if (!spokeFormQuestionValueResponse) {
                        spokeFormQuestionValueResponse = { SpokeFormQuestionValueId: spokeFormQuestionValue!.SpokeFormQuestionValueId }
                        response.SpokeFormQuestionValueResponses?.push(spokeFormQuestionValueResponse)
                    }
                    if (data.checked) {
                        spokeFormQuestionValueResponse.Response = data.label
                    } else {
                        response.SpokeFormQuestionValueResponses = response.SpokeFormQuestionValueResponses?.filter(questionValueResponse => questionValueResponse.SpokeFormQuestionValueId !== spokeFormQuestionValue!.SpokeFormQuestionValueId)
                    }
                    break;
                case 5:
                    if (data.checked) {
                        response.Response = data.label

                    } else {
                        response.Response = undefined;
                    }
                    break;
                case 6: // RadioButtonList
                case 10: // YesNo
                case 11: //YesNoNA
                    response.Response = data.value
                    if (response.SpokeFormQuestionValueResponses!.length > 0) {
                        response.SpokeFormQuestionValueResponses![0].Response = data.value
                        response.SpokeFormQuestionValueResponses![0].SpokeFormQuestionValueId = data.value
                    } else {
                        spokeFormQuestionValueResponse = {
                            Response: data.value,
                            SpokeFormQuestionValueId: data.value
                        }
                        response.SpokeFormQuestionValueResponses!.push(spokeFormQuestionValueResponse)
                    }
                    break;
                case 8:
                    if (spokeFormQuestion.DisplayRadio) {
                        const cols = response.SpokeFormQuestionValueResponses?.filter(questionValueResponse => questionValueResponse.SpokeFormQuestionValueIdRow === spokeFormQuestionRowValue?.SpokeFormQuestionValueId)
                        cols?.forEach((col) => {
                            col.Response = undefined
                        })
                    }
                    spokeFormQuestionValueResponse = response.SpokeFormQuestionValueResponses?.find(
                        questionValueResponse => questionValueResponse.SpokeFormQuestionValueIdColumn === spokeFormQuestionColumnValue?.SpokeFormQuestionValueId
                            && questionValueResponse.SpokeFormQuestionValueIdRow === spokeFormQuestionRowValue?.SpokeFormQuestionValueId)
                    if (!spokeFormQuestionValueResponse) {
                        spokeFormQuestionValueResponse = {
                            SpokeFormQuestionValueIdRow: spokeFormQuestionRowValue?.SpokeFormQuestionValueId,
                            SpokeFormQuestionValueIdColumn: spokeFormQuestionColumnValue?.SpokeFormQuestionValueId,
                            SpokeFormQuestionValueId: spokeFormQuestionRowValue?.SpokeFormQuestionIdRow,
                            Response: data.value
                        }
                        response.SpokeFormQuestionValueResponses?.push(spokeFormQuestionValueResponse)
                    } else {
                        spokeFormQuestionValueResponse.Response = data.value
                    }
                    break;
            }
            if (validate) {
                valid(_surveyResponse)
            }
        }

        if (!loading) {
            return (
                <SurveyTab validate={validate}
                    selectedTabId={selectedTabItem}
                    surveyForm={surveyForm}
                    surveyResponse={surveyResponse}
                    handleChange={handleChange}
                    parent={props.parent} />
            )
        } else {
            return (<Loader size="largest" styles={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />)
        }
    }, [loading, validate, selectedTabItem, surveyResponse, surveyForm, valid])

    const SaveButton = () => {
        if (surveyResponse?.Status === undefined || surveyResponse?.Status === 3 || surveyForm?.AllowResponseEdit) {
            return (<Button content="Save" secondary onClick={(event, data) => { handleSave(event, data, false) }} />)
        } else {
            return <></>
        }
    }

    const CompleteButton = () => {
        if (surveyResponse?.Status === undefined || surveyResponse?.Status === 3) {
            return (<Button content="Complete" primary onClick={(event, data) => { handleSave(event, data, true) }} />)
        } else {
            return <></>
        }
    }

    const handleSave: any = async (event: any, data: any, submit: boolean) => {
        if (communicationId && surveyResponse && token && tenantId) {
            let _surveyResponse = surveyResponse;
            if (submit) {
                if (isValid()) {
                    _surveyResponse!.Status = 2
                } else {
                    return
                }
            } else {
                _surveyResponse!.Status = 3
            }
            await SurveyAPI.update(communicationId, _surveyResponse, token, tenantId)
        }
        microsoftTeams.dialog.url.submit();
    }

    return (
        <div className="survey page" style={{
            marginBottom: '3em',
            height: '100%',
            overflowY: 'auto'
        }}>
            <div className="narrow">
            <Flex gap="gap.small" hAlign="end">
                        <SaveButton />
                        <CompleteButton />
                    </Flex>
                <Header
                    as="h3"
                    content={surveyForm?.Name}
                    //description={<Description/>}
                    description={{
                        as: Description
                    }}
                />  
                <Menu defaultActiveIndex={0} items={tabs} underlined secondary />
                <div id='tabContent' style={{ height: 'fit-content', paddingBottom: '3em' }}>
                {Survey}                
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Survey)