import { useState, useEffect, useContext } from "react";
import { formatDistance } from 'date-fns'
import { List, NotepadPersonIcon, Text } from "@fluentui/react-northstar"
import * as microsoftTeams from "@microsoft/teams-js";
import { SurveyAPI } from '../../../api/SurveyAPI'
import { TeamsFxContext } from "../../Context";
import { ErrorWithCode } from "@microsoft/teamsfx";

export default function Surveys(props: { ongoing: boolean }) {

    const [surveys, setSurveys] = useState<{}[]>();
    const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
    const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

    useEffect(() => {
        teamsUserCredential?.getUserInfo().then((userInfo) => {
            teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                load(tokenResponse!.token, userInfo.tenantId)
            }).catch((err: ErrorWithCode) => {
                if (err.code === 'UiRequiredError') {
                    teamsUserCredential!.login(scope).then(() => {
                        teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                            load(tokenResponse!.token, userInfo.tenantId)
                        })
                    })
                }
            })
        })
    }, [props.ongoing]);

    const load = (token: string, tentantId: string) => {
        SurveyAPI.surveys(props.ongoing ? 'ongoing' : 'standalone', token, tentantId).then((data) => {
            setSurveys(data.filter((i: any) => !i.FeedbackOngoing).map((i: any) => {
                const distance = formatDistance(new Date(i.CreatedDate), new Date(), {
                    addSuffix: true
                })
                return {
                    key: i.CommunicationId,
                    media: <NotepadPersonIcon outline size="medium" />,
                    header: i.Title,
                    content: `${distance}`,
                    styles: {
                        textDecoration: i.Responses > 0 ? 'line-through' : 'inherit'
                    },
                    onClick: () => { openSurvey(i.CommunicationId, i.Title) }
                };
            }))
        })
    }

    const openSurvey = (communicationId: number, title: string) => {
        let dialogSubmitHandler: microsoftTeams.dialog.DialogSubmitHandler = (result: any) => {
            console.log('closed task dialog')
        }
        let urlDialogInfo: microsoftTeams.UrlDialogInfo = {
            url: `${process.env.REACT_APP_URL}/index.html#/survey?communicationId=${communicationId}`,
            title: title,
            size: {
                height: 600,
                width: 1224
            }
        };
        microsoftTeams.dialog.url.open(urlDialogInfo, dialogSubmitHandler)
    }

    return (
        surveys && surveys.length > 0 ?
            <List navigable onSelectedIndexChange={() => { console.log('updated!') }} items={surveys} />
            : <Text content="You don't have any surveys to complete." />
    )
}