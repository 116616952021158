import { useState, useEffect, useRef, useContext } from "react";
import { INews } from '../../../interfaces/news'
import { Content } from "../../../interfaces/content";
import { Flex, Image, Header, Loader, Text } from "@fluentui/react-northstar"
import { SocialSection } from '../social/SocialSection'
import { Reactions } from '../social/Reactions'
import { NewsAPI } from '../../../api/NewsAPI'
import { Attachments } from "../attachment/Attachments";
import { ErrorWithCode } from "@microsoft/teamsfx";
import { TeamsFxContext } from "../../Context";

export default function NewsContent(props: {
    selectedComm?: INews,
    selectedMostRead?: INews,
    refreshNewsListSocial?: any
}) {

    const [news, setNews] = useState<INews>();
    const [loading, setLoading] = useState<boolean>(true);
    const [richText, setRichText] = useState<object>();
    const [updateSocial, setUpdateSocial] = useState<boolean>(false);
    const contentRef = useRef<any>();
    const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
    const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

    useEffect(() => {
        if (props.selectedMostRead) {
            getNewsDetail(props.selectedMostRead?.CommunicationId)
        } else {
            getNewsDetail(props.selectedComm?.CommunicationId)
        }
    }, [props.selectedComm?.CommunicationId, props.selectedMostRead, updateSocial]);

    useEffect(() => {
        (async () => {
            if (news?.Content) {
                const content: Content[] = news?.Content.filter(c => c.ContentType === 'ContentRichText')
                setRichText(await Promise.all(content.map(async (c) => {
                    const response = await fetch(c.contentUrl);
                    const html = await response.text();
                    return (<div key={'innerHtml'} dangerouslySetInnerHTML={{ __html: html }} />)
                })))
            }
        })();
    }, [news]);

    async function getNewsDetail(communicationId?: number) {
        if (communicationId) {
            console.log('newsContent getNewsDetail ', communicationId)
            teamsUserCredential?.getUserInfo().then((userInfo) => {
                teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                    NewsAPI.get(communicationId, tokenResponse!.token, userInfo.tenantId)
                        .then((response) => {
                            setNews(response);
                            setLoading(false)
                            if (contentRef.current) {
                                contentRef.current.scrollTo(0, 0);
                            }
                        })
                        .catch((error) => {
                            console.log('NewsContent error ', error)
                        })
                }).catch((err: unknown) => {
                    if (err instanceof ErrorWithCode && err.code === 'UiRequiredError') {
                        teamsUserCredential!.login(scope).then(() => {
                            teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                                NewsAPI.get(communicationId, tokenResponse!.token, userInfo.tenantId)
                                    .then((response) => {
                                        setNews(response);
                                        setLoading(false)
                                        if (contentRef.current) {
                                            contentRef.current.scrollTo(0, 0);
                                        }
                                    })
                                    .catch((error) => {
                                        console.log('NewsContent error ', error)
                                    })
                            })
                        })
                    }
                })
            })
        }
    }

    const ContentHtml = () => {
        if (richText !== undefined) {
            return (<div>{richText}</div>)
        } else {
            return (<div></div>)
        }
    }

    const Subtitle = ({ news }: { news: INews }) => {
        return (
            <>
                {news?.ByLine}
                {news?.Social?.Reactions ? <p><Reactions reactions={news?.Social.Reactions} commentsCount={news?.SocialSummary?.CommentsCount} /></p> : null}
            </>
        )
    }

    function refreshSocial() {
        getNewsDetail(props.selectedComm?.CommunicationId)
        // setUpdateSocial(!updateSocial)
        // if (props.refreshNewsListSocial) {
        //     props.refreshNewsListSocial()
        // }
    }

    const ContentFooter = () => {
        return (
            news?.Social && news?.SocialEnabled ? <SocialSection social={news?.Social} refreshSocial={refreshSocial} /> : null
        )
    }

    const ContentHeader = () => {
        return (
            <>
                <Flex gap="gap.small">
                    <Image
                        style={{
                            height: '115px',
                            width: '115px',
                        }}
                        src={news !== undefined && news.ThumbnailUrl?.length > 0 ? news?.ThumbnailUrl : "/square-image.png"}
                    />
                    <Header
                        as="h3"
                        styles={{ margin: '0px', padding: '0px' }}
                        content={news?.Title}
                        description={{
                            content: Subtitle({ news: news! }),
                            as: 'span',
                            styles: { fontSize: 'small' }
                        }}
                    />
                </Flex>
                <Flex gap="gap.small" styles={{ paddingTop: "5px" }}>
                    <Attachments content={news?.Content} />
                </Flex>
            </>)
    }


    return (
        <>
            {
                !loading ?
                    <Flex column fill >
                        <div ref={contentRef} style={{ marginTop: '1em', marginBottom: '10em', overflowY: 'auto' }}>
                            <ContentHeader />
                            <ContentHtml />
                            <ContentFooter />
                        </div>
                    </Flex> : <Flex fill hAlign="center" vAlign="center"><Loader size="larger" /></Flex>
            }
        </>
    )
}