import { useState, useEffect, useContext } from "react";
import { Box, Menu, Button, Loader, tabListBehavior, Flex, Segment, Header, Grid, Text } from "@fluentui/react-northstar"
import { Role } from '../../interfaces/user'
import TaskList from './task/TaskList'
import TaskCompliance from './task/TaskCompliance'
import SurveyList from './survey/SurveyList'
import SurveyResponses from './survey/SurveyResponses'
import Questions from './faq/Questions'
import Banner from './Banner'
import NewsViewed from './news/NewsViewed'
import { UserAPI } from '../../api/UserAPI'
import { useMediaQuery } from "react-responsive"
import { app } from "@microsoft/teams-js";
import { ITaskView } from "../../interfaces/taskView";
import Task from './task/Task'
import { TeamsFxContext } from "../Context";
import { ErrorWithCode } from "@microsoft/teamsfx";

export default function Home() {

    const [selectedMenuItem, setSelectedMenuItem] = useState({});
    const [menuActiveIndex, setMenuActiveIndex] = useState<number>(0);
    const [userRole, setUserRole] = useState<string>();
    const [taskComplianceKey, setTaskComplianceKey] = useState<string>(Math.random().toString(36).slice(2, 7))
    const [loading, setLoading] = useState<boolean>(true);
    const [validUser, setValidUser] = useState<boolean>(true);
    const [taskView, setTaskView] = useState<ITaskView>();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const tabs = [{
        key: 'tasks',
        content: 'Tasks',
        onClick: () => { setMenuActiveIndex(0); setSelectedMenuItem('tasks') },
    },
    {
        key: 'surveys',
        content: 'Surveys',
        onClick: () => { setMenuActiveIndex(1); setSelectedMenuItem('surveys') },
    },
    {
        key: 'faq',
        content: 'FAQ',
        onClick: () => { setMenuActiveIndex(2); setSelectedMenuItem('faq') },
    }]
    const { themeString, teamsUserCredential } = useContext(TeamsFxContext);

    useEffect(() => {
        loadTab()
        setLoading(false);
        setSelectedMenuItem('tasks')
    }, [])

    const loadTab = () => {
        const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];
        teamsUserCredential?.getUserInfo().then((userInfo) => {
            teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                if (tokenResponse) {
                    UserAPI.me(tokenResponse.token, userInfo.tenantId)
                        .then((user) => {
                            const role = user.Roles.find((r: Role) => r.Name.toLowerCase())
                            setUserRole(role)
                            setLoading(false);
                        })
                        .catch((error) => {
                            if (error.response.status.toString().startsWith('4')) {
                                setValidUser(false)
                            }
                            console.log('UserAPI error ', error)
                        })
                } else {
                    console.log('Inavlid token response')
                }
            })
        }).catch((err: unknown) => {
            if (err instanceof ErrorWithCode && err.code === 'UiRequiredError') {
                teamsUserCredential!.login(scope).then(() => {
                    teamsUserCredential?.getUserInfo().then((userInfo) => {
                        teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                            if (tokenResponse) {
                                UserAPI.me(tokenResponse.token, userInfo.tenantId)
                                    .then((user) => {
                                        const role = user.Roles.find((r: Role) => r.Name.toLowerCase())
                                        setUserRole(role)
                                        setLoading(false);
                                    })
                                    .catch((error) => {
                                        if (error.response.status.toString().startsWith('4')) {
                                            setValidUser(false)
                                        }
                                        console.log('UserAPI error ', error)
                                    })
                            } else {
                                console.log('Invalid token response')
                            }
                        })
                    })
                })
            }
        })
    }

    const HighLevelMobile = () => {
        return (
            <>
                <Banner />
                <Flex column gap="gap.small" padding="padding.medium">
                    <Segment content={<TaskCompliance key={taskComplianceKey} />} />
                    <Segment content={<NewsViewed />} />
                </Flex>
            </>
        )
    }

    const highLevel = (
        taskView ?
            <Task TaskView={taskView} />
            :
            isDesktopOrLaptop ?
                <>
                    <Banner />
                    <Flex fill gap="gap.small" padding="padding.medium" >
                        <Flex.Item size="size.half">
                            <Segment content={<TaskCompliance key={taskComplianceKey} />} />
                        </Flex.Item>
                        <Flex.Item size="size.half">
                            <Segment content={<NewsViewed />} />
                        </Flex.Item>
                    </Flex>
                </>
                :
                <HighLevelMobile />
    )

    const FrontlineMobile = () => {
        return (
            <div style={{ height: '100%', overflowY: 'auto' }}>
                <Banner />

                <Menu defaultActiveIndex={0} activeIndex={menuActiveIndex} accessibility={tabListBehavior} items={tabs} pointing primary />

                {selectedMenuItem === "tasks" && (
                    <Box>
                        <Flex>
                            <Flex.Item>
                                <TaskList handleChange={handleChange} handleTaskView={handleTaskView} />
                            </Flex.Item>
                        </Flex>
                        <Flex>
                            <Flex.Item>
                                <TaskCompliance key={taskComplianceKey} />
                            </Flex.Item>
                        </Flex>
                    </Box>
                )}
                {selectedMenuItem === "surveys" && (
                    <Box>
                        <Flex column gap="gap.small" vAlign="stretch" padding="padding.medium">
                            <Header color="brand" as="h3" content="Ongoing Surveys" styles={{ padding: 0 }} />
                            <SurveyList ongoing={true} />
                        </Flex>
                        <Flex column gap="gap.small" vAlign="stretch" padding="padding.medium">
                            <Header color="brand" as="h3" content="Surveys" styles={{ padding: 0 }} />
                            <SurveyList ongoing={false} />
                        </Flex>
                        <Flex column gap="gap.small" vAlign="stretch" padding="padding.medium">
                            <SurveyResponses />
                        </Flex>
                    </Box>
                )}
                {selectedMenuItem === "faq" && (
                    <Questions />
                )}
            </div>
        )
    }

    const handleChange: any = () => {
        console.log('Home screen handle task change ', taskComplianceKey)
        const newKey = Math.random().toString(36).slice(2, 7)
        console.log('new Task key ', newKey)
        setTaskComplianceKey(newKey)
    }

    const handleTaskView: any = (taskView: ITaskView) => {
        console.log('Handle a selected Task without a dialog')
        setTaskView(taskView)
    }

    const handlePostSave: any = () => {
        console.log('Task has been saved without using a dialog')
        setTaskView(undefined)
    }

    const frontline = (
        taskView ?
            <div className="task page" style={{ height: '100%', overflowY: 'auto' }}>
                <div className="narrow">
                    <Button content="Back" onClick={() => { setTaskView(undefined) }} />
                    <Task TaskView={taskView} postSave={handlePostSave} />
                </div>
            </div>
            :
            isDesktopOrLaptop ?
                <>
                    <Banner />
                    <Flex gap="gap.small" padding="padding.medium" styles={{ height: '100%', backgroundColor: 'white' }}>
                        <Flex.Item >
                            <Menu
                                defaultActiveIndex={0} accessibility={tabListBehavior} items={tabs} vertical pointing />
                        </Flex.Item>
                        <Flex.Item styles={{ width: '100%' }}>
                            <div>
                                {selectedMenuItem === "tasks" && (
                                    <Grid columns="60% 40%" styles={{ height: '100%' }}>
                                        <TaskList handleChange={handleChange} handleTaskView={handleTaskView} />
                                        <TaskCompliance key={taskComplianceKey} />
                                    </Grid>

                                )}
                                {selectedMenuItem === "surveys" && (
                                    <Flex gap="gap.small" padding="padding.medium">
                                        <Flex.Item size="size.half">
                                            <Flex fill column gap="gap.small" padding="padding.medium">
                                                <Flex.Item>
                                                    <>
                                                        <Header as="h3" content="Ongoing Surveys" styles={{ padding: 0 }} />
                                                        <SurveyList ongoing={true} />
                                                    </>
                                                </Flex.Item>
                                                <Flex.Item>
                                                    <>
                                                        <Header as="h3" content="Surveys" styles={{ padding: 0 }} />
                                                        <SurveyList ongoing={false} />
                                                    </>
                                                </Flex.Item>
                                            </Flex>
                                        </Flex.Item>
                                        <Flex.Item size="size.half">
                                            <Flex fill column gap="gap.small" padding="padding.medium">
                                                <Flex.Item>
                                                    <SurveyResponses />
                                                </Flex.Item>
                                            </Flex>
                                        </Flex.Item>
                                    </Flex>
                                )}
                                {selectedMenuItem === "faq" && (
                                    <Questions />
                                )}
                            </div>
                        </Flex.Item>
                    </Flex>
                </>
                :
                <FrontlineMobile />
    )

    return (
        !validUser ?
            <Flex fill hAlign="center" vAlign="center" column>
                <Text weight='bold' content='You need to be a subscriber of the Metro service from Retail Manager Solutions Ltd to use this application.' />
                <p>Please <a target='_blank' href='https://www.metrofy.com/contact-us'>contact us</a> at Retail Manager Solutions Ltd for more information or support.</p>
            </Flex>
            : !loading ?
                userRole === 'teamshighlevel' ? highLevel : frontline
                : <Flex fill hAlign="center" vAlign="center"><Loader size='larger' /></Flex>
    )
}