import { Segment, Flex } from "@fluentui/react-northstar"
import Find from './search/Find'

export default function Banner() {

    return (
        <Flex>
            <Flex.Item grow>
                <Segment styles={{ paddingBottom: '0.5em', paddingTop: '0.5em' }} content={<Find />} />
            </Flex.Item>            
        </Flex>
    )
}