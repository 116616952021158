import { useState, useEffect, useContext } from "react";
import { NewsAPI } from '../../../api/NewsAPI'
import { INews } from '../../../interfaces/news'
import { formatDistance } from 'date-fns'
import { ReactionsSummary, } from '../social/Reactions'
import { Divider, Text, Card, Image, Flex, Box } from "@fluentui/react-northstar"
import { ErrorWithCode } from "@microsoft/teamsfx";
import { TeamsFxContext } from "../../Context";

export default function NewList(
    props: {
        loadDefault?: boolean,
        onSelectedComm: any,
        resetSelectedComm?: boolean,
        updateNewsListSocial: boolean,
        searchQuery?: string,
        searchResult?: any
    }
) {

    const [newsList, setNewsList] = useState<INews[]>();
    const [selectedComm, setSelectedComm] = useState<INews>();
    const [token, setToken] = useState<string>();
    const [tenantId, setTenantId] = useState<string>();
    const loadComm = props.onSelectedComm
    const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
    const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

    useEffect(() => {
        teamsUserCredential?.getUserInfo().then((userInfo) => {
            setTenantId(userInfo.tenantId);
            teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                setToken(tokenResponse!.token)

                if (props.searchQuery) {
                    NewsAPI.search(props.searchQuery, tokenResponse!.token, userInfo.tenantId)
                        .then((response) => {
                            setNewsList(response)
                            if (props.loadDefault) {
                                setSelectedComm(response[0])
                            }
                            props.searchResult(response.length)
                        })
                } else {
                    console.log('NewsList: calling /news with token ', tokenResponse!.token, userInfo.tenantId)
                    NewsAPI.all(tokenResponse!.token, userInfo.tenantId)
                        .then((response) => {
                            console.log('NewList response ', response)
                            const ordered = response.reverse()
                            setNewsList(ordered)
                            if (props.loadDefault) {
                                console.log('NewsList setSelectedComm ', ordered[0])
                                setSelectedComm(ordered[0])
                            }
                        })
                }
            }).catch((err: ErrorWithCode) => {
                console.log('News get token error: ', err.code, err.message)
                if (err.code === 'UiRequiredError') {
                    console.log('prompt user to login', process.env.REACT_APP_IDENTIFIER_URI)
                    teamsUserCredential!.login(scope).then(() => {
                        teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                            setToken(tokenResponse!.token)
                            if (props.searchQuery) {
                                console.log('NewsList search  ', props.searchQuery, tokenResponse)
                                NewsAPI.search(props.searchQuery, tokenResponse!.token, userInfo.tenantId)
                                    .then((response) => {
                                        setNewsList(response)
                                        if (props.loadDefault) {
                                            setSelectedComm(response[0])
                                            props.searchResult(response.length)
                                        }
                                    })
                            } else {
                                NewsAPI.all(tokenResponse!.token, userInfo.tenantId)
                                    .then((response) => {
                                        console.log('NewList response ', response)
                                        const ordered = response.reverse()
                                        setNewsList(ordered)
                                        if (props.loadDefault) {
                                            setSelectedComm(ordered[0])
                                        }
                                    })
                            }
                        })
                    })
                }
            })
        })
    }, []);

    useEffect(() => {
        if (props.searchQuery && token) {
            console.log('NewsList search after query changed', props.searchQuery)
            NewsAPI.search(props.searchQuery, token!, tenantId!)
                .then((response) => {
                    setNewsList(response)
                    setSelectedComm(response[0])
                })
        } else if(token) {
            console.log('NewsList NewsAPI.all 2 ', token!)
            NewsAPI.all(token!, tenantId!)
                .then((response) => {
                    const ordered = response.reverse()
                    setNewsList(ordered)
                    setSelectedComm(ordered[0])
                })
        }
    }, [props.updateNewsListSocial, props.searchQuery]);

    useEffect(() => {
        setSelectedComm(undefined)
    }, [props.resetSelectedComm]);

    useEffect(() => {
        loadComm(selectedComm)
    }, [selectedComm]);

    let previousDate: Date;
    let groupHeader = (<></>);
    const cards = newsList?.map(n => {
        //console.log(previousDate, n.PublishedDate)
        groupHeader = (<></>)
        const distance = formatDistance(new Date(n.PublishedDate), new Date(), {
            addSuffix: true
        })
        if (previousDate) {
            if (previousDate.toDateString() !== new Date(n.PublishedDate).toDateString()) {
                groupHeader = (<Divider important styles={{ margin: '0em 1em 0em 1em' }}
                    content={distance} />)
                previousDate = new Date(n.PublishedDate);
            }
        } else {
            groupHeader = (<Divider styles={{ margin: '0em 1em 0em 1em' }}
                important content={distance} />)
            previousDate = new Date(n.PublishedDate);
        }
        return (
            <Box key={n.CommunicationId}>
                {groupHeader}
                <Card
                    key={n.CommunicationId}
                    onClick={(event) => { console.log("selectedComm", selectedComm?.CommunicationId); setSelectedComm(n) }}
                    compact
                    styles={{ margin: '0em 1em 1em 1em', padding: '0.5em', height: 'auto', width: 'auto' }}
                    horizontal
                    aria-roledescription="card with a preview image, text and action buttons"
                >
                    <Card.Column>
                        <Card.Header>
                            <Text content={n.Title} weight="bold" />
                        </Card.Header>
                        <Card.Body>
                            <Flex gap="gap.small">
                                <Image
                                    style={{
                                        height: '115px',
                                        width: '115px',
                                    }}
                                    src={n.ThumbnailUrl?.length > 0 ? n.ThumbnailUrl : "/square-image.png"}
                                />
                                <Flex column>
                                    <Text content={n.ByLine} />
                                </Flex>
                            </Flex>
                        </Card.Body>
                        <Card.Footer fitted>
                            <Flex>
                                <ReactionsSummary reactions={n.SocialSummary.ReactionsCount} commentsCount={n.SocialSummary.CommentsCount} />
                            </Flex>
                        </Card.Footer>
                    </Card.Column>
                </Card>
            </Box>
        )
    })
    return (<>{cards}</>)
}