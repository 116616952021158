import { useState, useEffect, useContext } from "react";
import { Segment, Breadcrumb, Flex, ChevronEndIcon, ListIcon, Loader, Text, Menu, Box, tabListBehavior } from "@fluentui/react-northstar"
import NewsList from "./NewsList"
import NewsContent from "./NewsContent"
import Video from "../video/Video"
import { NewsAPI } from '../../../api/NewsAPI'
import { UserAPI } from '../../../api/UserAPI'
import NewsRead from "./NewsRead"
import Banner from '../Banner'
import { useMediaQuery } from "react-responsive"
import { INews } from '../../../interfaces/news'
import { useNavigate } from "react-router-dom";
import { ErrorWithCode } from "@microsoft/teamsfx";
import { app } from "@microsoft/teams-js";
import { TeamsFxContext } from "../../Context";

export default function News() {

    const [selectedComm, setSelectedComm] = useState<INews>();
    const [selectedMostRead, setSelectedMostRead] = useState<INews>();
    const [updateNewsListSocial, setUpdateNewsListSocial] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true)
    const [validUser, setValidUser] = useState<boolean>(true)
    const [selectedMenuItem, setSelectedMenuItem] = useState({});
    const [menuActiveIndex, setMenuActiveIndex] = useState<number>(0);
    const [token, setToken] = useState<string>();
    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    const tabs = [{
        key: 'news',
        content: 'News',
        onClick: () => { setMenuActiveIndex(0); setSelectedMenuItem('news') },
    },
    {
        key: 'video',
        content: 'Video',
        onClick: () => { setMenuActiveIndex(1); setSelectedMenuItem('video') },
    },
    {
        key: 'mostRead',
        content: 'Most Read',
        onClick: () => { setMenuActiveIndex(2); setSelectedMenuItem('mostRead') },
    }]
    const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
    const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

    useEffect(() => {
        console.log('REACT_APP_IDENTIFIER_URI=', process.env.REACT_APP_IDENTIFIER_URI)
        console.log(`app is initialized ${app.isInitialized()}`)
        if (!app.isInitialized()) {
            app.initialize().then(() => {
                loadTab();
                setSelectedMenuItem('news')
            })
        } else {
            loadTab();
            setSelectedMenuItem('news')
        }
    }, [])

    const loadTab = () => {
        teamsUserCredential?.getUserInfo().then((userInfo) => {
            teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                setToken(tokenResponse!.token)
                UserAPI.me(tokenResponse!.token, userInfo.tenantId)
                    .catch((error) => {
                        console.log('News userAPI error ', error)
                        if (error.response.status.toString().startsWith('4')) {
                            setValidUser(false)
                            setLoading(false)
                        }
                        console.log('UserAPI error ', error)
                    })
                setLoading(false)
                app.getContext().then(context => {
                    console.log('context ', context)
                    if (context.page.subPageId) {
                        NewsAPI.get(Number(context.page.subPageId), tokenResponse!.token, userInfo.tenantId)
                            .then((response) => {
                                setSelectedComm(response)
                                setLoading(false)
                            })
                    }
                }).catch((err) => {
                    console.log('getContext error ', err)
                })
            }).catch((err: ErrorWithCode) => {
                console.log('News get token error: ', err.code, err.message)
                if (err.code === 'UiRequiredError') {
                    console.log('prompt user to login', process.env.REACT_APP_IDENTIFIER_URI)
                    teamsUserCredential!.login(scope).then(() => {
                        teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                            setToken(tokenResponse!.token)
                            UserAPI.me(tokenResponse!.token, userInfo.tenantId)
                                .catch((error) => {
                                    console.log('News userAPI error ', error)
                                    if (error.response.status.toString().startsWith('4')) {
                                        setValidUser(false)
                                        setLoading(false)
                                    }
                                    console.log('UserAPI error ', error)
                                })
                            setLoading(false)
                            app.getContext().then(context => {
                                console.log('context ', context)
                                if (context.page.subPageId) {
                                    if (context.page.subPageId) {
                                        NewsAPI.get(Number(context.page.subPageId), tokenResponse!.token, userInfo.tenantId)
                                            .then((response) => {
                                                setSelectedComm(response)
                                                setLoading(false)
                                            })
                                    }
                                }
                            }).catch((err) => {
                                console.log('getContext error ', err)
                            })
                        })
                    })
                }
            })
        })
    }

    const onSelectedComm = (selectedComm: INews) => {
        console.log('news setSelectedComm ', selectedComm)
        setSelectedComm(selectedComm)
        setSelectedMostRead(undefined)
    }

    const onSelectedMostRead = (selectedMostRead: INews) => {
        if (selectedMostRead) {
            if (!isDesktopOrLaptop) {
                setMenuActiveIndex(0)
                setSelectedMenuItem('news')
            }
            setSelectedMostRead(selectedMostRead)
            setSelectedComm(undefined)
        }
    }

    const newsBreadcrumbClick = (event: any) => {
        event.preventDefault();
        console.log('clicked breadcrumb')
        setSelectedComm(undefined);
        setSelectedMostRead(undefined);
        navigate("/news", { replace: true });
    }

    const MobileNews = () => {
        console.log('MobileNews selectedComm ', selectedComm)
        if (selectedComm || selectedMostRead) {

            return (
                <>
                    <Banner />
                    <Flex column gap="gap.small">
                        <Flex.Item grow>
                            <Segment
                                content={
                                    <Breadcrumb aria-label="breadcrumb" size="medium">
                                        <Breadcrumb.Item onClick={newsBreadcrumbClick}>
                                            <Breadcrumb.Link href="#">
                                                <ListIcon xSpacing="after" />
                                                News
                                            </Breadcrumb.Link>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Divider>
                                            <ChevronEndIcon size="medium" />
                                        </Breadcrumb.Divider>
                                        <Breadcrumb.Item active aria-current="page">{selectedComm ? selectedComm.Title : selectedMostRead?.Title}</Breadcrumb.Item>
                                    </Breadcrumb>}
                            />
                        </Flex.Item>
                    </Flex>
                    <Flex fill>
                        <Flex.Item >
                            <Segment styles={{ width: '100%' }}
                                content={<NewsContent selectedComm={selectedComm ? selectedComm : selectedMostRead}
                                    refreshNewsListSocial={refreshNewsListSocial} />}
                            />
                        </Flex.Item>
                    </Flex>
                </>)
        } else {
            return (
                <div style={{ height: '100%', overflowY: 'auto' }}>
                    <Banner />

                    <Menu defaultActiveIndex={0} activeIndex={menuActiveIndex} accessibility={tabListBehavior} items={tabs} pointing primary />

                    {selectedMenuItem === "news" && (
                        <NewsList loadDefault={false}
                            onSelectedComm={onSelectedComm}
                            updateNewsListSocial={updateNewsListSocial} />
                    )}

                    {selectedMenuItem === "video" && (
                        <Video />
                    )}

                    {selectedMenuItem === "mostRead" && (
                        <NewsRead onSelectedMostRead={onSelectedMostRead} />
                    )}
                </div>)
        }
    }

    function refreshNewsListSocial() {
        setUpdateNewsListSocial(!updateNewsListSocial)
    }

    return (
        !validUser ?
            <Flex fill hAlign="center" vAlign="center" column>
                <Text weight='bold' content='You need to be a subscriber of the Metro service from Retail Manager Solutions Ltd to use this application.' />
                <p>Please <a target='_blank' href='https://www.metrofy.com/contact-us'>contact us</a> at Retail Manager Solutions Ltd for more information or support.</p>
            </Flex>
            : !loading ?
                isDesktopOrLaptop ?
                    <>
                        <Banner />
                        <Flex styles={{ height: '100%' }}>
                            <Flex.Item size="size.quarter" styles={{ height: '100%' }}>
                                <Segment styles={{ overflowY: 'auto' }}
                                    content={<NewsList loadDefault={true}
                                        onSelectedComm={onSelectedComm}
                                        resetSelectedComm={setSelectedMostRead != null ? true : false}
                                        updateNewsListSocial={updateNewsListSocial} />}
                                />
                            </Flex.Item>
                            <Flex.Item size="size.half" >
                                <Segment
                                    content={selectedMostRead ?
                                        <NewsContent selectedMostRead={selectedMostRead}
                                            refreshNewsListSocial={refreshNewsListSocial} />
                                        :
                                        <NewsContent selectedComm={selectedComm}
                                            refreshNewsListSocial={refreshNewsListSocial} />}
                                />
                            </Flex.Item>
                            <Flex.Item size="size.quarter">
                                <Segment
                                    content={<Flex fill column>
                                        {/* <Flex.Item size="size.half"> */}
                                        <Video />
                                        {/* </Flex.Item>
                                        <Flex.Item size="size.half"> */}
                                        <NewsRead onSelectedMostRead={onSelectedMostRead} />
                                        {/* </Flex.Item> */}
                                    </Flex>}
                                />
                            </Flex.Item>
                        </Flex>
                    </>
                    :
                    <MobileNews />
                : <Flex fill hAlign="center" vAlign="center"><Loader size='larger' /></Flex>
    )

}