import React, { useState, useEffect, useContext } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import {
    Card,
    Table,
    TableRowProps,
    Status,
    CardHeader,
    CardBody,
    Text
} from "@fluentui/react-northstar"
import { KnowHow } from "../../../interfaces/faq"
import { FaqAPI } from "../../../api/FaqAPI";
import { ErrorWithCode } from "@microsoft/teamsfx";
import { TeamsFxContext } from "../../Context";

export default function Questions() {

    const [FAQs, setFAQs] = useState<KnowHow[]>();
    const [rowData, setRowData] = useState<Array<TableRowProps>>();
    const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
    const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

    useEffect(() => {
        teamsUserCredential?.getUserInfo().then((userInfo) => {
        teamsUserCredential!.getToken(scope).then((tokenResponse) => {
            load(tokenResponse!.token, userInfo.tenantId)
        }).catch((err: ErrorWithCode) => {
            console.log('NewsRead get token error: ', err.code, err.message)        
            if (err.code === 'UiRequiredError') {
                console.log('prompt user to login', process.env.REACT_APP_IDENTIFIER_URI)
                teamsUserCredential!.login(scope).then(() => {
                    teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                        load(tokenResponse!.token, userInfo.tenantId)
                    })
                })
            }
        })         
    })  
    }, []);

    const load = (token:string, tenantId: string) => {
        FaqAPI.all(token, tenantId)
        .then((response) => {
            let faqData: KnowHow[] = response;
            setFAQs(faqData!) 
        })
    }

    useEffect(() => {
        const showFAQs = (faqs: KnowHow[]) => {
            return faqs.map((q) => ({
                key: q.KnowHowId,
                items: [
                    {
                        content: q.Question,
                        truncateContent: true,
                        styles: { maxWidth: '400px' }
                    },
                    {
                        content: getStatus({ faqStatus: q.Status }),
                        styles: { maxWidth: '200px' }
                    }
                ],
                onClick: () => { openFaq(q.KnowHowId) }
            }))
        }

        if (FAQs !== undefined) {
            setRowData(showFAQs(FAQs))
        }
    }, [FAQs])

    const openFaq = (knowHowId: number) => {    
        let faqInfo = {
          url: `${process.env.REACT_APP_URL}/index.html#/answers?knowHowId=${knowHowId}`,
          title: "Question",
          height: 600,
          width: 1224
        };
        let submitHandler = (err: any, result: any) => {
          console.log('closed FAQ')
        };
        microsoftTeams.tasks.startTask(faqInfo, submitHandler);
      }

    const header = {
        items: [
            {
                content: 'Question',
                styles: { maxWidth: '400px' }
            },
            {
                content: 'Status',
                styles: { maxWidth: '200px' }
            }
        ]
    }

    const getStatus = ({ faqStatus }: { faqStatus: number }) => {
        switch (faqStatus) {
          case 0: {
            return (<><Status state="error" title="Awaiting Expert Response" /><Text> Awaiting Expert Response</Text></>)
          }
          case 1: {
            return (<><Status state="success" title="Answered" /><Text> Answered</Text></>)
          }
        }
      }

    return (
        <Card fluid>
            <CardHeader>
                <Text content="Questions" weight="bold" />
            </CardHeader>
            {rowData ?
            <CardBody styles={{ maxHeight: '700px', overflow:"auto" }}>
                { rowData.length > 0 ?
                <Table styles={{ maxWidth: '700px' }} compact={false} header={header} rows={rowData} aria-label="Static table" />
                : <Text content="You haven't asked any questions yet." />
                }
            </CardBody>
            : <Text content="You haven't asked any questions."/>}
        </Card>
    )

}