import { useState, useEffect, useContext } from "react";
import { Comment, CommentToPost } from '../../../interfaces/news'
import { Segment, CloseIcon, Flex, Chat, ChatMessage, Avatar, Button, TextArea } from "@fluentui/react-northstar"
import { formatDistance } from 'date-fns'
import { SocialAPI } from "../../../api/SocialAPI";
import { TeamsFxContext } from "../../Context";
import { ErrorWithCode } from "@microsoft/teamsfx";

export function CommentReply(props: { commentToReply: Comment, toggleCommentReply: any, refreshSocial: any }) {
    const [commentReplyText, setCommentReplyText] = useState('');
    const handleCommentReplyTextChange = (e: any) => { setCommentReplyText(e.target.value); };
    const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
    const [token, setToken] = useState<string>();
    const [tenantId, setTenantId] = useState<string>();
    const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

    useEffect(() => {
        setCommentReplyText('')
        teamsUserCredential?.getUserInfo().then((userInfo) => {
            setTenantId(userInfo.tenantId);
            teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                setToken(tokenResponse!.token)
            }).catch((err: ErrorWithCode) => {
                if (err.code === 'UiRequiredError') {
                    teamsUserCredential!.login(scope).then(() => {
                        teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                            setToken(tokenResponse!.token)
                        })
                    })
                }
            })
        })
    }, [])

    return (
        <Segment color="brand">
            <Flex gap="gap.smaller">
                <Chat
                    styles={{ marginBottom: '5px', width: '100%' }}
                    items={[
                        {
                            gutter: (<Avatar name={props.commentToReply.HierarchyFriendlyName} image={props.commentToReply.UserImageUrl} />),
                            key: 'commentToReply',
                            message: (
                                <ChatMessage
                                    author={props.commentToReply.HierarchyFriendlyName}
                                    content={props.commentToReply.CommentText}
                                    timestamp={formatDistance(new Date(props.commentToReply.AuditDate), new Date())}
                                />
                            ),
                        },
                    ]}
                />
                <Button icon={<CloseIcon />} iconOnly title="Close" size="small" style={{ marginLeft: "auto" }} onClick={props.toggleCommentReply} />
            </Flex>
            <Flex gap="gap.smaller">
                <TextArea id="commentText"
                    name="commentText"
                    value={commentReplyText}
                    onChange={handleCommentReplyTextChange}
                    fluid
                    placeholder="Reply to comment..."
                    style={{ marginBottom: '15px' }} />
                <Button content="Reply" tinted style={{ margin: "auto" }} onClick={async (event) => {
                    if (commentReplyText.length === 0) {
                        return
                    }
                    if (token && tenantId) {
                        const comment: CommentToPost = {
                            CommunicationId: props.commentToReply.CommunicationId,
                            CommentText: commentReplyText,
                            InReplyTo: props.commentToReply?.CommentId
                        }

                        await SocialAPI.addComment(comment, token, tenantId)
                            .then(async (response) => {
                                console.log(response);
                                props.refreshSocial();
                            })
                            .catch((error) => {
                                console.error(error.response.status);
                            })
                    }
                }} />
            </Flex>
        </Segment>)
}
