import { api } from "./config"
import { ITask } from '../interfaces/task'

const development = process.env.REACT_APP_DEVELOPER_ENDPOINT && process.env.REACT_APP_DEVELOPER_ENDPOINT !== '' 
          ? process.env.REACT_APP_DEVELOPER_ENDPOINT
          : ''

export const TaskAPI = {
    get: async function (communicationId:number, repeatDate:any, token:string, tenantId:string) {
        let _url = `/task/${communicationId}`;
        if(repeatDate)
        {
            _url += `?repeatDate=${encodeURIComponent(repeatDate.replace(' ', '+'))}`
        }
        const response = await api.request({
            url: _url,
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response.data
    },
    getMyTasks: async function (token:string, tenantId:string) {
        const response = await api.request({
            url: `/tasks`,
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response.data
    },
    getTaskCompletion: async function (hierarchyId:number, token:string, tenantId:string) {
        const response = await api.request({
            url: `/taskcompletion/${hierarchyId}`,
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response.data
    },  
    save: async function(task:ITask, token:string, tenantId:string){
        console.log('taskAPI save ', task)
        const response = await api.request({
            url: `/task`,          
            method: 'PUT', 
            data: JSON.stringify(task),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response.data
    }  
}