import { api } from "./config"

const development = process.env.REACT_APP_DEVELOPER_ENDPOINT && process.env.REACT_APP_DEVELOPER_ENDPOINT !== '' 
    ? process.env.REACT_APP_DEVELOPER_ENDPOINT
    : '';

export const VideosAPI = {

    all: async function (token:string, tenantId:string) {
        const response = await api.request({
            url: `/videos`,
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response.data
    },
    getLabels: async function (token:string, tenantId:string) {
        const response = await api.request({
            url: `/labels`,
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response.data
    },
    getForLabel: async function (label:string, token:string, tenantId:string) {
        const response = await api.request({
            url: `/videos?label=${label}`,
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Audience': development !== '' ? development : tenantId
            }
        })
        return response.data
    },
}