import { useState, useEffect, useContext } from "react";
import { Comment, ReactionToPost } from '../../../interfaces/news'
import { Flex, Button } from "@fluentui/react-northstar"
import { ChatIcon, RedbangIcon, TrashCanIcon } from '@fluentui/react-icons-northstar'
import { React } from './Reactions'
import { SocialAPI } from "../../../api/SocialAPI";
import { TeamsFxContext } from "../../Context";
import { ErrorWithCode } from "@microsoft/teamsfx";

export function CommentMenuButton(props: {
    comment: Comment,
    setCommentToReply: any,
    setCommentToReport: any,
    getCurrentUserReaction: any,
    toggleCommentReply: any,
    toggleCommentReport: any,
    refreshSocial: any
}) {
    const reaction: ReactionToPost = props.getCurrentUserReaction(props.comment)
    const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
    const [token, setToken] = useState<string>();
    const [tenantId, setTenantId] = useState<string>();
    const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

    useEffect(() => {
        teamsUserCredential?.getUserInfo().then((userInfo) => {
            setTenantId(userInfo.tenantId);
            teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                setToken(tokenResponse!.token)
            }).catch((err: ErrorWithCode) => {
                if (err.code === 'UiRequiredError') {
                    teamsUserCredential!.login(scope).then(() => {
                        teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                            setToken(tokenResponse!.token)
                        })
                    })
                }
            })
        })
    }, [])

    async function deleteComment(commentId: number): Promise<void> {
        if (commentId === undefined) { return }
        if (token && tenantId) {
            if (window.confirm("Are you sure you want to delete this comment?")) {
                await SocialAPI.deleteComment(commentId, token, tenantId)
                    .then(async (response) => {
                        props.refreshSocial();
                    })
                    .catch((error) => {
                        console.error(error.response.status);
                    })
            }
        }
    }

    let buttonGroup = []
    if (props.comment.CanReply) {
        buttonGroup.push(<Button circular icon={<ChatIcon />} key={`reply_${props.comment.CommentId}`} title="Reply" size="small" onClick={(e) => {
            e.preventDefault();
            props.setCommentToReply(props.comment);
            props.toggleCommentReply()
        }
        } />)
    }
    if (props.comment.CanReport) {
        buttonGroup.push(<Button circular icon={<RedbangIcon />} key={`report_${props.comment.CommentId}`} title="Report" size="small" onClick={(e) => {
            e.preventDefault();
            props.setCommentToReport(props.comment);
            props.toggleCommentReport()
        }
        } />)
    }
    if (props.comment.CanDelete) {
        buttonGroup.push(<Button circular icon={<TrashCanIcon />} key={`delete_${props.comment.CommentId}`} title="Delete" size="small" onClick={(e) => {
            e.preventDefault();
            deleteComment(props.comment.CommentId);
        }
        } />)
    }
    if (props.comment.CanReact) {
        buttonGroup.push(<React key={`reaction_${props.comment.CommentId}_${reaction.ReactionId}`} reaction={reaction} refreshSocial={props.refreshSocial} />)
    }
    return (<Flex gap="gap.smaller">{buttonGroup}</Flex>)
}
