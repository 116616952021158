import React from 'react'

export default function Circle(props: {content:string, color:string}) {

    const circleStyle = {
            backgroundColor: props.color,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '100%',
            textAlign: 'center' as const,
            margin: '5px 15px',
            fontSize: '15px',
            padding: '15px',
            display: 'flex',
            height: '120px',
            width: '120px',
            color: '#fff'
    }

    return (
        <div style={circleStyle}>{props.content}</div>
    )
}