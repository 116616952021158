import { useState, useEffect, useContext } from "react";
import { Compliance } from '../../../interfaces/compliance'
import {
  Card,
  CardHeader,
  CardBody,
  Divider,
  Flex,
  Button,
  Table,
  TableRowProps,
  Status,
  Text,
  Breadcrumb,
  ChevronEndMediumIcon
} from "@fluentui/react-northstar"
import Circle from '../Circle'
import "./TaskCompliance.css";
import { TaskAPI } from "../../../api/TaskAPI";
import { TeamsFxContext } from "../../Context";
import { ErrorWithCode } from "@microsoft/teamsfx";

export default function TaskCompliance(props: { key: string }) {

  const [compliance, setCompliance] = useState<Compliance[]>();
  const [rows, setRows] = useState<TableRowProps[] | undefined>();
  const [path, setPath] = useState<Compliance[]>([]);
  const [token, setToken] = useState<string>();
  const [tenantId, setTenantId] = useState<string>();
  const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
  const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

  const loadData = async (hierarchyId: number, complianceItem: Compliance | undefined, token: string, tenantId:string) => {
    await TaskAPI.getTaskCompletion(hierarchyId, token, tenantId)
      .then(async (response) => {
        let data: Compliance[] = response;
        console.log('data: ', data)
        setCompliance(data);
        if (path && path.length > 0) {
          console.log('path.length > 0')
          if (complianceItem) {
            console.log('complianceItem ', complianceItem)
            setPath(oldArray => [...oldArray, complianceItem])
          } else {
            setPath(oldArray => [...oldArray, data[0]])
          }
          console.log('path done')
        } else {
          console.log('path.length is 0')
          complianceItem ? setPath(oldArray => [...oldArray, complianceItem]) : setPath(oldArray => [...oldArray, data[0]])
        }
      })
  }

  useEffect(() => {
    teamsUserCredential?.getUserInfo().then((userInfo) => {
      setTenantId(userInfo.tenantId);
      teamsUserCredential!.getToken(scope).then((tokenResponse) => {
        setToken(tokenResponse!.token)
        loadData(0, undefined, tokenResponse!.token, userInfo.tenantId)
      }).catch((err: ErrorWithCode) => {
        console.log('News get token error: ', err.code, err.message)
        if (err.code === 'UiRequiredError') {
          console.log('prompt user to login', process.env.REACT_APP_IDENTIFIER_URI)
          teamsUserCredential!.login(scope).then(() => {
            teamsUserCredential!.getToken(scope).then((tokenResponse) => {
              setToken(tokenResponse!.token)
              loadData(0, undefined, tokenResponse!.token, userInfo.tenantId)
            })
          })
        }
      })
    })
  }, []);

  useEffect(() => {
    if (token && tenantId) {
      setRows(compliance?.slice(1).map((c, i) => {
        return {
          key: c.HierarchyId,
          items: [{
            content: <>
              <Status styles={{ marginRight: '1em' }} size='medium' color='red' />
              {c.HasChildren ? <Button onClick={async () => { await loadData(c.HierarchyId, undefined, token, tenantId) }} text content={<Text styles={{ whiteSpace: 'break-spaces' }} content={c.HierarchyName} />} styles={{ justifyContent: 'left', paddingLeft: '0px' }} /> : <Text styles={{ whiteSpace: 'inherit' }} content={c.HierarchyName} />}
            </>,
            styles: {}
          },
          {
            content: <>
              <Text content={c.TasksCompleted} />  <Text content='Completed' styles={{}} />
              <Divider />
              <Text content={c.TaskCount} /> <Text content='Total' styles={{}} />
            </>,
            styles: { maxWidth: '200px' }
          },
          {
            content: c.TaskCompletionPercent,
            styles: { maxWidth: '100px' }
          }]
        }
      }))
    }
  }, [compliance]);

  const header = {
    key: 'header',
    items: [
      {
        content: 'Location',
        key: 'location',
      },
      {
        content: 'Completion',
        key: 'completion',
        styles: { maxWidth: '200px' }
      },
      {
        content: 'Percentage',
        key: 'percentage',
        styles: { maxWidth: '100px' }
      }]
  }

  const myCompliance = () => {
    if (compliance) {
      return <Card
        fluid
        horizontal
        styles={{ height: '160px' }}
      >
        <Card.Preview horizontal >
          <Circle content={`${!isNaN(compliance[0]?.TaskCompletionPercent) ? compliance[0]?.TaskCompletionPercent : 0} %`} color='red' />
        </Card.Preview>
        <Card.Column styles={{ width: '100%' }}>
          <Card.Header>
            <Text content={compliance[0]?.HierarchyName} weight="bold" />
          </Card.Header>
          <Card.Body>
            {/* <Text content='lots of text in the body here to see if it wraps'/> */}
            <Flex>
              <Flex.Item size='size.half'>
                <Text content='Total' />
              </Flex.Item>
              <Flex.Item size='size.half'>
                <Text content={compliance[0]?.TaskCount} />
              </Flex.Item>
            </Flex>
            <Flex>
              <Flex.Item size='size.half'>
                <Text content='Completed' />
              </Flex.Item>
              <Flex.Item size='size.half'>
                <Text content={compliance[0]?.TasksCompleted} />
              </Flex.Item>
            </Flex>
          </Card.Body>
        </Card.Column>
      </Card>
    } else {
      <Text content='loading my compliance' />
    }
  }

  const breadcrumbClick = (event: any, complianceItem: Compliance) => {
    event.preventDefault();
    console.log('selected hierarchy breadcrumb ', complianceItem.HierarchyId, complianceItem.HierarchyName)
    if (token && tenantId) {
      let updatedPath: Compliance[] = [];
      console.log('path length ', path.length)
      //for (var i = path.length - 1; i >= 0; i--) {
      for (var i = 0; i < path.length; i++) {
        console.log('looping ', path[i].HierarchyName)
        if (path[i].HierarchyId !== complianceItem.HierarchyId) {
          updatedPath.push(path[i])
        } else {
          break;
        }
      }
      console.log('updatedPath ', updatedPath)
      setPath(updatedPath)
      loadData(complianceItem.HierarchyId, complianceItem, token, tenantId)
    }
  }

  const breadcrumb = () => {
    const items = path.map((complianceItem, i) => {
      if (i !== path.length - 1) {
        return (
          <>
            <Breadcrumb.Item>
              <Breadcrumb.Link onClick={(event: any) => { breadcrumbClick(event, complianceItem) }} href='#'>{complianceItem.HierarchyName}</Breadcrumb.Link>
            </Breadcrumb.Item>
            <Breadcrumb.Divider>
              <ChevronEndMediumIcon />
            </Breadcrumb.Divider>
          </>)
      } else {
        return (
          <Breadcrumb.Item>
            <Breadcrumb.Link >{complianceItem.HierarchyName}</Breadcrumb.Link>
          </Breadcrumb.Item>
        )
      }
    })
    return items
  }

  const allCompliance = () => {
    if (compliance) {
      return <><Table header={header} rows={rows} styles={{ width: 'auto' }} /></>
    } else {
      return <Text content='loading...' />
    }
  }

  return (
    <div style={{ overflowY: 'auto', marginBottom: '35px' }}>
      <Card fluid styles={{ margin: '0em 0em 0em 0.5em' }}>
        <CardHeader>
          <Text content="Compliance" weight="bold" />
          <Text content="Week to Date" weight="light" size="small" />
        </CardHeader>
        <CardBody>
          {compliance && compliance?.length === 1 ?
            <>
              {myCompliance()}
              <div>{breadcrumb()}</div>
            </>
            :
            <>
              {myCompliance()}
              <div>{breadcrumb()}</div>
              {allCompliance()}
            </>}
        </CardBody>
      </Card>
    </div>
  )
}