import { useState, useEffect } from "react";
import { Social, Comment, ReactionToPost} from '../../../interfaces/news'
import { Flex, Button, ChatIcon, Alert} from "@fluentui/react-northstar"
import { React } from './Reactions'
import { CommentReply } from './CommentReply'
import { CommentReport } from './CommentReport'
import { CommentPost } from './CommentPost'
import { SocialChat } from './SocialChat'
import { useMediaQuery } from "react-responsive"

export function SocialSection(props: { social?: Social , refreshSocial: any}) {        
    
    const [commentToReply, setCommentToReply] = useState<Comment>();
    const [commentToReport, setCommentToReport] = useState<Comment>();
    const [showComment, setShowComment] = useState(true);
    const [showCommentReply, setShowCommentReply] = useState(false);  
    const [showCommentReport, setShowCommentReport] = useState(false);  
    const [showChat, setShowChat] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    useEffect(() => {
        (async () => {
            if (isDesktopOrLaptop) 
            { 
                setShowChat(true)
            }
        })();
    }, [isDesktopOrLaptop]);
    
    function toggleCommentReply(){
        setShowCommentReply(!showCommentReply)
        setShowCommentReport(false)
        if(showCommentReply){
            setShowComment(true)
        }else{
            setShowComment(false)
        }
    }

    function toggleCommentReport(){        
        setShowCommentReport(!showCommentReport)  
        setShowCommentReply(false)
        if(showCommentReport){
            setShowComment(true)
        }else{
            setShowComment(false)
        }
    }   
    
    function getCurrentUserReaction(comment?: Comment): ReactionToPost {
        const existingReaction = comment ? 
                                    comment.Reactions.find((r) => {return r.CurrentUserReaction === true}) : 
                                    props.social?.Reactions.find((r) => {return r.CurrentUserReaction === true})
        if (existingReaction !== undefined){
            return existingReaction
        }
        else{
            let newReaction: ReactionToPost = {
                CommunicationId: ((props.social?.CommunicationId !== undefined) ? props.social?.CommunicationId : 0),
                CommentId: comment ? comment.CommentId : undefined,
                ReactionId: 0,
                ReactionType: ""
            }
            return newReaction
        }
    }     

    return (
        <>
            {props.social ? (
                <>    
                    <Flex gap="gap.smaller">
                            <React reaction={getCurrentUserReaction()} refreshSocial={props.refreshSocial}/>
                    </Flex>        
                    { showCommentReply && commentToReply ? <CommentReply commentToReply={commentToReply} toggleCommentReply={toggleCommentReply} refreshSocial={props.refreshSocial} /> : null }   
                    { showCommentReport && commentToReport ? <CommentReport commentToReport={commentToReport} toggleCommentReport={toggleCommentReport} refreshSocial={props.refreshSocial} /> : null } 
                    { showComment ? <CommentPost communicationId={((props.social?.CommunicationId !== undefined) ? props.social?.CommunicationId :0)} refreshSocial={props.refreshSocial} /> : null }                                                                   
                    { isDesktopOrLaptop ? null : <Button icon={<ChatIcon />} content={showChat ? "Hide comments" : "View comments"} tinted style={{marginBottom: "7px"}} onClick={async (event) => {
                        setShowChat(!showChat)
                    }} />  }
                    { showChat ? 
                                props.social?.Comments.length > 0 ?
                                    <SocialChat social={props.social} 
                                    setCommentToReply={setCommentToReply} 
                                    setCommentToReport={setCommentToReport}
                                    getCurrentUserReaction={getCurrentUserReaction}
                                    toggleCommentReply={toggleCommentReply}
                                    toggleCommentReport={toggleCommentReport}
                                    refreshSocial={props.refreshSocial}/> 
                                    :
                                    <Alert content="No comments have been submitted." />
                                : null}
                </>) 
                : null
            }
        </>
    ) 
}
