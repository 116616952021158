import { Text } from "@fluentui/react-northstar"


export default function EnvironmentVariables({ teamsToken }: { teamsToken: string | undefined }) {

    const env = process.env;
    let keys: any = [];
    Object.keys(env).forEach(function (key, value) {
        keys.push(<p><Text content={key} />=<Text content={env[key]} /></p>)
    });
    keys.push(<p><Text content='TOKEN' />=<Text content={teamsToken} /></p>)    

    return (<><Text content='Environment variables:' />{keys}</>)
}