import axios, { AxiosError } from "axios"

const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

export const api = axios.create({
  baseURL: process.env.REACT_APP_UNIFIED_COMMS_ENDPOINT,
})

// defining a custom error handler for all APIs
const errorHandler = (error: AxiosError) => {
  const statusCode = error.response?.status

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error)
  }
  return Promise.reject(error)
}

api.interceptors.request.use(
  async config => {
    const modifiedConfig = {
      ...config,
      headers: {
        'Content-Type': 'application/json',
        ...config.headers,
      },
    };
    return modifiedConfig;
  },
  error => {
    Promise.reject(error)
});

api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error)
})