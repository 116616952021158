import React, { useState, useEffect, useContext, useMemo } from "react";
import { Flex, FlexItem, Accordion, FormTextArea, Button, Alert, Box } from "@fluentui/react-northstar"
import { KnowHowFaq, KnowHowParent, KnowHow } from '../../../interfaces/faq'
import { useMediaQuery } from "react-responsive"
import { FaqAPI } from "../../../api/FaqAPI";
import { ErrorWithCode } from "@microsoft/teamsfx";
import { TeamsFxContext } from "../../Context";

export default function FAQ(props: {
    KnowHowParent?: KnowHowParent
    HandleFaqQuestionChange: any,
    HandleFaqSubmit: any,
    CommunicationId?: number,
    DefaultQuestion?: string,
    DisplayAlert: boolean,
    ShowAsk: boolean
}) {

    const [question, setQuestion] = useState<string>();
    const [token, setToken] = useState<string>();
    const panels = props.KnowHowParent?.KnowHowFaqs.map((q: KnowHowFaq) => {
        return {
            key: q.KnowHowFaqId,
            title: q.Question,
            content: q.Answer
        }
    });
    const canAskQuestion = (props.KnowHowParent?.KnowHowRoleExperts && props.KnowHowParent.KnowHowRoleExperts.length > 0)
        || (props.KnowHowParent?.KnowHowUserExperts.length && props.KnowHowParent?.KnowHowUserExperts?.length > 0)
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
    const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

    useEffect(() => {
        teamsUserCredential!.getToken(scope).then((tokenResponse) => {
            setToken(tokenResponse!.token)
        }).catch((err: ErrorWithCode) => {
            if (err.code === 'UiRequiredError') {
                teamsUserCredential!.login(scope).then(() => {
                    teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                        setToken(tokenResponse!.token)
                    })
                })
            }
        })
        setQuestion(props.DefaultQuestion)
        console.log(`FAQ DefaultQuestion ${props.DefaultQuestion}`)
        console.log('FAQ KnowHowParent', props.KnowHowParent)
        console.log('FAQ canAskQuestion ', canAskQuestion)
    }, []);

    useEffect(() => {
        console.log(`FAQ question changed: ${question}`)
    }, [question])

    

    return (
        isDesktopOrLaptop ?
            <>
                {
                    props.DisplayAlert ?
                        <Alert content="Question submitted" dismissible success /> : <></>
                }
                <Flex>
                    <FlexItem size="size.half" >
                        {panels && panels.length > 0 ?
                            <Accordion panels={panels} exclusive />
                            :
                            <Box styles={{ padding: "1em 0em" }} content={<Alert fitted content="No FAQ is currently attached to this Task." />} />
                        }
                    </FlexItem>
                    {canAskQuestion ?
                        <FlexItem size="size.half">
                            <Flex styles={{ height: '9em' }}>
                                <FormTextArea
                                    id='faqQuestion'
                                    defaultValue={props.DefaultQuestion}
                                    onChange={(event, data) => props.HandleFaqQuestionChange(data?.value)}
                                    placeholder="Ask question..."
                                    fluid
                                    label="Question"
                                    resize='none'
                                    rows={5}
                                    cols={50}
                                />
                                <Flex vAlign='end'>
                                    {
                                        props.ShowAsk ?
                                            <Button content="Ask"
                                                styles={{ margin: '0em 0em 0em 1em' }}
                                                onClick={() => props.HandleFaqSubmit(props.KnowHowParent!, props.CommunicationId!)} /> : <></>
                                    }
                                </Flex>
                            </Flex>
                        </FlexItem>
                        :
                        <></>
                    }
                </Flex>
            </>
            :
            <>
                {
                    props.DisplayAlert ?
                        <Alert content="Question submitted" dismissible success /> : <></>
                }
                <Flex column gap="gap.small">
                    {panels && panels.length > 0 ?
                        <Accordion panels={panels} exclusive />
                        :
                        <Box styles={{ padding: "1em 0em" }} content={<Alert fitted content="No FAQ is currently attached to this Task." />} />
                    }
                    {canAskQuestion ?
                        <Flex >
                            <FormTextArea
                                id='faqQuestion'
                                defaultValue={props.DefaultQuestion}
                                onChange={(event, data) => props.HandleFaqQuestionChange(data?.value)}
                                placeholder="Ask question..."
                                fluid
                                label="Question"
                                resize='none'
                                rows={5}
                                cols={50}
                            />
                            <Flex vAlign='end'>
                                {
                                    props.ShowAsk ?
                                        <Button content="Ask"
                                            styles={{ margin: '0em 0em 0em 1em' }}
                                            onClick={() => props.HandleFaqSubmit(props.KnowHowParent!, props.CommunicationId!)} /> : <></>
                                }
                            </Flex>
                        </Flex>
                        :
                        <></>
                    }
                </Flex>
            </>
    )
}