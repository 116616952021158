import { useState, useEffect, useContext } from "react";
import "./Compliance.css"
import { Menu, Loader, Header, Text, Flex, Table, InfoIcon } from "@fluentui/react-northstar"
import { NewsAPI } from "../../api/NewsAPI";
import { ComplianceDTO } from "../../interfaces/compliance";
import { format } from 'date-fns'
import { ReactionsSummary } from "./social/Reactions";
import { ErrorWithCode } from "@microsoft/teamsfx";
import { TeamsFxContext } from "../Context";

export default function Compliance(props: { communicationId?: number, datefrom?: string }) {

    const [communicationId, setCommunicationId] = useState<number>();
    const [dateFrom, setDateFrom] = useState<string>("");
    const [dateTo, setDateTo] = useState<string>("");
    const [compliance, setCompliance] = useState<ComplianceDTO>();
    const [selectedTabItem, setSelectedTabItem] = useState({});
    const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
    const scope = [`${process.env.REACT_APP_IDENTIFIER_URI}/access_as_user`];

    useEffect(() => {
        const params = new URLSearchParams(window.location.hash.substring(12))
        if (params.get('datefrom')) {
            setDateFrom(String(params.get('datefrom')))
        }
        if (params.get('dateto')) {
            setDateTo(String(params.get('dateto')))
        }
        if (params.get('communicationId')) {
            setCommunicationId(Number(params.get('communicationId')))
        }
        else if (props.communicationId) {
            setCommunicationId(props.communicationId)
        }

        setSelectedTabItem('Read')
    }, [props.communicationId])

    useEffect(() => {
        
            if (communicationId) {
                teamsUserCredential?.getUserInfo().then((userInfo) => {
                teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                    NewsAPI.compliance(communicationId, dateFrom, dateTo, tokenResponse!.token, userInfo.tenantId)
                    .then((response) => {
                        let data: ComplianceDTO = response;
                        setCompliance(data);
                    })
                }).catch((err: ErrorWithCode) => {
                    console.log('NewsRead get token error: ', err.code, err.message)        
                    if (err.code === 'UiRequiredError') {
                        console.log('prompt user to login', process.env.REACT_APP_IDENTIFIER_URI)
                        teamsUserCredential!.login(scope).then(() => {
                            teamsUserCredential!.getToken(scope).then((tokenResponse) => {
                                NewsAPI.compliance(communicationId, dateFrom, dateTo, tokenResponse!.token, userInfo.tenantId)
                                .then((response) => {
                                    let data: ComplianceDTO = response;
                                    setCompliance(data);
                                })
                            })
                        })
                    }
                })
            })
            }
    }, [communicationId, dateFrom, dateTo])

    const MenuItems = () => {
        var items =  [
            {
                key: 'Read',
                content: `Read (${compliance?.AudienceRead.length})`,
                onClick: () => setSelectedTabItem("Read")
            },
            {
                key: 'Unread',
                content: `Unread (${compliance?.AudienceUnread.length})`,
                onClick: () => setSelectedTabItem("Unread")
            }
        ]

        if (compliance?.SocialCompliance !== undefined) {
            items.push({
                key: 'Social',
                content: 'Social',
                onClick: () => setSelectedTabItem('Social')
            })
        }

        return items;
    }

    const TabContent = () => {
        if (selectedTabItem === 'Read') {
            return (<Read />)
        } else if (selectedTabItem === 'Unread') {
            return (<Unread />)
        } else if (selectedTabItem === 'Social') {
            return (<SocialCompliance />)
        } else {
            return (
                <></>
            )
        }
    }

    const Read = () => {
        let header = {
            items: ['Name', 'Ref', 'Date'],
          }

        let rows = compliance?.AudienceRead.map((a, i) => {
            return {
              key: a.HierarchyId,
              items: [{
                content: a.HierarchyName,
              },
              {
                content: a.ExternalReference,
              },
              {
                content: format(new Date(a.DateStamp), 'MM/dd/yyyy hh:mm')
              }]
            }
          })
        return (<Table compact header={header} rows={rows}/>)
    }

    const Unread = () => {
        let header = {
            items: ['Name', 'Ref'],
          }

        let rows = compliance?.AudienceUnread.map((a, i) => {
            return {
              key: a.HierarchyId,
              items: [{
                content: a.HierarchyName,
              },
              {
                content: a.ExternalReference,
              }]
            }
          })
        return (<Table compact header={header} rows={rows}/>)
    }

    const SocialCompliance = () => {
        let totalreactionCount = 0
        compliance?.SocialCompliance.ReactionsCount.forEach(r => {
            totalreactionCount += r.Count
        });

        return (<>
            <Flex gap="gap.medium" padding="padding.medium">
                <Flex.Item styles={{textAlign: "end"}} size="size.half">
                    <Text weight="semibold" content="Audience count" />
                </Flex.Item>
                <Flex.Item size="size.half">
                    <Text content={compliance?.TotalAudience}/>
                </Flex.Item>
            </Flex>
            <Flex gap="gap.medium" padding="padding.medium">
                <Flex.Item styles={{textAlign: "end"}} size="size.half">
                    <Text weight="semibold" content="Number of times read" />
                </Flex.Item>
                <Flex.Item size="size.half">
                    <Text content={compliance?.AudienceRead.length}/>
                </Flex.Item>
            </Flex>
            <Flex gap="gap.medium" padding="padding.medium">
                <Flex.Item styles={{textAlign: "end"}} size="size.half">
                    <div><Text weight="semibold" content="Total number of comments" /> <InfoIcon outline data-toggle="tooltip" data-placement="right" title="This is total number of comments for the communication"/></div>
                </Flex.Item>
                <Flex.Item size="size.half">
                    <Text content={compliance?.SocialCompliance.TotalCommentCount}/>
                </Flex.Item>
            </Flex>
            <Flex gap="gap.medium" padding="padding.medium">
                <Flex.Item styles={{textAlign: "end"}} size="size.half">
                    <div><Text weight="semibold" content="Active number of comments" /> <InfoIcon outline data-toggle="tooltip" data-placement="right" title="This is number of active comments for the communication"/></div>
                </Flex.Item>
                <Flex.Item size="size.half">
                    <Text content={compliance?.SocialCompliance.ActiveCommentCount}/>
                </Flex.Item>
            </Flex>
            <Flex gap="gap.medium" padding="padding.medium">
                <Flex.Item styles={{textAlign: "end"}} size="size.half">
                    <div><Text weight="semibold" content="Number of comments in moderation" /> <InfoIcon outline data-toggle="tooltip" data-placement="right" title="This is number of comments currently in moderation for the communication"/></div>
                </Flex.Item>
                <Flex.Item size="size.half">
                    <Text content={compliance?.SocialCompliance.InModerationCommentCount}/>
                </Flex.Item>
            </Flex>    
            <Flex gap="gap.medium" padding="padding.medium">
                <Flex.Item styles={{textAlign: "end"}} size="size.half">
                    <Text weight="semibold" content="Number of deleted comments" />
                </Flex.Item>
                <Flex.Item size="size.half">
                    <Text content={compliance?.SocialCompliance.DeletedCommentCount}/>
                </Flex.Item>
            </Flex>   
            <Flex gap="gap.medium" padding="padding.medium">
                <Flex.Item styles={{textAlign: "end"}} size="size.half">
                    <Text weight="semibold" content="Number of reactions assigned" />
                </Flex.Item>
                <Flex.Item size="size.half">
                    <Text content={totalreactionCount}/>
                </Flex.Item>
            </Flex>    
            <Flex gap="gap.medium" padding="padding.medium">
                <Flex.Item styles={{textAlign: "end"}} size="size.half">
                    <Text weight="semibold" content="Number of individual reactions" />
                </Flex.Item>
                <Flex.Item size="size.half">
                    <ReactionsSummary reactions={compliance?.SocialCompliance.ReactionsCount ? compliance?.SocialCompliance.ReactionsCount : []} />
                </Flex.Item>
            </Flex>                                                                     
        </>)
    }

    return (
        <div className="compliance page">
            <div className="narrow">
                {
                    compliance ?
                        <>
                            <Header as="h4" content={`Created by ${compliance?.CreatorName} on ${compliance?.PublishedDate ? format(new Date(compliance?.PublishedDate), 'MM/dd/yyyy hh:mm') : ""}`}/>
                            <Menu defaultActiveIndex={0} items={MenuItems()} underlined secondary styles={{marginBottom: '15px'}} />
                            <TabContent />
                        </>
                        :
                        <Loader label="Loading..." />
                }
            </div>
        </div>
    )
}